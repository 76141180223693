import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { IconButton, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { format } from 'date-fns'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CalendarIcon from '../../../assets/icons/calendar.svg?react'
import { useStoreState } from '../../../store/hooks'
import getParsedDate from '../helpers/getParsedDate'
import navigateToDate from '../helpers/navigateToDate'

interface IProps {
    disabled: boolean
    variant: 'mobile' | 'desktop'
}

const DailyPuzzleDate: React.FC<IProps> = ({ disabled, variant }) => {
    const { date } = useParams<{ date?: string }>()
    const validations = useStoreState((state) => state.dailyPuzzleView.validations)
    const navigate = useNavigate()

    const handleDateChange = (isNext: boolean) => {
        const parsedDate = getParsedDate(date)
        navigateToDate(navigate, new Date(parsedDate), isNext)
    }

    if (variant === 'mobile') {
        return (
            <Stack height="36px" direction="row" alignItems="center" gap={0}>
                <IconButton
                    size="small"
                    onClick={() => handleDateChange(false)}
                    disabled={!validations.prevDate || disabled}
                    sx={{
                        width: '40px',
                        height: '40px',
                        padding: 0,
                        svg: {
                            height: '40px',
                            width: '40px',
                        },
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <CalendarIcon height="36px" width="36px" />
                <IconButton
                    size="small"
                    onClick={() => handleDateChange(true)}
                    disabled={!validations.nextDate || disabled}
                    sx={{
                        width: '40px',
                        height: '40px',
                        svg: {
                            padding: 0,
                            height: '40px',
                            width: '40px',
                        },
                    }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Stack>
        )
    }

    return (
        <Stack height="24px" direction="row" alignItems="center" gap={1}>
            <IconButton
                sx={{ padding: 0 }}
                size="medium"
                onClick={() => handleDateChange(false)}
                disabled={!validations.prevDate || disabled}
            >
                <ChevronLeftIcon />
            </IconButton>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="center" minWidth="150px">
                <CalendarIcon height="24px" />
                <Typography fontSize="22px">{format(getParsedDate(date), 'dd MMMM')}</Typography>
            </Stack>
            <IconButton
                sx={{ padding: 0 }}
                size="medium"
                onClick={() => handleDateChange(true)}
                disabled={!validations.nextDate || disabled}
            >
                <ChevronRightIcon />
            </IconButton>
        </Stack>
    )
}

DailyPuzzleDate.displayName = 'DailyPuzzleDate'

export default DailyPuzzleDate
