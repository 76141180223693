import { Button, Stack, Zoom } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import ChallengeIcon from '../../assets/icons/challenge.svg?react'
import FollowIcon from '../../assets/icons/follow.svg?react'
import UnfollowIcon from '../../assets/icons/unfollow.svg?react'
import getPlayersStats, { IOverallRecord, IOverallRecordStats } from '../../functions/getPlayersStats'
import { imgFallback } from '../../helpers/imgFallback'
import { Rules } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { FramedAvatar } from '../../sharedComponents/src/globalHeader/components/FramedAvatar'
import {
    followUser,
    unfollowUser,
    UserSearchResult,
} from '../../sharedComponents/src/globalHeader/functions/ams-functions'
import { colors } from '../../sharedComponents/src/globalHeader/theme/colors'
import { useStoreActions, useStoreState } from '../../store/hooks'
import Rating from '../Rating/Rating'
import { StatusIndicator } from '../userSearchBar/StatusIndicator/StatusIndicator'

const avatarStyle = {
    height: '4.875rem',
    width: '4.875rem',
}

const overallRecordContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    px: '.5rem',
}

const overallRecordTextStyles = {
    fontSize: '.94rem',
    lineHeight: '1.125rem',
    fontWeight: 700,
}

export type ProfileInfoOrigin = 'top' | 'bottom'

interface IProps {
    user: UserSearchResult
    children: JSX.Element | JSX.Element[]
    showOnHover?: boolean
    showOnClick?: boolean
    sx?: SxProps<Theme>
    origin?: ProfileInfoOrigin
    onStartChallenge?: (userId: string) => void
    overallRecord?: IOverallRecord
}

const ProfileInfo: React.FC<IProps> = (props) => {
    const {
        user,
        children,
        onStartChallenge,
        showOnHover = false,
        showOnClick = true,
        sx = {},
        origin = 'bottom',
        overallRecord,
    } = props
    const { palette } = useTheme()
    const followList = useStoreState((state) => state.followList)
    const currentUserId = useStoreState((state) => state.userData.userData?.id)
    const currentUserName = useStoreState((state) => state.userData.userData?.user_name)
    const token = useStoreState((state) => state.token)
    const rules = useStoreState((state) => state.rules)

    const updateUserFollowList = useStoreActions((state) => state.updateUserFollowList)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [clicked, setClicked] = useState<boolean>(false)
    const [follow, setFollow] = useState<boolean>(followList.some((item) => item.id === user.id))
    const [overallRecordStats, setOverallRecordStats] = useState<IOverallRecordStats | null>(
        overallRecord?.stats || null,
    )

    const open = Boolean(anchorEl)
    const id = open ? `profile-info-popover-for-${user.userName}` : undefined
    const followEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.FOLLOW)

    useEffect(() => {
        if (clicked) {
            getStats()
        }
    }, [clicked])

    useEffect(() => {
        setFollow(followList.some((item) => item.id === user.id))
    }, [followList])

    useEffect(() => {
        if (overallRecord) {
            setOverallRecordStats(overallRecord.stats)
        } else {
            setOverallRecordStats(null)
        }
    }, [overallRecord])

    const getStats = useCallback(async () => {
        if (!token || overallRecord !== undefined) return

        const result = await getPlayersStats(currentUserId || '', user.id, token)
        if (result) {
            setOverallRecordStats(result.stats)
        }
    }, [token, currentUserId, user.id])

    const onShow = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const onHide = () => {
        setAnchorEl(null)
    }

    const onShowClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (showOnClick) {
            setClicked(true)
            onShow(event)
        }
    }

    const onShowHover = (event: React.MouseEvent<HTMLElement>) => {
        if (!clicked && showOnHover) {
            onShow(event)
        }
    }

    const onHideHover = () => {
        if (!clicked && showOnHover) {
            onHide()
        }
    }

    const onHideClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (showOnClick) {
            setClicked(false)
            onHide()
        }
    }

    const onFollowClick = async () => {
        if (followEnabled) {
            if (follow) {
                await unfollowUser(user.id)
            } else {
                const result = await followUser(user.id)
                if (typeof result === 'object' && result?.error) {
                    setSnackbarMessage('Limit reached! You can follow up to 50 users')
                    setTimeout(() => {
                        setSnackbarMessage(undefined)
                    }, 2000)
                }
            }
            await updateUserFollowList()
        } else {
            setOverlayTrigger('joinUs')
        }
    }

    return (
        <React.Fragment>
            <Box
                aria-owns={id}
                aria-describedby={id}
                aria-haspopup="true"
                onClick={onShowClick}
                onMouseEnter={onShowHover}
                onMouseLeave={onHideHover}
                sx={{ ...sx, cursor: showOnClick ? 'pointer' : 'auto' }}
            >
                {children}
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onHideClick}
                onClick={(event) => {
                    event.stopPropagation()
                }}
                TransitionComponent={Zoom}
                anchorOrigin={{
                    vertical: origin === 'bottom' ? 'bottom' : 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: origin === 'bottom' ? 'top' : 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    pointerEvents: clicked ? 'auto' : 'none',
                    '& .MuiPopover-paper': {
                        border: `.2rem solid ${palette.text.primary}`,
                        backgroundColor: '#131D26',
                        borderRadius: 0,
                        overflowY: 'hidden',
                        minWidth: '320px',
                    },
                }}
                disableRestoreFocus
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: 0.5,
                        pb: currentUserId !== user.id ? 1 : 0.25,
                        pt: 1,
                        gap: 0.75,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        <Rating data={user.ratings.classic} usePadding />
                        <Rating data={user.ratings.rapid} usePadding />
                        <Rating data={user.ratings.bullet} usePadding />
                        <Rating data={user.ratings.blitz} usePadding />
                    </Box>
                    <Box sx={{ display: 'flex', px: 0.5 }}>
                        <FramedAvatar
                            avatar={user?.avatarUrl}
                            sx={avatarStyle}
                            season={user.reward?.season}
                            reward={user.reward?.tier}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                px: '.5rem',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <StatusIndicator status={user.status} style={{ marginRight: '.38rem' }} />
                                <Typography
                                    sx={{
                                        fontSize: '.94rem',
                                        lineHeight: '1.375rem',
                                        fontFamily: 'Space Grotesk',
                                        fontWeight: 400,
                                        color: user.status === 'online' ? colors.green : colors.gray,
                                    }}
                                >
                                    {user.status === 'online' ? 'Online' : 'Offline'}
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    lineHeight: '1.375rem',
                                    fontFamily: 'Space Grotesk',
                                    fontWeight: 500,
                                    py: '.38rem',
                                    letterSpacing: '.06rem',
                                }}
                            >
                                {user.userName}
                            </Typography>
                            <img
                                src={`/flags/${user.country || 'WORLD'}@2x.png`}
                                onError={imgFallback}
                                alt={'flag icon'}
                                style={{
                                    width: '1.5rem',
                                    height: '1.125rem',
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: overallRecordStats ? 'flex-start' : 'flex-end',
                            px: '.25rem',
                        }}
                    >
                        {!!overallRecordStats && (
                            <Box sx={overallRecordContainerStyles}>
                                <Typography sx={{ ...overallRecordTextStyles, color: colors.green }}>
                                    {overallRecordStats.win}
                                </Typography>
                                <Typography sx={{ ...overallRecordTextStyles, px: '.25rem' }}>/</Typography>
                                <Typography sx={{ ...overallRecordTextStyles, px: '.25rem' }}>
                                    {overallRecordStats.draw}
                                </Typography>
                                <Typography sx={{ ...overallRecordTextStyles, px: '.25rem' }}>/</Typography>
                                <Typography sx={{ ...overallRecordTextStyles, color: colors.red }}>
                                    {overallRecordStats.loss}
                                </Typography>
                                <Typography sx={{ ...overallRecordTextStyles, px: '.5rem' }}>VS</Typography>
                                <Typography
                                    sx={{
                                        fontSize: '.7rem',
                                        fontFamily: 'Space Grotesk',
                                        lineHeight: '1.125rem',
                                        fontWeight: 500,
                                        letterSpacing: '.04rem',
                                    }}
                                >
                                    {currentUserName}
                                </Typography>
                            </Box>
                        )}
                        {clicked && (
                            <Stack direction="row" gap={1} justifyContent="space-between" flexGrow={1}>
                                {/* left side buttons block */}
                                <Stack direction="row" alignItems="center">
                                    {currentUserId !== user.id && (
                                        <Button
                                            sx={{
                                                minWidth: 'auto',
                                                width: 'auto',
                                                height: '34px !important',
                                                minHeight: 'auto',
                                                fontSize: '16px',
                                            }}
                                            startIcon={follow ? <UnfollowIcon /> : <FollowIcon />}
                                            variant={follow ? 'outlined' : 'contained'}
                                            onClick={onFollowClick}
                                        >
                                            {follow ? 'Unfollow' : 'Follow'}
                                        </Button>
                                    )}
                                </Stack>

                                {/* right side buttons block */}
                                <Stack direction="row">
                                    {onStartChallenge !== undefined && user.status === 'online' && (
                                        <IconButton
                                            sx={{
                                                '& path': {
                                                    fill: palette.primary.main,
                                                },
                                                p: 0.5,
                                            }}
                                            onClick={() => onStartChallenge(user.id)}
                                        >
                                            <ChallengeIcon
                                                style={{
                                                    width: '1.75rem',
                                                    height: '1.75rem',
                                                    flexShrink: 0,
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </Stack>
                            </Stack>
                        )}
                    </Box>
                </Box>
            </Popover>
        </React.Fragment>
    )
}

export default ProfileInfo
