import { useEffect } from 'react'
import { UserData } from '../../GlobalHeader'
import {
    AnalyticsEventMap,
    BotChallengeEvent,
    ClickVideoEvent,
    CompleteLoginEvent,
    CompleteSignupEvent,
    CompletedPuzzleEvent,
    CustomChallengeEvent,
    DirectChallengeEvent,
    EmailActivatedEvent,
    EnterAutoPoolEvent,
    ExitAutoPoolEvent,
    GameFinishedEvent,
    GameOfferResultEvent,
    GameOfferSentEvent,
    GameStartedEvent,
    LogoutEvent,
    OfferRematchEvent,
    OfferRematchResultEvent,
    OpenLoginEvent,
    OpenSignupEvent,
    ResignEvent,
    RewardEvent,
    analyticsManager,
} from './AnalyticsManager'
import { getHighestUserGroupName } from './utils'

type Props = {
    env: string
    userData: UserData | undefined
}

export const AnalyticsLoggingServiceComponent = ({ env, userData }: Props) => {
    useEffect(() => {
        if (env === 'prod') return

        const userId = userData?.id
        const userName = userData?.user_name
        const userType = userId ? getHighestUserGroupName(userData.groups) : 'visitor'

        const eventHandlers: { [key in keyof AnalyticsEventMap]?: Function } = {
            botChallenge: (e: BotChallengeEvent) => {
                console.log('[AnalyticsLoggingService] botChallenge event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            completeLogin: (e: CompleteLoginEvent) => {
                console.log('[AnalyticsLoggingService] completeLogin event', e, {
                    env,
                    userId: e.userId,
                    userType,
                    userName: e.userName,
                })
            },
            completeSignup: (e: CompleteSignupEvent) => {
                console.log('[AnalyticsLoggingService] completeSignup event', e, {
                    env,
                    userId: e.userId,
                    userType,
                    userName: e.userName,
                })
            },
            openSignup: (e: OpenSignupEvent) => {
                console.log('[AnalyticsLoggingService] openSignup event', e, { env, userId, userType, userName })
            },
            clickVideo: (e: ClickVideoEvent) => {
                console.log('[AnalyticsLoggingService] clickVideo event', e, { env, userId, userType, userName })
            },
            logout: (e: LogoutEvent) => {
                console.log('[AnalyticsLoggingService] logout event', e, { env, userId, userType, userName })
            },
            gameOfferSent: (e: GameOfferSentEvent) => {
                console.log('[AnalyticsLoggingService] gameOfferSent event', e, { env, userId, userType, userName })
            },
            gameOfferResult: (e: GameOfferResultEvent) => {
                console.log('[AnalyticsLoggingService] gameOfferResult event', e, { env, userId, userType, userName })
            },
            openLogin: (e: OpenLoginEvent) => {
                console.log('[AnalyticsLoggingService] openLogin event', e, { env, userId, userType, userName })
            },
            exitAutoPool: (e: ExitAutoPoolEvent) => {
                console.log('[AnalyticsLoggingService] exitAutoPool event', e, { env, userId, userType, userName })
            },
            customChallenge: (e: CustomChallengeEvent) => {
                console.log('[AnalyticsLoggingService] customChallenge event', e, { env, userId, userType, userName })
            },
            offerRematch: (e: OfferRematchEvent) => {
                console.log('[AnalyticsLoggingService] offerRematch event', e, { env, userId, userType, userName })
            },
            resign: (e: ResignEvent) => {
                console.log('[AnalyticsLoggingService] resign event', e, { env, userId, userType, userName })
            },
            completedPuzzle: (e: CompletedPuzzleEvent) => {
                console.log(`[AnalyticsLoggingService] completedPuzzle event - ${e.origin}`, e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            gameFinished: (e: GameFinishedEvent) => {
                console.log('[AnalyticsLoggingService] gameFinished event', e, { env, userId, userType, userName })
            },
            enterAutoPool: (e: EnterAutoPoolEvent) => {
                console.log('[AnalyticsLoggingService] enterAutoPool event', e, { env, userId, userType, userName })
            },
            gameStarted: (e: GameStartedEvent) => {
                console.log('[AnalyticsLoggingService] gameStarted event', e, { env, userId, userType, userName })
            },
            reward: (e: RewardEvent) => {
                console.log('[AnalyticsLoggingService] reward event', e, { env, userId, userType, userName })
            },
            directChallenge: (e: DirectChallengeEvent) => {
                console.log('[AnalyticsLoggingService] directChallenge event', e, { env, userId, userType, userName })
            },
            offerRematchResult: (e: OfferRematchResultEvent) => {
                console.log('[AnalyticsLoggingService] offerRematchResult event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            emailActivated: (e: EmailActivatedEvent) => {
                console.log('[AnalyticsLoggingService] emailActivated event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
        }

        console.log('[AnalyticsLoggingService] initialize analytics logging service')

        // Add listeners using the stored references
        Object.entries(eventHandlers).forEach(([event, handler]) => {
            analyticsManager.addEventListener(event as any, handler as any)
        })

        return () => {
            // Remove listeners using the stored references
            Object.entries(eventHandlers).forEach(([event, handler]) => {
                analyticsManager.removeEventListener(event as any, handler as any)
            })
        }
    }, [env, userData?.id, userData?.groups])

    return null
}
