import CloseIcon from '@mui/icons-material/Close'
import { alpha, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { FC } from 'react'

export interface PopupProps {
    popup: {
        name: string
        asset: string
        ctaText: string
        ctaLink: string
        textColor: string
        bgColor: string
    }
    handleClose: () => void
    handleCTA: (link: string) => void
}

export const Popup: FC<PopupProps> = ({ popup, handleClose, handleCTA }) => {
    const handleInternalCTA = () => {
        if (popup.ctaLink) {
            handleCTA(popup.ctaLink)
        } else {
            handleClose()
        }
    }

    return (
        <Dialog
            open={Boolean(popup)}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                zIndex: 9999,
                '.MuiDialog-paper': {
                    flexDirection: 'column',
                    // maxHeight: 'calc(100% - 64px)'
                    maxWidth: '680px',
                    width: '100%',
                    margin: '1rem',
                },
                '.MuiPaper-root': {
                    backgroundColor: popup.bgColor,
                    p: 4,
                },
            }}
        >
            <DialogTitle>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        ...(popup.textColor
                            ? {
                                  color: `${popup.textColor} !important`,
                                  '&:hover': {
                                      backgroundColor: `${alpha(popup.textColor, 0.3)} !important`,
                                  },
                              }
                            : {}),
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                <img height="100%" width="100%" src={`${popup.asset}`} />

                {/* <DialogContentText></DialogContentText> */}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    sx={
                        popup.textColor
                            ? {
                                  borderColor: `${popup.textColor} !important`,
                                  color: `${popup.textColor} !important`,
                                  '&:hover': {
                                      backgroundColor: `${alpha(popup.textColor, 0.3)} !important`,
                                  },
                              }
                            : {}
                    }
                    variant="outlined"
                    onClick={handleInternalCTA}
                >
                    {popup.ctaText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
