import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const DailyPuzzleButtons: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Stack direction="row" justifyContent="center" gap={1}>
            <Button onClick={() => navigate('/puzzles')}>More Puzzles</Button>
            <Button onClick={() => navigate('/puzzle-quest')}>Puzzle Quest</Button>
        </Stack>
    )
}

DailyPuzzleButtons.displayName = 'DailyPuzzleButtons'

export default DailyPuzzleButtons
