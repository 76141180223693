import { InputLabel, Stack, Switch, TextField } from '@mui/material'
import { ChangeEventHandler, useState } from 'react'

interface IProps {
    label: string
    type: string
    value?: string
    onChange?: ChangeEventHandler<HTMLInputElement>
    name?: string
    readOnly?: boolean
}

const Input: React.FC<IProps> = (props) => {
    const { label, type, value, onChange, name, readOnly = false } = props
    const [enabled, setEnabled] = useState(false)

    if (type === 'switch') {
        return (
            <div>
                <Switch checked={enabled} onChange={() => setEnabled(!enabled)} />
                <InputLabel>{label}</InputLabel>
            </div>
        )
    }

    return (
        <Stack flexGrow={1}>
            <TextField
                variant="standard"
                type={type}
                disabled={readOnly}
                name={name}
                value={value}
                onChange={onChange}
                label={label}
            />
        </Stack>
    )
}

Input.displayName = 'Input'

export default Input
