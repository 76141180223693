import { action, Action, thunk, Thunk } from 'easy-peasy'
import { MessageType } from '../../API/generated/websocket-gateway/message_type'

import { MessageTypeToResponse } from '../../API/grpcHelpers'
import { StoreModel } from '../../store/store'

// Define the WebSocket model interface
export interface PopupServiceModel {
    // State
    popups: MessageTypeToResponse[MessageType.POPUP_ACTION]['popups']

    // Actions
    setPopups: Action<PopupServiceModel, MessageTypeToResponse[MessageType.POPUP_ACTION]>
    removePopups: Action<PopupServiceModel>

    // Thunks
    establishListener: Thunk<PopupServiceModel, undefined, undefined, StoreModel>
    shareLocation: Thunk<PopupServiceModel, string, undefined, StoreModel>
}

// WebSocket model implementation
export const PopupServiceModel: PopupServiceModel = {
    // State
    popups: [],

    // Actions
    setPopups: action((state, message) => {
        state.popups = message.popups
    }),

    removePopups: action((state) => {
        state.popups = []
    }),

    // Thunks
    establishListener: thunk((actions, _, { getStoreState, getStoreActions }) => {
        const { ws } = getStoreActions()
        const { isConnected } = getStoreState().ws

        if (isConnected) {
            ws.addListener({ messageType: MessageType.POPUP_ACTION, callback: (message) => actions.setPopups(message) })
        }
    }),

    shareLocation: thunk((actions, url, { getStoreState, getStoreActions }) => {
        const clientUUID = getStoreState().userData.userData?.id

        clientUUID &&
            getStoreActions().ws.sendMessage({
                messageType: MessageType.POPUP_ACTION,
                message: { clientId: { value: clientUUID }, url },
            })
    }),
}

// WebsocketModel
