import { Box } from '@mui/material'
import { FC, useRef } from 'react'
import { Stripe, StripeProps } from './Stripe'

interface HeaderStripeProps extends StripeProps {
    leftChildren?: React.ReactNode
    rightChildren?: React.ReactNode
    bottomChildren?: React.ReactNode
}

export const HeaderStripe: FC<HeaderStripeProps> = ({ title, leftChildren, rightChildren, bottomChildren }) => {
    const ref = useRef<HTMLElement>(null)

    return (
        <Box
            ref={ref}
            sx={{
                width: 'min(460px, 100%)',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
            }}
        >
            <Stripe title={title} placeholder />
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: '100',
                    top: 0,
                    left: { xs: '-8px', md: '-12px' },
                    right: { xs: '8px', md: '12px' },
                    backgroundSize: 'contain',
                    svg: {
                        width: { xs: 'calc(100% + 16px)', md: 'calc(100% + 24px)' },
                    },
                }}
            >
                <Stripe title={title} />
            </Box>
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    border: { xs: 'none', md: 'solid' },
                    borderTop: 'none !important',
                }}
            >
                <Box
                    sx={{
                        zIndex: 101,
                        marginTop: { xs: 0, md: '0px' },
                        height: { xs: '54px', md: '64px' },
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderBottomRightRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        border: 'solid',
                        gap: { xs: '40px', md: '35px' },
                        width: '100%',
                        maxWidth: { xs: 'unset', md: '65%' },
                        borderTop: 'none',
                        textAlign: 'center',
                    }}
                >
                    {leftChildren}
                    {rightChildren}
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        my: '5px',
                    }}
                >
                    {bottomChildren}
                </Box>
            </Box>
        </Box>
    )
}
