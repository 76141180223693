// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.2
// source: popup-service/fetch_popups_request.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { UUID } from "../common/common";

export const protobufPackage = "popup_service";

export interface FetchPopupsRequest {
  clientId: UUID | undefined;
  url: string;
}

function createBaseFetchPopupsRequest(): FetchPopupsRequest {
  return { clientId: undefined, url: "" };
}

export const FetchPopupsRequest: MessageFns<FetchPopupsRequest> = {
  encode(message: FetchPopupsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.clientId !== undefined) {
      UUID.encode(message.clientId, writer.uint32(10).fork()).join();
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FetchPopupsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchPopupsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.clientId = UUID.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
}
