import { useEffect, useMemo } from 'react'
import { positionToFEN } from '../../../../chess/fen'
import { getPositionById } from '../../../../chess/gameTree'
import { BasePositionFEN } from '../../../../chess/positionPresets'
import { Color, Move } from '../../../../chess/types'
import { EvalBar } from '../../../../components/evalBar/EvalBar'
import HorizontalEvalBar from '../../../../components/evalBar/HorizontalEvalBar'
import { Layout, useResponsiveSizings } from '../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreState } from '../../../../store/hooks'
import { useStockfish } from '../../../analysisView/useStockfish/useStockFish'
import { ChessBoard } from '../../../chessboard/ChessBoard'
import { GameViewState } from '../../../gameView/GameViewModel'

type Props = {
    layout: Layout
    gameState: GameViewState
}

export const ObserveBoardContent = ({ layout, gameState }: Props) => {
    let gameTree = useStoreState((state) => state.observeView.gameTree)
    const currentPositionId = useStoreState((state) => state.observeView.currentPositionId)
    const evalBarVisibility = useStoreState((state) => state.observeView.evalBarVisibility)

    const currentPosition = useMemo(() => {
        return getPositionById(gameTree, currentPositionId)
    }, [gameTree, currentPositionId])

    const liveGameData = useStoreState((state) => state.observeView.liveGameData)
    const colorToMove = useMemo(() => {
        return currentPosition.position.turn
    }, [gameTree, currentPositionId])
    // ----------------------------------------------------------------- Engine Stuff

    const { allVariations, isEngineLoaded, startEngine, stopEngine } = useStockfish({
        fen: positionToFEN(getPositionById(gameTree, currentPositionId).position) || BasePositionFEN,
        lines: 1,
        // This is the depth of the analysis
        depth: 19,
    })

    useEffect(() => {
        if (!isEngineLoaded && liveGameData?.matchId && evalBarVisibility) {
            startEngine()
        } else if (isEngineLoaded && !evalBarVisibility) {
            stopEngine()
        }
    }, [evalBarVisibility, liveGameData?.matchId, isEngineLoaded])

    let evalScore = 0
    if (allVariations.v1.scoreType === 'cp') {
        evalScore = Number(allVariations.v1.score) / 100
    } else if (allVariations.v1.scoreType === 'mate') {
        evalScore = colorToMove === 1 ? Number(allVariations.v1.score) : -Number(allVariations.v1.score)
    }

    const {
        resolution: { isDesktop, isMobile },
    } = useResponsiveSizings()

    const flipped = useStoreState((state) => state.observeView.flipped)

    const onMove = (move: Move): boolean => {
        return false
    }

    const mate = allVariations.v1.scoreType === 'mate'

    return (
        <>
            {isDesktop && isEngineLoaded && <EvalBar score={evalScore} mate={mate} colorToMove={colorToMove} />}
            <ChessBoard
                observer={true}
                gameTree={gameTree}
                currentPositionId={currentPositionId}
                position={currentPosition.position}
                flipped={flipped}
                onMove={onMove}
                gameRunning={gameState === GameViewState.GAME_RUNNING}
                myColor={Color.White}
            />

            {isMobile && isEngineLoaded && (
                <HorizontalEvalBar colorToMove={colorToMove} evalScore={evalScore} mate={mate} />
            )}
        </>
    )
}
