import { Stack, SxProps, Theme, Typography } from '@mui/material'
import React, { useState } from 'react'
import { RatingCounter } from './RatingCounter'

interface PuzzleRatingProps {
    userRating: number
    animationDuration?: number
    wrapperStyles?: SxProps<Theme>
}

const puzzleImgStyles: React.CSSProperties = {
    verticalAlign: 'middle',
    width: '1.625rem',
    height: '1.625rem',
}

const ratingStyles = {
    fontVariantNumeric: 'tabular-nums',
    verticalAlign: 'middle',
    fontSize: '22px',
    textTransform: 'uppercase' as 'uppercase',
}

const PuzzleRating: React.FC<PuzzleRatingProps> = ({ userRating = 0, animationDuration = 2000, wrapperStyles }) => {
    const [rating, setRating] = useState(Math.round(userRating))

    const onAnimationEnd = () => {
        setRating(Math.round(userRating))
    }

    return (
        <Stack direction="row" spacing={{ xs: 0.5, md: 1 }} alignItems="flex-end" sx={wrapperStyles}>
            <img src={'/assets/images/icons/puzzle.svg'} alt="Puzzle Rating" style={puzzleImgStyles} />
            <Typography color="secondary" variant="h6" sx={ratingStyles}>
                <RatingCounter
                    oldRating={rating}
                    newRating={Math.round(userRating)}
                    onAnimationEnd={onAnimationEnd}
                    animationDuration={animationDuration}
                />
            </Typography>
        </Stack>
    )
}

export default PuzzleRating
