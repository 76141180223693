import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { OverlayType } from '../globalHeader/GlobalHeader'

interface IProps {
    title?: string
    description: string
    openSignInForm: () => void
    openSignUpForm: () => void
}

const LoginAction: React.FC<IProps> = (props) => {
    const { title, description, openSignInForm, openSignUpForm } = props

    const openOverlay = (overlay: OverlayType) => {
        switch (overlay) {
            case OverlayType.SIGNIN:
                openSignInForm()
                break
            case OverlayType.SIGNUP:
                openSignUpForm()
                break
            default:
                break
        }
    }

    return (
        <Stack
            spacing={1}
            sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', maxWidth: '340px', p: 1 }}
        >
            {title && (
                <Typography variant="h3" color="text.primary">
                    {title}
                </Typography>
            )}
            <Typography variant="body1" color="text.primary">
                {description}
            </Typography>
            <Stack spacing={2} sx={{ pt: 1 }}>
                <Button sx={{ minWidth: '260px' }} onClick={() => openOverlay(OverlayType.SIGNUP)}>
                    Sign up
                </Button>
                <Button variant="outlined" sx={{ minWidth: '260px' }} onClick={() => openOverlay(OverlayType.SIGNIN)}>
                    Log in
                </Button>
            </Stack>
        </Stack>
    )
}

LoginAction.displayName = 'LoginAction'

export default LoginAction
