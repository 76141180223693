import puzzlesAPI from '../../API/puzzlesConfig'
import { PuzzleStatus } from '../PuzzlesView/functions/puzzlesApi'
import { QuestPuzzle } from './types'

export const getPuzzleQuest = async (): Promise<QuestPuzzle> => {
    return await puzzlesAPI.get('/api/quest/puzzles/next')
        .then((response) => {
            return response.data
        })
        .then((data) => data)
        .catch((error) => {
            console.error('[] Error:', error)
        })
}

export interface IResolvePuzzleQuest {
    puzzleId: string
    status: PuzzleStatus
    usedHints: number
}

export const resolvePuzzle = async (data: IResolvePuzzleQuest): Promise<QuestPuzzle> => {
    return await puzzlesAPI.put(`/api/quest/puzzles/${data.puzzleId}/resolve`, {
        status: data.status,
        usedHints: data.usedHints,
    })
        .then((response) => {
            return response.data
        })
        .then((data) => data)
        .catch((error) => {
            console.error('[] Error:', error)
        })
}
