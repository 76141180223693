import SquareIcon from '@mui/icons-material/Square'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Rules } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import { useResponsiveSizings } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import {
    GAME_HISTORY_COLUMNS,
    GAME_HISTORY_PHONE_COLUMNS,
    GameHistoryColumns,
    GameHistoryTypes,
    SortDirection,
} from '../constants'

const tableColumnStyle = {
    fontFamily: 'Space Grotesk',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.75rem',
    px: '.5rem',
    width: 'auto',
}

const headerTextStyle = {
    fontFamily: 'Fira Sans',
    fontWeight: 500,
    fontSize: '1.3rem',
    lineHeight: '2rem',
}

const tableTextStyle = {
    fontFamily: 'Space Grotesk',
    fontWeight: 700,
    fontSize: { xs: '1rem', sm: '1.15rem' },
    lineHeight: '1.75rem',
}

const tableHeaderColumnStyle = (arrayLength: number, index: number, isSmallPadding: boolean) => {
    return {
        ...tableTextStyle,
        backgroundColor: 'background.paper',
        pl: isSmallPadding || index !== 0 ? '.5rem' : 4,
        pr: isSmallPadding || arrayLength !== index ? '.5rem' : 4,
    }
}

const GameHistory: React.FC = () => {
    const user = useStoreState((state) => state.userData.userData)
    const gameHistory = useStoreState((state) => state.profileView.gameHistory)
    const recordAmount = useStoreState((state) => state.profileView.recordAmount)
    const historyLoading = useStoreState((state) => state.profileView.historyLoading)
    const filter = useStoreState((state) => state.profileView.filter)
    const gamesPerPage = useStoreState((state) => state.profileView.gamesPerPage)
    const sort = useStoreState((state) => state.profileView.sort)
    const activePage = useStoreState((state) => state.profileView.activePage)
    const rules = useStoreState((state) => state.rules)

    const setFilter = useStoreActions((state) => state.profileView.setFilter)
    const setGamesPerPage = useStoreActions((state) => state.profileView.setGamesPerPage)
    const setSort = useStoreActions((state) => state.profileView.setSort)
    const setActivePage = useStoreActions((state) => state.profileView.setActivePage)
    const getGameHistory = useStoreActions((state) => state.profileView.getGameHistory)

    const navigate = useNavigate()
    const { layout, resolution } = useResponsiveSizings()
    const cells = resolution.isMobile ? GAME_HISTORY_PHONE_COLUMNS : GAME_HISTORY_COLUMNS
    const [headCells, setHeadCells] = useState(cells)

    const profileEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.PROFILE)

    useEffect(() => {
        setHeadCells(cells)
        setGamesPerPage(resolution.isMobile ? 10 : 25)
    }, [layout])

    useEffect(() => {
        if (profileEnabled) {
            getGameHistory()
        }
    }, [filter, gamesPerPage, sort, activePage, profileEnabled])

    const sortChanged = (property: GameHistoryColumns) => {
        const isAsc = sort.orderBy === property && sort.order === SortDirection.asc
        setSort({
            order: isAsc ? SortDirection.desc : SortDirection.asc,
            orderBy: property,
        })
    }

    const filterChanged = (value: string) => {
        setFilter(value as GameHistoryTypes)
    }

    const handleChangePage = (page: number) => {
        setActivePage(page)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = Number(event.target.value)
        setGamesPerPage(value)
    }

    const goToAnalysis = (matchId: string) => {
        navigate(`/analysis/${matchId}`)
    }

    if (!profileEnabled) {
        return null
    }

    return (
        <Stack sx={{ backgroundColor: 'background.paper' }}>
            <Stack
                direction="row"
                sx={{
                    backgroundColor: 'background.paper',
                    py: 2,
                    px: { xs: 2, md: 5 },
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h2">Games History</Typography>
                <Select
                    sx={{
                        '& .MuiSelect-select': {
                            ...headerTextStyle,
                            color: 'text.primary',
                        },
                        '& .MuiInputBase-input': {
                            padding: '8px 32px 8px 8px !important',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'text.primary',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    MenuProps={{
                        sx: {
                            '& .MuiMenuItem-root': {
                                ...headerTextStyle,
                            },
                        },
                    }}
                    value={filter}
                    onChange={(event) => filterChanged(event.target.value)}
                >
                    <MenuItem value={GameHistoryTypes.all}>All</MenuItem>
                    <MenuItem value={GameHistoryTypes.bullet}>
                        {resolution.isMobile ? 'Bullet' : `Bullet (${user?.ratings.bullet.rating || 0})`}
                    </MenuItem>
                    <MenuItem value={GameHistoryTypes.blitz}>
                        {resolution.isMobile ? 'Blitz' : `Blitz (${user?.ratings.blitz.rating || 0})`}
                    </MenuItem>
                    <MenuItem value={GameHistoryTypes.rapid}>
                        {resolution.isMobile ? 'Rapid' : `Rapid (${user?.ratings.rapid.rating || 0})`}
                    </MenuItem>
                    <MenuItem value={GameHistoryTypes.classic}>
                        {resolution.isMobile ? 'Classical' : `Classical (${user?.ratings.classic.rating || 0})`}
                    </MenuItem>
                </Select>
            </Stack>
            <Paper sx={{ width: '100%', borderRadius: 0 }}>
                <TableContainer sx={{ maxHeight: 420 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell, index) => (
                                    <TableCell
                                        style={{ width: headCell.width }}
                                        key={index}
                                        sx={tableHeaderColumnStyle(headCells.length, index, resolution.isMobile)}
                                        sortDirection={sort.orderBy === headCell.id ? sort.order : false}
                                    >
                                        {resolution.isMobile || headCell.sortable === false ? (
                                            <>
                                                {headCell.name}
                                                {sort.orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {sort.order === SortDirection.desc
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </>
                                        ) : (
                                            <TableSortLabel
                                                active={sort.orderBy === headCell.id}
                                                direction={
                                                    sort.orderBy === headCell.id ? sort.order : SortDirection.asc
                                                }
                                                onClick={() => sortChanged(headCell.id)}
                                            >
                                                {headCell.name}
                                                {sort.orderBy === headCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {sort.order === SortDirection.desc
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!historyLoading ? (
                                <TableRow tabIndex={-1}>
                                    <TableCell
                                        align="center"
                                        sx={tableColumnStyle}
                                        colSpan={resolution.isMobile ? 3 : 5}
                                    >
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : gameHistory.length > 0 ? (
                                gameHistory.map((match, index) =>
                                    resolution.isMobile ? (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => goToAnalysis(match.matchId)}
                                        >
                                            <TableCell sx={tableColumnStyle}>{match.game}</TableCell>
                                            <TableCell sx={tableColumnStyle}>
                                                <Stack direction="row">
                                                    {match.color === 'white' ? <SquareOutlinedIcon /> : <SquareIcon />}
                                                    <Box>{match.opponent}</Box>
                                                </Stack>
                                            </TableCell>
                                            <TableCell sx={tableColumnStyle}>{match.result}</TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => goToAnalysis(match.matchId)}
                                        >
                                            <TableCell sx={{ ...tableColumnStyle, pl: 4 }}>{match.game}</TableCell>
                                            <TableCell sx={tableColumnStyle}>{match.date}</TableCell>
                                            <TableCell sx={tableColumnStyle}>{match.white}</TableCell>
                                            <TableCell sx={tableColumnStyle}>{match.black}</TableCell>
                                            <TableCell sx={{ ...tableColumnStyle, pr: 4 }}>{match.result}</TableCell>
                                        </TableRow>
                                    ),
                                )
                            ) : (
                                <TableRow tabIndex={-1}>
                                    <TableCell
                                        align="center"
                                        sx={tableColumnStyle}
                                        colSpan={resolution.isMobile ? 3 : 5}
                                    >
                                        Games not found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        '& .MuiTablePagination-selectLabel': {
                            ...tableTextStyle,
                        },
                        '& .MuiInputBase-input': {
                            padding: '8px 24px 8px 8px !important',
                        },
                        '& .MuiTablePagination-toolbar': {
                            ...tableTextStyle,
                        },
                        '& .MuiTablePagination-displayedRows': {
                            ...tableTextStyle,
                        },
                        '& .MuiTablePagination-actions': {
                            display: 'flex',
                        },
                    }}
                    labelRowsPerPage={resolution.isMobile ? '' : 'Games per page:'}
                    component="div"
                    count={recordAmount}
                    rowsPerPage={gamesPerPage}
                    page={activePage}
                    onPageChange={(event, page) => handleChangePage(page)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Stack>
    )
}

GameHistory.displayName = 'GameHistory'

export default GameHistory
