// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.2
// source: common/common.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "common";

export enum PlayerKind {
  UNSPECIFIED = "PLAYER_KIND_UNSPECIFIED",
  HUMAN = "PLAYER_KIND_HUMAN",
  MACHINE = "PLAYER_KIND_MACHINE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function playerKindFromJSON(object: any): PlayerKind {
  switch (object) {
    case 0:
    case "PLAYER_KIND_UNSPECIFIED":
      return PlayerKind.UNSPECIFIED;
    case 1:
    case "PLAYER_KIND_HUMAN":
      return PlayerKind.HUMAN;
    case 2:
    case "PLAYER_KIND_MACHINE":
      return PlayerKind.MACHINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PlayerKind.UNRECOGNIZED;
  }
}

export function playerKindToNumber(object: PlayerKind): number {
  switch (object) {
    case PlayerKind.UNSPECIFIED:
      return 0;
    case PlayerKind.HUMAN:
      return 1;
    case PlayerKind.MACHINE:
      return 2;
    case PlayerKind.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum PlayerColor {
  UNSPECIFIED = "PLAYER_COLOR_UNSPECIFIED",
  WHITE = "PLAYER_COLOR_WHITE",
  BLACK = "PLAYER_COLOR_BLACK",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function playerColorFromJSON(object: any): PlayerColor {
  switch (object) {
    case 0:
    case "PLAYER_COLOR_UNSPECIFIED":
      return PlayerColor.UNSPECIFIED;
    case 1:
    case "PLAYER_COLOR_WHITE":
      return PlayerColor.WHITE;
    case 2:
    case "PLAYER_COLOR_BLACK":
      return PlayerColor.BLACK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PlayerColor.UNRECOGNIZED;
  }
}

export function playerColorToNumber(object: PlayerColor): number {
  switch (object) {
    case PlayerColor.UNSPECIFIED:
      return 0;
    case PlayerColor.WHITE:
      return 1;
    case PlayerColor.BLACK:
      return 2;
    case PlayerColor.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum MatchKind {
  UNSPECIFIED = "MATCH_KIND_UNSPECIFIED",
  HUMAN_VS_HUMAN = "MATCH_KIND_HUMAN_VS_HUMAN",
  HUMAN_VS_MACHINE = "MATCH_KIND_HUMAN_VS_MACHINE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function matchKindFromJSON(object: any): MatchKind {
  switch (object) {
    case 0:
    case "MATCH_KIND_UNSPECIFIED":
      return MatchKind.UNSPECIFIED;
    case 1:
    case "MATCH_KIND_HUMAN_VS_HUMAN":
      return MatchKind.HUMAN_VS_HUMAN;
    case 2:
    case "MATCH_KIND_HUMAN_VS_MACHINE":
      return MatchKind.HUMAN_VS_MACHINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MatchKind.UNRECOGNIZED;
  }
}

export function matchKindToNumber(object: MatchKind): number {
  switch (object) {
    case MatchKind.UNSPECIFIED:
      return 0;
    case MatchKind.HUMAN_VS_HUMAN:
      return 1;
    case MatchKind.HUMAN_VS_MACHINE:
      return 2;
    case MatchKind.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum ChallengeType {
  UNSPECIFIED = "CHALLENGE_TYPE_UNSPECIFIED",
  DIRECT = "CHALLENGE_TYPE_DIRECT",
  AUTO_MATCH = "CHALLENGE_TYPE_AUTO_MATCH",
  CUSTOM = "CHALLENGE_TYPE_CUSTOM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function challengeTypeFromJSON(object: any): ChallengeType {
  switch (object) {
    case 0:
    case "CHALLENGE_TYPE_UNSPECIFIED":
      return ChallengeType.UNSPECIFIED;
    case 1:
    case "CHALLENGE_TYPE_DIRECT":
      return ChallengeType.DIRECT;
    case 2:
    case "CHALLENGE_TYPE_AUTO_MATCH":
      return ChallengeType.AUTO_MATCH;
    case 3:
    case "CHALLENGE_TYPE_CUSTOM":
      return ChallengeType.CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ChallengeType.UNRECOGNIZED;
  }
}

export function challengeTypeToNumber(object: ChallengeType): number {
  switch (object) {
    case ChallengeType.UNSPECIFIED:
      return 0;
    case ChallengeType.DIRECT:
      return 1;
    case ChallengeType.AUTO_MATCH:
      return 2;
    case ChallengeType.CUSTOM:
      return 3;
    case ChallengeType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface UUID {
  value: string;
}

export interface Rating {
  gameType: string;
  rating: number;
  rd: number;
  sigma: number;
}

export interface Group {
  id: string;
  name: string;
}

export interface TimeMode {
  durationMinutes: number;
  clockIncrementSeconds: number;
  name: string;
}

export interface RatingRange {
  from: number;
  to: number;
}

export interface Opponent {
  ChallengeID: UUID | undefined;
  PlayerID: string;
  Color: PlayerColor;
}

export interface Empty {
}

function createBaseUUID(): UUID {
  return { value: "" };
}

export const UUID: MessageFns<UUID> = {
  encode(message: UUID, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UUID {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUUID();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseRating(): Rating {
  return { gameType: "", rating: 0, rd: 0, sigma: 0 };
}

export const Rating: MessageFns<Rating> = {
  encode(message: Rating, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.gameType !== "") {
      writer.uint32(10).string(message.gameType);
    }
    if (message.rating !== 0) {
      writer.uint32(21).float(message.rating);
    }
    if (message.rd !== 0) {
      writer.uint32(29).float(message.rd);
    }
    if (message.sigma !== 0) {
      writer.uint32(37).float(message.sigma);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Rating {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRating();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.gameType = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 21) {
            break;
          }

          message.rating = reader.float();
          continue;
        }
        case 3: {
          if (tag !== 29) {
            break;
          }

          message.rd = reader.float();
          continue;
        }
        case 4: {
          if (tag !== 37) {
            break;
          }

          message.sigma = reader.float();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseGroup(): Group {
  return { id: "", name: "" };
}

export const Group: MessageFns<Group> = {
  encode(message: Group, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Group {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseTimeMode(): TimeMode {
  return { durationMinutes: 0, clockIncrementSeconds: 0, name: "" };
}

export const TimeMode: MessageFns<TimeMode> = {
  encode(message: TimeMode, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.durationMinutes !== 0) {
      writer.uint32(13).float(message.durationMinutes);
    }
    if (message.clockIncrementSeconds !== 0) {
      writer.uint32(16).uint32(message.clockIncrementSeconds);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TimeMode {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 13) {
            break;
          }

          message.durationMinutes = reader.float();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.clockIncrementSeconds = reader.uint32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseRatingRange(): RatingRange {
  return { from: 0, to: 0 };
}

export const RatingRange: MessageFns<RatingRange> = {
  encode(message: RatingRange, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.from !== 0) {
      writer.uint32(8).uint32(message.from);
    }
    if (message.to !== 0) {
      writer.uint32(16).uint32(message.to);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RatingRange {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRatingRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.from = reader.uint32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.to = reader.uint32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseOpponent(): Opponent {
  return { ChallengeID: undefined, PlayerID: "", Color: PlayerColor.UNSPECIFIED };
}

export const Opponent: MessageFns<Opponent> = {
  encode(message: Opponent, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ChallengeID !== undefined) {
      UUID.encode(message.ChallengeID, writer.uint32(10).fork()).join();
    }
    if (message.PlayerID !== "") {
      writer.uint32(18).string(message.PlayerID);
    }
    if (message.Color !== PlayerColor.UNSPECIFIED) {
      writer.uint32(24).int32(playerColorToNumber(message.Color));
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Opponent {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpponent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.ChallengeID = UUID.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.PlayerID = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.Color = playerColorFromJSON(reader.int32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseEmpty(): Empty {
  return {};
}

export const Empty: MessageFns<Empty> = {
  encode(_: Empty, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Empty {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
}
