import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Popup } from '../../sharedComponents/src/Popup/Popup'
import { useStoreActions, useStoreState } from '../../store/hooks'

export const PopupService = () => {
    const navigate = useNavigate()
    const { shareLocation, removePopups } = useStoreActions((actions) => actions.popupService)
    const { popups } = useStoreState((state) => state.popupService)
    const [popup] = popups

    const { isConnected } = useStoreState((state) => state.ws)
    const { pathname } = useLocation()

    useEffect(() => {
        if (isConnected) {
            if (pathname === '/') {
                shareLocation('/play')
            } else {
                shareLocation(pathname)
            }
        }
    }, [isConnected, pathname])

    const handleClose = () => {
        removePopups()
    }

    const handleCTA = (url) => {
        handleClose()
        if (url) {
            if (url.includes(window.location.origin)) {
                const parsedUrl = url.replace(window.location.origin, '')
                navigate(parsedUrl)
            } else {
                window.location.href = url
            }
        }
    }

    if (!popup) return null

    return <Popup popup={popup} handleClose={handleClose} handleCTA={handleCTA} />
}
