import { alpha, Box, Fade, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'

export const DateBoardOverlay: React.FC<{
    date: string
}> = ({ date }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 2000)
    }, [date])

    return (
        <Fade in={show} timeout={1000}>
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1000,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        color: 'white',
                        display: 'flex',
                        borderRadius: '8px',
                        border: 'solid',
                        backgroundColor: 'background.paper',
                        width: 'min(340px, 80%)',
                        justifyContent: 'center',
                        padding: '10px',
                        fontSize: '14px',
                        lineHeight: '100%',
                    }}
                >
                    {format(date, 'MMMM dd, yyyy')}
                </Typography>
            </Box>
        </Fade>
    )
}
