import { FC } from 'react'

const fontStyles = {
    color: '#FFD568',
    fill: '#FFD568',
    textAnchor: 'middle',
    fontFamily: 'Fira Sans',
    fontSize: '1.02em', // Use relative units to scale with the SVG
    fontWeight: '700',
    textTransform: 'uppercase',
}

export interface StreakResult {
    value: number
    show: boolean
}

export const StreakResult: FC<StreakResult> = ({ value, show }) => {
    if (!show)
        return (
            <svg width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
        )

    return (
        <svg width="100%" height="100%" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <text x="50%" y="65.5%" dominantBaseline="middle" {...fontStyles}>
                <animate attributeName="opacity" values="0;0;1" dur="2s" repeatCount="1"></animate>
                {value}
            </text>
        </svg>
    )
}
