import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Stack, Typography } from '@mui/material'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import { modeIcon, RatingType } from '../../../components/matchmaking/ChallengeCard'
import HelpTooltip from '../../../sharedComponents/src/globalHeader/components/HelpTooltip'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { StatsColumn, TooltipText } from './StatsTable'

interface IProps {
    ratingType: RatingType
}

const StatsContainer: React.FC<IProps> = (props) => {
    const { ratingType } = props
    const user = useStoreState((state) => state.userData.userData)
    const stats = useStoreState((state) => state.profileView.stats)
    const selectedContainer = useStoreState((state) => state.profileView.selectedContainer)
    const settings = useStoreState((state) => state.gameView.settings)
    const setSelectedContainer = useStoreActions((state) => state.profileView.setSelectedContainer)

    if (!user) {
        return null
    }

    const data = user.ratings[ratingType]

    return (
        <Stack>
            <MenuItem
                selected={selectedContainer === ratingType}
                divider={selectedContainer !== ratingType}
                sx={{
                    borderBottomWidth: 2,
                    borderBottomColor: 'text.primary',
                    color: 'text.primary',
                    lineHeight: '2rem',
                    py: 2,
                    mx: 2,
                    px: 1,
                    gap: 1,
                    '&.Mui-selected': {
                        color: 'background.default',
                        backgroundColor: 'text.primary',
                        '&:hover': {
                            color: 'background.default',
                            backgroundColor: 'text.primary',
                        },
                    },
                }}
                onClick={() => setSelectedContainer(selectedContainer === ratingType ? '' : data.game_type)}
            >
                <Stack
                    sx={(theme) => ({
                        '& svg': {
                            fill:
                                selectedContainer === ratingType
                                    ? theme.palette.background.default
                                    : theme.palette.text.primary,
                            width: '25px',
                            height: '100%',
                        },
                    })}
                >
                    {modeIcon(ratingType)}
                </Stack>
                <Stack direction="row" gap={1} sx={{ flexGrow: 1 }}>
                    <Typography variant="h5" textTransform="capitalize">
                        {ratingType}
                    </Typography>
                    <HelpTooltip
                        title={
                            <TooltipText
                                text={
                                    !!settings.friendlyMode
                                        ? 'Turn OFF Casual Play in your settings to view ratings'
                                        : 'Provisional Rating'
                                }
                            />
                        }
                        hideTooltip={settings.friendlyMode === false ? data.is_provisional === false : false}
                        placement="bottom-end"
                    >
                        {!settings.friendlyMode ? (
                            <Typography variant="h5">{` (${data.rating})`}</Typography>
                        ) : (
                            <Typography variant="h5">-</Typography>
                        )}
                    </HelpTooltip>
                </Stack>
                {selectedContainer === ratingType ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </MenuItem>
            {selectedContainer === ratingType ? (
                <Grow in={selectedContainer === ratingType}>
                    <Stack sx={{ border: '4px solid', borderColor: 'text.primary', borderTop: '0', mx: 2 }}>
                        {stats.map((item, index) => {
                            if (item.name === 'Rating') {
                                return null
                            }

                            return (
                                <Stack direction="row" key={index}>
                                    <Typography
                                        variant="h5"
                                        textTransform="capitalize"
                                        sx={{
                                            px: 2,
                                            py: 2,
                                            flexBasis: '50%',
                                            backgroundColor: 'background.default',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                    <StatsColumn
                                        text={item[ratingType]}
                                        color={item.color}
                                        secondaryText={item[`${ratingType}Info`]}
                                        basis={'50%'}
                                        hide={item.hideRating}
                                        isProvisional={item[`${ratingType}IsProvisional`]}
                                    />
                                </Stack>
                            )
                        })}
                    </Stack>
                </Grow>
            ) : null}
        </Stack>
    )
}

StatsContainer.displayName = 'StatsContainer'

export default StatsContainer
