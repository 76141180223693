import { Paper } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReconnectingWebSocket, {
    CloseEvent,
    ErrorEvent,
    Options,
    WS_ERROR_EVENT_STATUS,
} from '../../../socket/ReconnectingWebsocket'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { ConnectionStatus, MMChallengeType } from '../../../store/types'
import { GameViewState, StateChangeRequest } from '../../gameView/GameViewModel'
import { MM_ERRORS, MM_MESSAGES, MM_REQUESTS } from './MMTypes'

export const MatchMakingConnector = () => {
    const serverConnectionData = useStoreState((state) => state.serverConnectionData)
    const connectionStatus = useStoreState((state) => state.matchMaker.connectionStatus)
    const token = useStoreState((state) => state.token)
    const alreadyConnected = useStoreState((state) => state.matchMaker.alreadyConnected)
    const gameInQueue = useStoreState((state) => state.matchMaker.gameInQueue)

    const resetMatchMakerState = useStoreActions((state) => state.matchMaker.reset)
    const setSocket = useStoreActions((state) => state.matchMaker.setSocket)
    const setMatchData = useStoreActions((state) => state.gameView.setMatchData)
    const setAlreadyConnected = useStoreActions((state) => state.matchMaker.setAlreadyConnected)
    const setChallenges = useStoreActions((state) => state.matchMaker.setChallenges)
    const setRematchMatchData = useStoreActions((state) => state.matchMaker.setRematchMatchData)
    const setRematchStatus = useStoreActions((state) => state.matchMaker.setRematchStatus)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)
    const setOnlinePresence = useStoreActions((state) => state.matchMaker.setOnlinePresence)
    const setScoreboard = useStoreActions((state) => state.matchMaker.setScoreboard)
    const showGameOverDialog = useStoreActions((state) => state.matchMaker.showGameOverDialog)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const getScore = useStoreActions((state) => state.matchMaker.getScore)

    const addQueueTimeStart = useStoreActions((state) => state.addQueueTimeStart)
    const removeQueueTimeStart = useStoreActions((state) => state.removeQueueTimeStart)
    const clearQueueTimeStarts = useStoreActions((state) => state.clearQueueTimeStarts)
    const setGameToQueue = useStoreActions((state) => state.matchMaker.setGameToQueue)
    const setConnectionStatus = useStoreActions((state) => state.matchMaker.setConnectionStatus)
    const setBots = useStoreActions((state) => state.matchMaker.setBots)
    const shutdownGSConnection = useStoreActions((state) => state.gameView.shutDownGSConnection)
    const resetGame = useStoreActions((state) => state.gameView.resetGame)
    const setOpponentDisconnected = useStoreActions((state) => state.gameView.setOpponentDisconnected)
    const preloadGame = useStoreActions((state) => state.gameView.preloadGame)

    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setIsDrawerMenuOpen = useStoreActions((state) => state.setIsDrawerMenuOpen)
    const updateQueueTimeStart = useStoreActions((state) => state.updateQueueTimeStart)
    const navigate = useNavigate()

    useEffect(() => {
        if (!token || alreadyConnected) return

        const url = `${import.meta.env.VITE_REACT_APP_MM_WS_URL}/api/ws?token=${token}`
        const options: Options = {
            debug: true,
            debugName: 'MM',
            maxReconnectionDelay: 5000,
            minReconnectionDelay: 5000,
            maxRetries: 3,
        }
        const ws = new ReconnectingWebSocket(url, undefined, options)

        ws.addEventListener('close', (ce: CloseEvent) => {
            // console.log('matchmaker ws closed')
        })

        ws.addEventListener('open', () => {
            setConnectionStatus(ConnectionStatus.CONNECTED)
            setSocket(ws)
            ws.send(JSON.stringify({ requestType: MM_REQUESTS.ONLINE_USERS }))
            // console.log('matchmaker ws opened')
        })

        ws.addEventListener('reconnect', () => {
            setConnectionStatus(ConnectionStatus.CONNECTED)
            setSocket(ws)
            // console.log('matchmaker ws reconnected')
        })

        ws.addEventListener('error', (ev: ErrorEvent) => {
            Sentry.captureMessage(`Matchmaker error: ${ev.message}`)
            console.error('matchmaker ws error', ev)
            resetMatchMakerState()
            shutdownGSConnection()
            switch (ev.message) {
                case WS_ERROR_EVENT_STATUS.TIMEOUT:
                    setConnectionStatus(ConnectionStatus.CONNECTING)
                    break
                case WS_ERROR_EVENT_STATUS.MAX_RETRIES:
                    setConnectionStatus(ConnectionStatus.RECONNECT_FAILED)
                    break
                default:
                    break
            }
        })

        ws.addEventListener('message', (me) => {
            const mData = JSON.parse(me.data)
            console.log('mData', mData)

            if (mData.messageType === MM_MESSAGES.PLAYER_NOT_PLAYING) {
                showGameOverDialog()
            }

            if (
                mData.messageType === MM_MESSAGES.MATCH_CREATED ||
                mData.messageType === MM_MESSAGES.CHALLENGE_ACCEPTED ||
                mData.messageType === MM_MESSAGES.PLAYER_IS_PLAYING ||
                (mData.requestType === MM_REQUESTS.MY_STATUS && mData.data?.isPlaying === true)
            ) {
                console.log('mm relevant game start event reveived' + JSON.stringify(mData))
                shutdownGSConnection()

                if (window.location.pathname !== '/' && mData.messageType === MM_MESSAGES.PLAYER_IS_PLAYING) {
                    setGameToQueue(true)
                } else {
                    mData.messageType === MM_MESSAGES.CHALLENGE_ACCEPTED
                        ? setDrawerOpen('closed')
                        : setDrawerOpen('loading')

                    setGameToQueue(false)
                    navigate('/')

                    if (mData.messageType === MM_MESSAGES.CHALLENGE_ACCEPTED) {
                        const { matchKind, player1, player2 } = mData.data.result
                        preloadGame(mData.data.result)
                        if (matchKind === 'human_vs_human') {
                            getScore({
                                challengerId: player1.id,
                                challengeeId: player2.id,
                            })
                        }
                    }

                    setMatchData({
                        matchId: mData.data.result?.pairId || '',
                        gameServiceUrl: mData.data.metadata.gameServiceUrl,
                        messageType: mData.messageType,
                        result: mData.data.result,
                    })
                }
            }

            if (mData.requestType === MM_REQUESTS.MY_STATUS && mData.data?.isPlaying === false) {
                setSnackbarMessage('in progress game ended')
            }

            if (mData.requestType === MM_REQUESTS.CHALLENGE_REMATCH) {
                if (mData.result.status === 'success') {
                    setRematchStatus(StateChangeRequest.REQUESTED)
                    setSnackbarMessage('Rematch request sent')
                } else if (mData.result.status === 'error') {
                    setRematchStatus(StateChangeRequest.ERROR)
                }
            }

            if (mData.messageType === MM_MESSAGES.CHALLENGE_REJECTED) {
                if (mData.data.result?.isRematch) {
                    setRematchStatus(StateChangeRequest.REJECTED)
                    setSnackbarMessage('Rematch rejected')
                }
            }

            if (mData.messageType === MM_MESSAGES.CHALLENGE_REMATCH_REQUESTED) {
                setRematchMatchData(mData.data.result)
                setRematchStatus(StateChangeRequest.RECEIVED)
            }

            if (mData.messageType === MM_MESSAGES.CHALLENGE_FOUND) {
                updateQueueTimeStart({ challengeId: mData.data.result.challengeId, pairId: mData.data.result.pairId })
                ws.send(
                    JSON.stringify({
                        requestType: MM_REQUESTS.CHALLENGE_ACCEPT,
                        rawData: {
                            pairId: mData.data.result.pairId,
                        },
                    }),
                )
            }

            if (mData.messageType === MM_MESSAGES.ONLINE_USERS && mData.data?.amount) {
                setOnlinePresence(mData.data.amount)
            }

            if (mData.requestType === MM_REQUESTS.CHALLENGE_SCORE) {
                if (mData.result.status === 'success') {
                    setScoreboard(mData.data)
                }
            }

            if (
                mData.requestType === MM_REQUESTS.CHALLENGE_ACCEPT ||
                mData.requestType === MM_REQUESTS.CHALLENGE_CREATE ||
                mData.requestType === MM_REQUESTS.CHALLENGE_DELETE ||
                mData.requestType === MM_REQUESTS.CHALLENGE_REJECT
            ) {
                if (mData.data.result?.isRematch && mData.requestType === MM_REQUESTS.CHALLENGE_REJECT) {
                    setRematchStatus(StateChangeRequest.REJECTED)
                }
                if (mData.result.status === 'error') {
                    const action =
                        mData.requestType === MM_REQUESTS.CHALLENGE_ACCEPT ||
                        mData.requestType === MM_REQUESTS.CHALLENGE_CREATE
                            ? 'Start'
                            : 'Decline'
                    if (mData.data.isRematch) {
                        setRematchStatus(StateChangeRequest.ERROR)
                    }
                    if (mData.result.code === MM_ERRORS.PLAYER_IS_SUSPENDED) {
                        setSnackbarMessage(
                            `Your account has been suspended. Reach Customer Service for additional information.`,
                        )
                    } else if (mData.result.code === MM_ERRORS.OPPONENT_IS_SUSPENDED) {
                        setSnackbarMessage(`Opponent not available`)
                    } else if (mData.result.errorMessage === 'player is offline') {
                        setSnackbarMessage(`Unable to ${action} Match: User is Offline`)
                    } else if (mData.result.errorMessage === 'player is playing') {
                        setSnackbarMessage(`Unable to ${action} Match: User is Currently Playing`)
                    } else if (mData.result.errorMessage.includes('limit for direct challenges is reached')) {
                        setSnackbarMessage(`Limit: You Can Only Have 2 Direct Challenges Active`)
                    } else if (mData.result.errorMessage.includes('limit for custom challenges is reached')) {
                        setSnackbarMessage(`Limit: You Can Only Have 3 Custom Challenges Active`)
                    } else if (
                        mData.result.errorMessage.includes('not found') ||
                        mData.result.errorMessage.includes('not available')
                    ) {
                        setSnackbarMessage('Challenge not available')
                        const id = mData.data.pairId || mData.data.challengeId || mData.data.customId
                        const key =
                            mData.requestType === MM_REQUESTS.CHALLENGE_ACCEPT ? 'challengesToMe' : 'challengesByMe'
                        // @ts-ignore - this is a hack to get the challenge to hide in the UI
                        setChallenges({
                            [key]: [
                                {
                                    challengeId: id,
                                    pairId: id,
                                    challengeType: mData.data.pairId ? MMChallengeType.DIRECT : MMChallengeType.CUSTOM,
                                    recordState: 2, // DELETE
                                },
                            ],
                        })
                    } else {
                        // setSnackbarMessage(`Unable to ${action} Match`)
                        Sentry.captureMessage(
                            `Unable to ${action} Match, with request id: ${mData.requestId} and error: ${mData.result.errorMessage}`,
                        )
                    }
                }
            }

            if (mData.requestType === MM_REQUESTS.MY_CHALLENGES && mData.data) {
                mData.data.isFullRefresh = mData.requestId !== '00000000000000000000000000000000'
                setChallenges(mData.data)
            }

            if (mData.messageType === MM_MESSAGES.ALREADY_CONNECTED) {
                setAlreadyConnected(true)
                if (window.location.pathname === '/') {
                    if (gameInQueue) {
                        setDrawerOpen('open')
                        setIsDrawerMenuOpen(true)
                    }
                } else {
                    resetGame(GameViewState.PRE_GAME)
                    setOpponentDisconnected(undefined)
                }
            }

            if (mData.messageType === MM_MESSAGES.CHALLENGE_CREATED) {
                if (mData.data.challengeType === MMChallengeType.DIRECT) {
                    setSnackbarMessage(`Challenge sent, match will start once the user accepts`)
                }

                // list of open challenges for tracking time in queue
                const queueTimeStart = {
                    challengeId: mData.data.result.challengeId,
                    challengeType: mData.data.result.challengeType,
                    timeMode: `${mData.data.result.timeMode?.durationMinutes || 0}-${mData.data.result.timeMode?.clockIncrementSeconds || 0}`,
                    timestamp: new Date().getTime(),
                    rated: mData.data.result.rated,
                }

                addQueueTimeStart(queueTimeStart)
            }

            if (mData.messageType === MM_MESSAGES.CHALLENGE_DELETED) {
                removeQueueTimeStart(mData.data.challengeId)
            }

            if (mData.messageType === MM_MESSAGES.CHALLENGE_COMPLETED) {
                // reset game information here
            }

            if (mData.requestType === MM_REQUESTS.ONLINE_USERS && mData.data?.online_users) {
                setOnlinePresence(mData.data.online_users)
            }

            if (mData.messageType === MM_MESSAGES.REFRESH_PAGE) {
                window.location.reload()
            }

            if (mData.messageType === MM_MESSAGES.LOGOUT) {
                setOverlayTrigger('logout')
            }

            if (mData.requestType === MM_REQUESTS.BOTS_LIST) {
                if (mData.result.status === 'success') {
                    setBots(mData.data)
                }
            }
        })

        return () => {
            ws.close()
            resetMatchMakerState()
        }
    }, [
        alreadyConnected,
        setChallenges,
        setSocket,
        setMatchData,
        setSnackbarMessage,
        resetMatchMakerState,
        setAlreadyConnected,
        setRematchMatchData,
        setRematchStatus,
        token,
        addQueueTimeStart,
        removeQueueTimeStart,
        clearQueueTimeStarts,
        setConnectionStatus,
    ])

    return (
        <Paper
            className={'serverConnection'}
            style={{
                display: serverConnectionData ? 'block' : 'none',
            }}
        >
            MM: {connectionStatus} {import.meta.env.VITE_REACT_APP_MM_WS_URL} {import.meta.env.VITE_REACT_APP_ENV}{' '}
        </Paper>
    )
}
