import { alpha, Box, Slide, Typography, Zoom } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { createRootGameTree, getLastPosition } from '../../../chess/gameTree'
import { Color } from '../../../chess/types'
import { UserTitle } from '../../../components/userTitle/UserTitle'
import { imgFallback } from '../../../helpers/imgFallback'
import { FramedAvatar } from '../../../sharedComponents/src/globalHeader/components/FramedAvatar'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { PlayerCompressed } from '../../../store/types'

const startUpDelay = 2000

interface PlayerInfoProps {
    player: PlayerCompressed
    placement: 'top' | 'bottom'
    slider: {
        onExited: () => void
        in: boolean
        direction: 'down' | 'up'
        timeout: number
        container: HTMLElement | null
    }
}

const PlayerInfo: React.FC<PlayerInfoProps> = ({ player, placement, slider }) => {
    return (
        <Slide {...slider}>
            <Box
                sx={{
                    height: '50%',
                    width: '100%',
                    backgroundColor: alpha('#000000', 0.6),
                    display: 'flex',
                    justifyContent: placement === 'top' ? 'flex-start' : 'flex-end',
                    alignItems: placement === 'top' ? 'flex-end' : 'flex-start',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: placement === 'top' ? 'row' : 'row-reverse',
                        alignItems: 'center',
                        zIndex: 15,
                        gap: 1.5,
                        padding: 'calc(100% / 12)',
                    }}
                >
                    <FramedAvatar
                        avatar={player?.avatarUrl}
                        season={player?.reward?.season}
                        reward={player?.reward?.tier}
                        sx={{ width: 56, height: 56 }}
                    />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems={placement === 'top' ? 'flex-start' : 'flex-end'}
                        gap={0.5}
                    >
                        <Box display="flex" gap={1} flexDirection={placement === 'top' ? 'row' : 'row-reverse'}>
                            <img
                                height="24px"
                                src={`/flags/${player?.country?.toLocaleUpperCase()}@2x.png`}
                                onError={imgFallback}
                                alt={player?.country}
                            />
                            <UserTitle
                                sx={{
                                    height: '24px !important',
                                    minWidth: '34px !important',
                                    '& p': { fontSize: '18px' },
                                }}
                                title={player?.title}
                            />
                            {player.botdRewardUrl && (
                                <img
                                    src={player.botdRewardUrl}
                                    alt={'Bot of the day reward'}
                                    style={{
                                        height: '24px',
                                    }}
                                />
                            )}
                        </Box>
                        <Typography sx={{ lineHeight: '28px' }} textTransform="none" variant="h4">
                            {player?.username}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Slide>
    )
}

export const BoardOverlay = () => {
    const [readyToJoin, setReadyToJoin] = useState(false)
    const [delayedConnectionStatus, setDelayedConnectionStatus] = useState(false)
    const [initTime, setInitTime] = useState(Date.now())
    const containerRef = React.useRef<HTMLElement>(null)

    const user = useStoreState((state) => state.userData.userData)
    const opponentConnected = useStoreState((state) => state.gameView.opponentConnected)
    const playerInfo = useStoreState((state) => state.userData.userData)
    const data = useStoreState((state) => state.gameView.matchData)
    const bots = useStoreState((state) => state.matchMaker.bots)

    const setFlipBoard = useStoreActions((state) => state.gameView.setFlipBoard)
    const setMyColor = useStoreActions((state) => state.gameView.setMyColor)
    const setGameTree = useStoreActions((state) => state.gameView.setGameTree)
    const setCurrentPositionId = useStoreActions((state) => state.gameView.setCurrentPositionId)

    const result = data?.result

    const sendJoin = useStoreActions((state) => state.gameView.sendJoin)

    // Meh...
    useEffect(() => {
        if (result) {
            if (user) {
                const myColor = result.player1.id === user.id ? result.player1.color : result.player2.color
                const color = myColor === 'black' ? Color.Black : Color.White
                setMyColor(color)
                if (myColor === 'black') setFlipBoard(true)
            }

            if (result.startingFEN) {
                const newGameTree = createRootGameTree(result.startingFEN)
                setGameTree(newGameTree)
                const currentPositionId = getLastPosition(newGameTree).id
                setCurrentPositionId(currentPositionId)
            }

            const botOfTheDay = bots.find(
                (bot) => !!bot.isBotOfTheDay && (bot.id === result.player1?.id || bot.id === result.player2?.id),
            )
            // add Bot of the Day reward for bot
            if (result.player1?.id === botOfTheDay?.id) {
                result.player1.botdRewardUrl = botOfTheDay?.botdRewardURL
            } else if (result.player2?.id === botOfTheDay?.id) {
                result.player2.botdRewardUrl = botOfTheDay?.botdRewardURL
            }
        }
    }, [result])

    useEffect(() => {
        if (opponentConnected || data?.result?.matchKind === 'human_vs_ai') {
            const passedTime = Date.now() - initTime

            setTimeout(
                () => setDelayedConnectionStatus(true),
                passedTime > startUpDelay ? 0 : startUpDelay - passedTime,
            )
        }
    }, [opponentConnected, initTime, data])

    useEffect(() => {
        readyToJoin && sendJoin()
    }, [readyToJoin])

    const [player, opponent] = useMemo(
        () => [result?.player1, result?.player2].sort((a, b) => (a?.username === playerInfo?.user_name ? -1 : 1)),
        [result, playerInfo],
    )

    if (!result?.matchKind) return null

    return (
        <Box
            ref={containerRef}
            sx={{
                position: 'absolute',
                top: 5,
                left: 5,
                right: 5,
                bottom: 5,
                overflow: 'hidden',
            }}
        >
            <PlayerInfo
                slider={{
                    onExited: () => setReadyToJoin(true),
                    in: !delayedConnectionStatus,
                    container: containerRef.current,
                    direction: 'down',
                    timeout: startUpDelay / 2,
                }}
                player={opponent!}
                placement="top"
            />

            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 5,
                }}
            >
                <Zoom in={!delayedConnectionStatus} timeout={{ enter: startUpDelay / 2, exit: 0, appear: 1000 }}>
                    <Typography variant="h1">VS</Typography>
                </Zoom>
            </Box>

            <PlayerInfo
                slider={{
                    onExited: () => undefined,
                    in: !delayedConnectionStatus,
                    container: containerRef.current,
                    direction: 'up',
                    timeout: startUpDelay / 2,
                }}
                player={player!}
                placement="bottom"
            />
        </Box>
    )
}
