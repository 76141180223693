import ExpandMoreIcon from '@mui/icons-material/ExpandLess'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Color, GameTree } from '../../../../chess/types'
import { NotificationDialog } from '../../../../components/overlays/NotificationDialog/NotificationDialog'
import { saveUserSettings } from '../../../../sharedComponents/src/globalHeader/functions/ams-functions'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import useDescriptionText from '../../hooks/useDescriptionText'

interface IProps {
    description?: string
    myColor: Color
    dialog?: {
        open: boolean
        handleClose: () => void
    }
    puzzleGameTree: GameTree
}

export const Description: React.FC<IProps> = (props) => {
    const { myColor, description = '', dialog, puzzleGameTree } = props

    const userData = useStoreState((state) => state.userData)
    const settings = useStoreState((state) => state.gameView.settings)

    const setSettings = useStoreActions((state) => state.gameView.setSettings)
    const setUserData = useStoreActions((state) => state.setUserData)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)

    const [updatedDescription, setUpdatedDescription] = useState<string>('')
    const token = useStoreState((state) => state.token)

    useEffect(() => {
        const positions = puzzleGameTree.positions.filter((position) => position.position.turn === myColor)

        const result = useDescriptionText(description, {
            opponentColor: myColor === Color.Black ? 'White' : 'Black',
            myColor: myColor === Color.Black ? 'Black' : 'White',
            movesCount: positions.length,
        })
        setUpdatedDescription(result)
    }, [puzzleGameTree, description])

    const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (userData.userData && token) {
            const updatedSettings = { ...settings, briefToggle: !settings.briefToggle }
            setUserData({ ...userData, userData: { ...userData.userData, game_settings: updatedSettings } })
            setSettings(updatedSettings)
            // setOverlayTrigger({ name: 'updateSettings', data: updatedSettings })
            await saveUserSettings(updatedSettings)
        }
    }

    return dialog ? (
        <NotificationDialog
            handleClose={dialog.handleClose}
            open={dialog.open}
            header="description"
            description={updatedDescription}
        />
    ) : (
        <Box
            sx={{
                '.Mui-expanded, .MuiButtonBase-root, .MuiAccordionSummary-content': {
                    margin: '0 !important',
                    minHeight: '0 !important',
                },
            }}
        >
            <Divider />
            <Accordion defaultExpanded={settings.briefToggle} onChange={handleSwitchChange as any}>
                <AccordionSummary color="secondary" expandIcon={<ExpandMoreIcon color="secondary" />}>
                    <Typography
                        my="6px"
                        width="calc(100% - 24px)"
                        ml="24px"
                        textAlign="center"
                        variant="h5"
                        color="secondary"
                    >
                        Description
                    </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ overflow: 'auto', padding: '0 16px 16px' }}>
                    <Typography textAlign="justify" variant="body2">
                        {updatedDescription}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
