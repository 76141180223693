import { FetchPopupsRequest } from './generated/popup-service/fetch_popups_request'
import { FetchPopupsResponse } from './generated/popup-service/fetch_popups_response'
import { MessageType } from './generated/websocket-gateway/message_type'

type ByteArray = Uint8Array
type RequestType = MessageType

// ENCODE REQUESTS
export const encoders = {
    [MessageType.POPUP_ACTION]: FetchPopupsRequest,
}

// DECODE RESPONSES
export const decoders = {
    [MessageType.POPUP_ACTION]: FetchPopupsResponse,
}

export type Decoders = typeof decoders
export type Encoders = typeof encoders

export type MessageTypeToResponse = {
    [K in keyof Decoders]: ReturnType<Decoders[K]['decode']>
}

export type MessageTypeToPayload = {
    [K in keyof Encoders]: {
        messageType: K
        message: Parameters<Encoders[K]['encode']>[0]
    }
}
export type SendMessageArgs = MessageTypeToPayload[keyof Encoders]

export const encode = <T>(msgType: RequestType, message: T): ByteArray => {
    // Get message type bytes
    const messageTypeBytes = new TextEncoder().encode(msgType)
    // Get message bytes
    const messageBytes = encoders[msgType].encode(message).finish()

    // Create full message
    const fullMessage = new Uint8Array(1 + messageTypeBytes.length + messageBytes.length)
    // Fill full message
    fullMessage.set([messageTypeBytes.length], 0)
    fullMessage.set(messageTypeBytes, 1)
    fullMessage.set(messageBytes, 1 + messageTypeBytes.length)
    return fullMessage
}

export const decode = <R extends keyof Decoders>(
    d: Uint8Array,
): { messageType: R; message: MessageTypeToResponse[R] } => {
    const data = new Uint8Array(d)
    const messageTypeBytesLength = data[0]
    const messageTypeBytes = data.slice(1, 1 + messageTypeBytesLength)
    const messageBytes = data.slice(1 + messageTypeBytesLength)

    const messageType = new TextDecoder().decode(messageTypeBytes) as R
    const message = decoders[messageType].decode(messageBytes) as MessageTypeToResponse[R]
    return { messageType, message }
}
