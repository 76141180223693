import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { RatingCounter } from '../../../../../../components/Rating/RatingCounter'
import { FramedAvatar } from '../../../../../../sharedComponents/src/globalHeader/components/FramedAvatar'
import { useStoreState } from '../../../../../../store/hooks'
import { Bot, PlayerInGameData, TimeMode } from '../../../../../../store/types'
import { GameOverColumnContent } from '../GameOverColumnContent'
import { RatingChange } from '../RatingChange/RatingChange'

const PlayerAvatar: React.FC<{ playerInfo: PlayerInGameData }> = (props) => {
    const { playerInfo } = props
    const { avatarUrl, playerKind, reward, playerId } = playerInfo

    const bots = useStoreState((state) => state.matchMaker.bots)
    const [bot, setBot] = useState<Bot>()

    useEffect(() => {
        if (playerKind === 'machine') {
            const bot = bots.find((bot) => bot.id === playerId)
            if (bot) {
                setBot(bot)
            }
        }
    }, [])

    return (
        <Stack sx={{ position: bot?.isBotOfTheDay ? 'relative' : 'unset', margin: '0 auto 0.75rem' }}>
            <FramedAvatar
                avatar={avatarUrl}
                season={reward?.season}
                reward={reward?.tier}
                sx={{
                    maxWidth: '140px',
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '1',
                }}
            />
            {bot?.isBotOfTheDay && (
                <Typography
                    variant="h5"
                    sx={(theme) => ({
                        zIndex: 1,
                        fontSize: '16px !important',
                        position: 'absolute',
                        bottom: '0',
                        maxWidth: '132px',
                        width: '100%',
                        textAlign: 'center',
                        backgroundColor: 'primary.main',
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                    })}
                >
                    featured bot
                </Typography>
            )}
        </Stack>
    )
}

type Props = {
    playerInfo: PlayerInGameData
    winner: boolean
    rated: boolean
    oldRating: number
    newRating: number
    timeMode: TimeMode
}

export const PlayerColumn = ({ playerInfo, winner, rated, oldRating, newRating, timeMode }: Props) => {
    const { userName, ratings } = playerInfo
    const settings = useStoreState((state) => state.gameView.settings)

    return (
        <GameOverColumnContent winner={winner}>
            <PlayerAvatar playerInfo={playerInfo} />
            <Typography
                variant="body2"
                sx={{ pt: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {userName}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 2,
                    pb: 1,
                }}
            >
                {!settings.friendlyMode &&
                    (rated ? (
                        <div
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px 0' }}
                        >
                            <RatingCounter
                                oldRating={oldRating}
                                newRating={newRating}
                                type={timeMode.name}
                                isProvisional={ratings[timeMode.name].is_provisional}
                            />
                            <RatingChange change={newRating > 0 ? newRating - oldRating : 0} />
                        </div>
                    ) : (
                        <Typography
                            color="secondary"
                            fontFamily="Space Grotesk"
                            style={{ fontSize: '24px', textTransform: 'capitalize' }}
                        >
                            Unrated
                        </Typography>
                    ))}
            </Box>
        </GameOverColumnContent>
    )
}
