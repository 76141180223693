import { Action, action, Thunk, thunk } from 'easy-peasy'
import { WS_GATEWAY_URL } from '../env'
import store, { StoreModel } from '../store/store'
import { decode, Decoders, encode, SendMessageArgs } from './grpcHelpers'

export type MessageTypeToCallbacks = {
    [K in keyof Decoders]: {
        messageType: K
        callback: (message: ReturnType<Decoders[K]['decode']>) => void
    }
}
export type Callback = MessageTypeToCallbacks[keyof Decoders]

// Define the WebSocket model interface
export type WebSocketModel = {
    // State
    socket: WebSocket | null
    messages: string[]
    isConnected: boolean
    reconnectInterval: NodeJS.Timeout | undefined
    listeners: {
        [K in keyof Decoders]?: MessageTypeToCallbacks[K]['callback']
    }

    // Actions
    setSocket: Action<WebSocketModel, WebSocket | null>
    setConnected: Action<WebSocketModel, boolean>
    setReconnectInterval: Action<WebSocketModel, NodeJS.Timeout | undefined>
    addListener: Action<WebSocketModel, Callback>

    // Thunks
    connect: Thunk<WebSocketModel, undefined, undefined, StoreModel>
    sendMessage: Thunk<WebSocketModel, SendMessageArgs, {}, WebSocketModel>
    disconnect: Thunk<WebSocketModel>
}

// WebSocket model implementation
export const WebsocketModel: WebSocketModel = {
    // State
    socket: null,
    messages: [],
    isConnected: false,
    reconnectInterval: undefined,

    listeners: {},

    // Actions
    setSocket: action((state, socket) => {
        state.socket = socket
    }),

    setConnected: action((state, isConnected) => {
        state.isConnected = isConnected
    }),

    setReconnectInterval: action((state, interval) => {
        state.reconnectInterval = interval
    }),

    addListener: action((state, { messageType, callback }) => {
        state.listeners[messageType] = callback as any
    }),

    // Thunks
    connect: thunk((actions, _, { getState, getStoreState }) => {
        let healthCheckInterval: NodeJS.Timeout

        const token = getStoreState().token

        if (!token) {
            actions.setConnected(false)
            actions.setSocket(null)
            return
        }

        const socket = new WebSocket(`${WS_GATEWAY_URL}/api/ws?token=${getStoreState().token || ''}`)
        socket.binaryType = 'arraybuffer'

        socket.onopen = () => {
            console.log('WebSocket gateway connected', socket.readyState)
            actions.setConnected(true)
            clearInterval(getState().reconnectInterval)

            healthCheckInterval = setInterval(() => {
                socket.send(new Uint8Array())
            }, 5000)

            // Establish listeners
            store.getActions().popupService.establishListener()
        }

        socket.onmessage = (event) => {
            const { messageType, message } = decode(event.data)

            const listener = getState().listeners[messageType]
            if (listener) {
                listener(message as any)
            } else {
                console.error(`No listener for message type: ${messageType}`)
            }
        }

        socket.onclose = () => {
            actions.setConnected(false)
            actions.setSocket(null)

            clearInterval(getState().reconnectInterval)
            clearInterval(healthCheckInterval)

            const recInt = setInterval(() => {
                actions.connect()
            }, 5000)
            actions.setReconnectInterval(recInt)

            console.log('WebSocket gateway disconnected')
        }

        socket.onerror = (error) => {
            console.error('WebSocket gateway error:', error)
        }

        actions.setSocket(socket)
    }),

    sendMessage: thunk((actions, { messageType, message }, { getState }) => {
        const { socket, isConnected } = getState()
        if (socket && isConnected && socket.readyState === WebSocket.OPEN) {
            const encodedMessage = encode(messageType, message)
            socket.send(encodedMessage)
        } else {
            console.error('Cannot send message: WebSocket gateway is not connected')
        }
    }),

    disconnect: thunk((actions, _, { getState }) => {
        const { socket } = getState()
        if (socket) {
            socket.close()
            actions.setSocket(null)
        }
    }),
}
