import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { Box } from '@mui/material'
import { useStoreState } from '../../../store/hooks'

export const DailyResult = () => {
    const dayProgress = useStoreState((state) => state.puzzleQuestView.dayProgress)
    const { reward } = dayProgress
    const rewards = {
        first: reward >= 1 ? '#FFB01A' : '#252636',
        second: reward >= 2 ? '#FFB01A' : '#252636',
        third: reward >= 3 ? '#FFB01A' : '#252636',
    }

    return (
        <Box
            sx={{
                position: 'relative',
                flexGrow: 1,
                '@keyframes appearLight': {
                    '0%': {
                        opacity: 0.01,
                    },
                    '60%': {
                        opacity: 0.25 * reward + 0.125,
                    },
                    '100%': {
                        opacity: 0.25 * reward,
                    },
                },
                '@keyframes appearCrowns': {
                    '0%': {
                        transform: 'scale(0)',
                        opacity: 1,
                    },
                    '75%': {
                        transform: 'scale(1)',
                        opacity: 1,
                    },
                    '100%': {
                        transform: 'scale(0.8)',
                        opacity: 1,
                    },
                },
                '> div': {
                    position: 'absolute',
                    zIndex: 0,
                    top: '-12.5%',
                    left: '-12.5%',
                    height: '125%',
                    width: '125%',
                    animation: 'appearLight 1s ease-out forwards',
                    animationDelay: '0.2s',
                    opacity: 0.01,
                },
                svg: {
                    animation: 'appearCrowns 1s ease-in-out forwards',
                    animationDelay: '0.2s',
                    opacity: 0,
                    filter: 'drop-shadow(8px 8px 3px rgba(0, 0, 0, 0.5))',
                },
            }}
        >
            <Box>
                <DotLottieReact
                    speed={0.3}
                    src="/assets/animations/puzzles/reward.lottie"
                    loop={true}
                    autoplay
                    layout={{ fit: 'fill', align: [0, 0] }}
                    // renderConfig={{
                    //     autoResize: true,
                    // }}
                />
            </Box>

            <svg
                style={{
                    zIndex: 100,
                    position: 'relative',
                }}
                width="100%"
                height="100%"
                viewBox="0 0 208 102"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.8961 101.5C14.6714 89.4654 24.4964 86.3723 24.2451 85.1931C24.1405 84.7225 23.9904 84.2425 23.7258 83.8581C22.8604 82.5184 7.69053 61.4422 6.83487 60.219C4.02428 60.2242 1.46436 58.1895 1.0029 55.1807C0.595292 52.4934 2.12715 49.8067 4.64474 48.8286C7.96956 47.5259 11.5854 49.4402 12.4877 52.8079C12.7262 53.6978 12.7427 54.6039 12.5813 55.4492L22.8968 61.3781L17.7103 42.0218C15.0447 41.8039 12.7121 39.7733 12.3307 36.8623C11.9896 34.2222 13.513 31.6245 15.9776 30.6498C19.2997 29.3369 22.9357 31.2459 23.838 34.6135C24.2797 36.2619 23.9744 37.9371 23.1377 39.2777L33.2443 54.5192L34.376 36.2664C32.9812 35.5237 31.8792 34.2257 31.4375 32.5772C30.5351 29.2096 32.7194 25.7411 36.2629 25.2144C38.8847 24.8262 41.5001 26.3041 42.5275 28.7711C43.6554 31.493 42.6506 34.4177 40.4484 35.9292L45.6349 55.2855L51.6039 44.9931C51.0388 44.3316 50.6028 43.5488 50.3643 42.6589C49.462 39.2912 51.6389 35.8356 55.1669 35.2913C57.839 34.8896 60.5062 36.4403 61.4969 38.9714C62.6017 41.8078 61.4021 44.8499 58.9654 46.2507C58.836 47.7378 57.627 73.2027 57.5374 74.7983C57.5105 75.2608 57.6077 75.7442 57.7551 76.2141C58.1271 77.361 68.3186 74.549 71.6787 87.089"
                    fill={rewards.second}
                />
                <path
                    d="M137.976 87.089C141.201 75.0544 151.256 77.2882 151.628 76.1413C151.773 75.6815 151.883 75.1907 151.846 74.7256C151.766 73.1326 149.167 47.2951 149.038 45.808C146.601 44.4072 145.401 41.3652 146.506 38.5287C147.497 35.9977 150.167 34.4369 152.836 34.8486C156.367 35.3828 158.541 38.8486 157.639 42.2162C157.4 43.1061 156.961 43.8991 156.399 44.5505L162.368 54.8428L167.555 35.4865C165.355 33.965 164.35 31.0402 165.475 28.3284C166.5 25.8715 169.118 24.3835 171.74 24.7717C175.273 25.2958 177.468 28.7669 176.565 32.1346C176.124 33.783 175.022 35.0811 173.627 35.8237L174.759 54.0765L184.865 38.835C184.028 37.4944 183.723 35.8193 184.165 34.1708C185.067 30.8032 188.693 28.8916 192.025 30.2071C194.49 31.1819 196.016 33.7694 195.672 36.4196C195.288 39.3408 192.956 41.3714 190.293 41.5791L185.106 60.9355L195.422 55.0065C195.263 54.1511 195.277 53.2551 195.515 52.3652C196.418 48.9976 200.031 47.0933 203.358 48.386C205.873 49.3742 207.408 52.0507 207 54.738C206.539 57.7469 203.979 59.7815 201.168 59.7763C200.312 60.9995 186.533 82.4482 185.658 83.7853C185.403 84.1724 185.245 84.6396 185.138 85.1203C184.887 86.2995 195.119 88.96 191.759 101.5"
                    fill={rewards.third}
                />
                <path
                    d="M72.5734 79.7014C72.5734 65.3043 84.465 64.7902 84.5371 63.3989C84.5612 62.8424 84.5371 62.2616 84.3567 61.7535C83.7915 59.9992 73.1627 31.9374 72.5734 30.3162C69.4347 29.4814 67.1859 26.4447 67.5707 22.9483C67.9195 19.8269 70.4329 17.2862 73.5355 16.9475C77.6362 16.4877 81.0995 19.7059 81.0995 23.7347C81.0995 24.7993 80.847 25.8156 80.4141 26.7109L90.1548 36.4138V13.2575C87.2446 12.217 85.2484 9.25291 85.6933 5.88956C86.1022 2.84076 88.5795 0.396886 91.6219 0.0460321C95.7227 -0.425805 99.2101 2.79237 99.2101 6.82113C99.2101 8.79317 98.3683 10.5716 97.0335 11.8178L103.756 31.8527L110.478 11.8178C109.143 10.5716 108.301 8.79317 108.301 6.82113C108.301 2.79237 111.777 -0.425805 115.89 0.0460321C118.932 0.396886 121.409 2.82866 121.818 5.88956C122.263 9.26501 120.267 12.2291 117.357 13.2575V36.4138L127.097 26.7109C126.665 25.8035 126.412 24.7993 126.412 23.7347C126.412 19.7059 129.875 16.4998 133.976 16.9475C137.079 17.2983 139.592 19.8269 139.941 22.9483C140.326 26.4447 138.077 29.4814 134.938 30.3162C134.349 31.9374 125.383 59.9992 124.806 61.7535C124.638 62.2616 124.602 62.8303 124.626 63.3989C124.698 64.7902 136.915 64.6996 136.915 79.7014"
                    fill={rewards.first}
                />
            </svg>
        </Box>
    )
}
