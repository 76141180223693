import axios from 'axios'
import isEmpty from 'lodash-es/isEmpty'

export const getPopupApiUrl = (): string => {
    // ts-ignore needed for next.js app
    // @ts-ignore
    if (!isEmpty(import.meta) && import.meta.env.VITE_REACT_APP_POPUP_URL) {
        // @ts-ignore
        return import.meta.env.VITE_REACT_APP_POPUP_URL
    } else if (process.env.NEXT_PUBLIC_POPUP_URL) {
        return process.env.NEXT_PUBLIC_POPUP_URL
    } else {
        throw new Error('API URL is not defined')
    }
}

export const popupAPI = axios.create({
    baseURL: getPopupApiUrl(),
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
})

// Add a request interceptor
popupAPI.interceptors.request.use(
    async (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

// Add a response interceptor
popupAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                // TODO: need to implement refresh token logic here
                // originalRequest.headers.Authorization = `Bearer ${token}`
                // return axios(originalRequest)
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }

        return Promise.reject(error)
    },
)
