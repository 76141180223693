import { FC } from 'react'
import { useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'

const fontStyles = {
    color: '#FFF',
    fill: '#FFF',
    fontFamily: 'Fira Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    textAnchor: 'middle',
}

export interface StripeProps {
    title: string
    placeholder?: boolean
}

const getStripe = (title, variant, placeholder) => {
    if (variant === 'desktop') {
        if (placeholder) {
            return (
                <svg
                    width="100%"
                    viewBox="0 0 476 72"
                    height="66px"
                    fill="none"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                ></svg>
            )
        }
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="72px">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    viewBox="0 0 476 72"
                    height="74px"
                    preserveAspectRatio="none"
                    fill="none"
                >
                    <path
                        d="M26.4054 13.7765C87.4188 6.11258 155.078 1.0115 237.756 1.00002C320.625 0.988516 388.581 6.11258 449.595 13.7765L446.002 25.5277C451.267 25.698 464.238 27.367 474 32.6807L461.797 49.5412L466.678 71H421.284V64.8689H237.756H54.7156V71H9.32161L14.2027 49.5412L2 32.6807C11.7622 27.367 24.7328 25.698 29.9978 25.5277L26.4054 13.7765Z"
                        fill="#131D26"
                    />
                </svg>
                <text x="50%" y="69%" {...fontStyles} fontSize={38}>
                    {title}
                </text>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    viewBox="0 0 476 74"
                    height="74px"
                    preserveAspectRatio="none"
                    fill="none"
                >
                    <path
                        d="M42.0248 64.8689L54.7156 71M42.0248 64.8689H54.7156M42.0248 64.8689L29.9978 25.5277M54.7156 71V64.8689M54.7156 71H9.32161L14.2027 49.5412L2 32.6807C11.7622 27.367 24.7328 25.698 29.9978 25.5277M54.7156 64.8689H237.756H421.284M29.9978 25.5277L26.4054 13.7765C87.4188 6.11258 155.078 1.0115 237.756 1.00002C320.625 0.988516 388.581 6.11258 449.595 13.7765L446.002 25.5277M433.975 64.8689L421.284 71M433.975 64.8689H421.284M433.975 64.8689L446.002 25.5277M421.284 71V64.8689M421.284 71H466.678L461.797 49.5412L474 32.6807C464.238 27.367 451.267 25.698 446.002 25.5277"
                        stroke="white"
                        strokeWidth="2"
                    />
                </svg>
            </svg>
        )
    } else {
        if (placeholder) {
            return (
                <svg
                    width="100%"
                    viewBox="0 0 320 44"
                    height="44px"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                ></svg>
            )
        }
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" overflow="hidden" height="44px">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    viewBox="0 0 320 44"
                    height="44px"
                    preserveAspectRatio="none"
                    fill="none"
                >
                    <path
                        d="M18.3392 8.66589C59.1872 4.06755 104.484 1.0069 159.837 1.00001C215.316 0.99311 260.813 4.06755 301.661 8.66589L299.256 15.7166C302.781 15.8188 311.464 16.8202 318 20.0084L309.83 30.1247L313.098 43H282.707V39.3213H159.837H37.2927V43H6.90176L10.1696 30.1247L2 20.0084C8.53568 16.8202 17.2194 15.8188 20.7443 15.7166L18.3392 8.66589Z"
                        fill="#131D26"
                    />
                </svg>
                <text x="50%" y="70%" {...fontStyles} fontSize={28}>
                    {title}
                </text>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    viewBox="0 0 320 44"
                    height="44px"
                    preserveAspectRatio="none"
                    fill="none"
                >
                    <path
                        d="M28.7963 39.3213L37.2927 43M28.7963 39.3213H37.2927M28.7963 39.3213L20.7443 15.7166M37.2927 43V39.3213M37.2927 43H6.90176L10.1696 30.1247L2 20.0084C8.53568 16.8202 17.2194 15.8188 20.7443 15.7166M37.2927 39.3213H159.837H282.707M20.7443 15.7166L18.3392 8.66589C59.1872 4.06755 104.484 1.0069 159.837 1.00001C215.316 0.99311 260.813 4.06755 301.661 8.66589L299.256 15.7166M291.204 39.3213L282.707 43M291.204 39.3213H282.707M291.204 39.3213L299.256 15.7166M282.707 43V39.3213M282.707 43H313.098L309.83 30.1247L318 20.0084C311.464 16.8202 302.781 15.8188 299.256 15.7166"
                        stroke="white"
                        strokeWidth="2"
                    />
                </svg>
            </svg>
        )
    }
}

export const Stripe: FC<StripeProps> = ({ title, placeholder }) => {
    const sizings = useResponsiveSizings()

    if (placeholder) return getStripe(title, sizings.layout, placeholder)
    return getStripe(title, sizings.layout, placeholder)
}
