import BackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material'
import { FC } from 'react'

interface DialogScreen {
    header: {
        top: string
        middle?: string
        bottom?: string
    }
    handleCloseOrPrev?: () => void
    dialogWidth?: string
    isPrevActive?: boolean
}

interface DialogTemplate extends DialogProps, DialogScreen {}

export const DialogTemplate: FC<DialogTemplate> = (
    { children, handleCloseOrPrev, isPrevActive, dialogWidth, open, header: { top, middle, bottom }, sx },
    props,
) => {
    return (
        <Dialog sx={{ zIndex: '1302' }} {...props} open={open} onClose={handleCloseOrPrev}>
            <DialogContent
                sx={{
                    ...sx,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: dialogWidth ? dialogWidth : 'unset',
                    padding: { xs: ' 1rem', md: '2rem' },
                    gap: { xs: 1, md: 2 },
                    border: '4px solid white',
                }}
            >
                {handleCloseOrPrev && (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: { xs: '8px', md: '20px' },
                            top: { xs: '8px', md: '20px' },
                        }}
                        onClick={handleCloseOrPrev}
                    >
                        {isPrevActive ? <BackIcon fontSize="large" /> : <CloseIcon fontSize="large" />}
                    </IconButton>
                )}
                <Stack sx={{ textAlign: 'center' }}>
                    <Typography variant="h1" fontSize="3.5rem">
                        {top}
                    </Typography>
                    {middle && <Typography variant="h6">{middle}</Typography>}
                    {bottom && <Typography variant="h2">{bottom}</Typography>}
                </Stack>
                {children}
            </DialogContent>
        </Dialog>
    )
}
