// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.2
// source: websocket-gateway/message_type.proto

/* eslint-disable */

export const protobufPackage = "websocket_gateway";

export enum MessageType {
  UNSPECIFIED = "MESSAGE_TYPE_UNSPECIFIED",
  POPUP_ACTION = "MESSAGE_TYPE_POPUP_ACTION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function messageTypeFromJSON(object: any): MessageType {
  switch (object) {
    case 0:
    case "MESSAGE_TYPE_UNSPECIFIED":
      return MessageType.UNSPECIFIED;
    case 1:
    case "MESSAGE_TYPE_POPUP_ACTION":
      return MessageType.POPUP_ACTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MessageType.UNRECOGNIZED;
  }
}

export function messageTypeToNumber(object: MessageType): number {
  switch (object) {
    case MessageType.UNSPECIFIED:
      return 0;
    case MessageType.POPUP_ACTION:
      return 1;
    case MessageType.UNRECOGNIZED:
    default:
      return -1;
  }
}
