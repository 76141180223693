import { Box, Stack, Typography } from '@mui/material'
import { Color } from '../../../../../../chess/types'
import { Layout, useResponsiveSizings } from '../../../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { GameResultType } from '../../../../../../store/types'
import { dialogData } from '../../utils'

type Props = {
    myColor?: Color
    winner?: Color
    resultType: GameResultType
    layout: Layout
    TimeControlIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    timeMode: {
        name: string
        durationMinutes: number
        clockIncrementSeconds: number
    }
    rewardURL?: string
}

export const DialogHeader = ({ myColor, winner, resultType, layout, TimeControlIcon, timeMode, rewardURL }: Props) => {
    const {
        resolution: { isMobile },
    } = useResponsiveSizings()
    const dataKey = resultType + (winner ? winner : '')
    const dd: any = !!dialogData[dataKey] ? dialogData[dataKey] : dialogData.unknown

    const gameResultTitle = myColor ? (myColor === winner ? dd.winnerMessage : dd.otherMessage) : dd.neutralMessage

    const gameResultDescription = (myColor === undefined && dd.neutralDescription) || dd.description

    return (
        <Box>
            <Typography variant="h2" textAlign="center">
                {gameResultTitle}
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'lowercase', textAlign: 'center' }}>
                {gameResultDescription}
            </Typography>
            {myColor === winner && rewardURL && (
                <Stack gap={1} direction="row" justifyContent="center" alignItems="center" my={2}>
                    <Typography variant="body1" color="secondary" sx={{ textTransform: 'lowercase' }}>
                        Reward claimed
                    </Typography>
                    <img
                        src={rewardURL}
                        alt="Featured bot reward"
                        style={{
                            height: '32px',
                        }}
                    />
                </Stack>
            )}
            {isMobile && (
                <Box display="flex" justifyContent="center" mt="8px" mb={-1}>
                    <TimeControlIcon style={{ width: '20px', height: '20px', fill: '#FFC58C', marginRight: '4px' }} />
                    <Typography variant="body2" color="secondary" textTransform="capitalize">
                        {timeMode.name} {timeMode.durationMinutes}+{timeMode.clockIncrementSeconds}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
