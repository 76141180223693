import { nameOfSquare, squareByName } from '../../chess/basics'
import { generateMoves, isInCheck, positionFindFirstPiece } from '../../chess/core'
import { getPositionById } from '../../chess/gameTree'
import { Color, GameTree, Move, Piece, Position } from '../../chess/types'
import { Square } from '../../react-chessboard/src/chessboard/types'

export const opponentColor = {
    [Color.White]: 'b',
    [Color.Black]: 'w',
}

export const ownColor = {
    [Color.White]: 'w',
    [Color.Black]: 'b',
}

export const isColorInCheck = (color: Color, gameTree?: GameTree, currentPositionId?: string) => {
    if (!gameTree || !currentPositionId) return false
    try {
        const position = getPositionById(gameTree, currentPositionId).position
        return position.turn === color && isInCheck(position)
    } catch {
        return false
    }
}

const squareHighlightStyles = {
    background: 'rgba(255, 255, 0, 0.4)',
    transition: 'background-color 250ms ease-in-out',
}

const checkHighlightStyles = {
    background: 'rgba(255, 0, 0, 0.4)',
    transition: 'background-color 250ms ease-in-out',
}

const rightClickedSquareStyles = {
    outline: '0.5rem solid rgba(0, 0, 255, 0.4)',
    outlineOffset: '-0.5rem',
    transition: 'outline-color 250ms ease-in-out',
    borderRadius: '50%',
}

const legalMoveStyles = {
    background: 'radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)',
}

const legalMoveStylesWithPiece = {
    background:
        'radial-gradient(circle, transparent 60%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0.1) 70%, transparent 70%)',
}

const legalPremoveStyles = {
    background: 'radial-gradient(circle, rgba(255, 72, 0, 0.4) 25%, transparent 25%)',
}

const legalPremoveStylesWithPiece = {
    background:
        'radial-gradient(circle, transparent 60%, rgba(255, 72, 0, 0.4) 60%, rgba(255, 72, 0, 0.4) 70%, transparent 70%)',
}

const premoveHighlightStyles = { background: '#dd7777' }

const hintHighlightStyles = {
    background: '#DCFB1950',
    boxShadow: 'inset 0rem 0rem .25rem .25rem #DCFB19',
    transition: 'background-color 250ms ease-in-out',
}

const correctMoveHighlightStyles = {
    background: '#00FF47',
    transition: 'background-color 250ms ease-in-out',
}

const incorrectMoveHighlightStyles = {
    background: '#EC1C24',
    transition: 'background-color 250ms ease-in-out',
}

export const getLastMoveHighlightsStyles = (
    lastMove: Move | undefined,
    isHint?: boolean,
    isMyTurn?: boolean,
    status?: boolean,
) => {
    if (!lastMove) return {}

    return {
        [nameOfSquare(lastMove.from)]: isHint ? hintHighlightStyles : squareHighlightStyles,
        [nameOfSquare(lastMove.to)]: isMyTurn
            ? status === false
                ? incorrectMoveHighlightStyles
                : correctMoveHighlightStyles
            : squareHighlightStyles,
    }
}

export const getCheckHighlightStyles = (position: Position) => {
    if (!isInCheck(position)) return {}

    const kingSquare = positionFindFirstPiece(
        position,
        position.turn === Color.White ? Piece.WhiteKing : Piece.BlackKing,
    )

    if (!kingSquare) return {}

    return { [nameOfSquare(kingSquare)]: checkHighlightStyles }
}

export const getRightClickedSquareStyles = (rightClickedSquares: Square[]) => {
    return rightClickedSquares.reduce((acc, square) => {
        return { ...acc, [square]: rightClickedSquareStyles }
    }, {})
}

export const getSelectedSquareStyles = (
    showLegalMoves: boolean,
    position: Position,
    selectedSquare: Square | undefined,
    legalPremoves: Move[],
    myColor: Color,
    isHint?: boolean,
) => {
    if (!selectedSquare) return {}
    const highlightedSquare = {
        [selectedSquare]: isHint ? hintHighlightStyles : squareHighlightStyles,
    }
    if (position.turn === myColor) {
        let legalMoves = generateMoves(position, squareByName(selectedSquare))
        if (!showLegalMoves) {
            return highlightedSquare
        }
        if (legalMoves.length > 0) {
            const styles = legalMoves.reduce((acc, move) => {
                return {
                    ...acc,
                    [nameOfSquare(move.to)]:
                        position.board[move.to] !== null ? legalMoveStylesWithPiece : legalMoveStyles,
                }
            }, {})

            return { ...styles, ...highlightedSquare }
        }
    } else if (legalPremoves.length > 0) {
        if (!showLegalMoves) {
            return highlightedSquare
        }
        const styles = legalPremoves.reduce((acc, move) => {
            return {
                ...acc,
                [nameOfSquare(move.to)]:
                    position.board[move.to] !== null ? legalPremoveStylesWithPiece : legalPremoveStyles,
            }
        }, {})

        return { ...styles, ...highlightedSquare }
    }

    return {}
}

export const getPremoveStyles = (premove: Move | undefined) => {
    if (!premove) return {}

    return {
        [nameOfSquare(premove.from)]: premoveHighlightStyles,
        [nameOfSquare(premove.to)]: premoveHighlightStyles,
    }
}

export const getHintStyles = (hint: Square | undefined) => {
    if (!hint) return {}

    return {
        [hint]: hintHighlightStyles,
    }
}

export const combineStyles = (...styles: { [key: string]: React.CSSProperties }[]) => {
    const acc = {}

    styles.forEach((style) => {
        Object.keys(style).forEach((key) => {
            acc[key] = { ...acc[key], ...style[key] }
        })
    })

    return acc
}

export const libPieceToEnumPieceMap = {
    wP: Piece.WhitePawn,
    wR: Piece.WhiteRook,
    wN: Piece.WhiteKnight,
    wB: Piece.WhiteBishop,
    wQ: Piece.WhiteQueen,
    wK: Piece.WhiteKing,
    bP: Piece.BlackPawn,
    bR: Piece.BlackRook,
    bN: Piece.BlackKnight,
    bB: Piece.BlackBishop,
    bQ: Piece.BlackQueen,
    bK: Piece.BlackKing,
}
