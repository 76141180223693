import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { Button, CssBaseline, Stack, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { boardColors } from '../../sharedComponents/src/globalHeader/theme/colors'
import { getDesignTokens } from '../../theme/theme'

const AppError: React.FC = () => {
    const theme = getDesignTokens('dark', boardColors.default)

    const handleReload = () => {
        window.location.reload()
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme={true}>
                <Stack sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', p: 2 }}>
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        gap={1.5}
                        sx={{ p: 2, flexGrow: 1, backgroundColor: 'background.paper' }}
                    >
                        <Typography variant="h1">OOPS!</Typography>
                        <Stack justifyContent="center" height="20rem" width="100%">
                            <DotLottieReact src="/assets/animations/error-boundary.lottie" loop={true} autoplay />
                        </Stack>
                        <Typography variant="h4">Something went wrong</Typography>
                        <Button sx={{ maxWidth: '200px' }} onClick={handleReload}>
                            reload the page
                        </Button>
                        <Typography variant="h6">and continue play with us</Typography>
                    </Stack>
                </Stack>
            </CssBaseline>
        </ThemeProvider>
    )
}

export default AppError
