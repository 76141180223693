import { useEffect, useState } from 'react'
import { UserData } from '../../GlobalHeader'
import {
    AnalyticsEventMap,
    BotChallengeEvent,
    CompleteLoginEvent,
    CompleteSignupEvent,
    CompletedPuzzleEvent,
    CustomChallengeEvent,
    DirectChallengeEvent,
    EmailActivatedEvent,
    EnterAutoPoolEvent,
    ExitAutoPoolEvent,
    GameFinishedEvent,
    GameOfferResultEvent,
    GameOfferSentEvent,
    GameStartedEvent,
    LogoutEvent,
    OfferRematchEvent,
    OfferRematchResultEvent,
    OpenLoginEvent,
    OpenSignupEvent,
    ResignEvent,
    RewardEvent,
    analyticsManager,
} from './AnalyticsManager'
import { getHighestUserGroupName, getTimeBucket } from './utils'

type Props = {
    gaGameKey: string
    gaSecretKey: string
    version: string
    env: string
    userData?: UserData
    loaded: () => void
}

export const GameAnalyticsServiceComponent = ({ gaGameKey, gaSecretKey, version, env, userData, loaded }: Props) => {
    const [gameanalytics, setGameanalytics] = useState<any>(null)

    useEffect(() => {
        const loadGameAnalytics = async () => {
            const { default: gameanalytics } = await import('gameanalytics')
            setGameanalytics(gameanalytics)
            loaded()
        }

        loadGameAnalytics()
    }, [])

    useEffect(() => {
        if (!gameanalytics) return

        if (userData?.id && userData.groups) {
            const userType = getHighestUserGroupName(userData.groups)
            const userId = userData.id

            const eventHandlers: { [key in keyof AnalyticsEventMap]?: Function } = {
                botChallenge: (ev: BotChallengeEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `botChallenge:${ev.botName}:${ev.minutes}-${ev.increment}:${ev.color}:${ev.difficulty}`,
                    )
                },
                completeLogin: (ev: CompleteLoginEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`completeLogin:${ev.authType}`)
                },
                completeSignup: (ev: CompleteSignupEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`completeSignup:${ev.authType}:${ev.country}`)
                },
                openSignup: (ev: OpenSignupEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`openSignup:${ev.origin}`)
                },
                logout: (ev: LogoutEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`logout`, 1)
                },
                gameOfferSent: (ev: GameOfferSentEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `gameOfferSent:${ev.type}:${ev.minutes}-${ev.increment}:${ev.color}:${ev.rated ? 'rated' : 'unrated'}`,
                    )
                },
                gameOfferResult: (ev: GameOfferResultEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `gameOfferResult:${ev.type}:${ev.result}:${ev.minutes}-${ev.increment}:${ev.color}`,
                    )
                },
                openLogin: (ev: OpenLoginEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`openLogin:${ev.origin}`)
                },
                exitAutoPool: (ev: ExitAutoPoolEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `exitAutoPool:${ev.gameType}:${ev.minutes}-${ev.increment}`,
                    )
                },
                customChallenge: (ev: CustomChallengeEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `customChallenge:${ev.minutes}-${ev.increment}:${ev.color}:${ev.rated ? 'rated' : 'unrated'}`,
                    )
                },
                offerRematch: (ev: OfferRematchEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `offerRematch:${ev.minutes}-${ev.increment}:${ev.color}:${ev.rated ? 'rated' : 'unrated'}`,
                    )
                },
                resign: (ev: ResignEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `resign:${ev.minutes}-${ev.increment}:${ev.color}:${ev.rated ? 'rated' : 'unrated'}`,
                    )
                },
                completedPuzzle: (ev: CompletedPuzzleEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `completedPuzzle:${ev.origin}:${ev.result}:${ev.attempts}:${ev.usedHints}`,
                    )
                },
                gameFinished: (ev: GameFinishedEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `gameFinished:${ev.matchKind}:${ev.minutes}-${ev.increment}:${ev.method}:${ev.reason}`,
                    )
                },
                enterAutoPool: (ev: EnterAutoPoolEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `enterAutoPool:${ev.gameType}:${ev.minutes}-${ev.increment}`,
                    )
                },
                gameStarted: (ev: GameStartedEvent) => {
                    const timeInQueue = getTimeBucket(ev.timeInQueue)
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `gameStarted:${ev.matchKind}:${ev.minutes}-${ev.increment}:${ev.rated ? 'rated' : 'unrated'}:${timeInQueue}`,
                    )
                },
                reward: (ev: RewardEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`reward:${ev.type}:${ev.tier}:${ev.amount}`)
                },
                directChallenge: (ev: DirectChallengeEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `directChallenge:${ev.minutes}-${ev.increment}:${ev.color}:${ev.rated ? 'rated' : 'unrated'}`,
                    )
                },
                offerRematchResult: (ev: OfferRematchResultEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`offerRematchResult:${ev.result}`)
                },
                emailActivated: (ev: EmailActivatedEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`emailActivated`, 1)
                },
            }

            gameanalytics.GameAnalytics.setEnabledInfoLog(env !== 'prod')
            gameanalytics.GameAnalytics.configureBuild(version)
            gameanalytics.GameAnalytics.configureUserId(userId)
            // if GroupNames enum changes, this will need to be updated
            gameanalytics.GameAnalytics.configureAvailableCustomDimensions01([
                'visitor',
                'guest',
                'member',
                'subscribed',
            ])
            gameanalytics.GameAnalytics.setCustomDimension01(userType)
            gameanalytics.GameAnalytics.setEnabledEventSubmission(true)
            gameanalytics.GameAnalytics.initialize(gaGameKey, gaSecretKey)

            // Add listeners using the stored references
            Object.entries(eventHandlers).forEach(([event, handler]) => {
                analyticsManager.addEventListener(event as any, handler as any)
            })

            return () => {
                gameanalytics.GameAnalytics.endSession()
                gameanalytics.GameAnalytics.setEnabledEventSubmission(false)

                // Remove listeners using the stored references
                Object.entries(eventHandlers).forEach(([event, handler]) => {
                    analyticsManager.removeEventListener(event as any, handler as any)
                })
            }
        }
    }, [userData?.id, userData?.groups, gameanalytics, gaGameKey, gaSecretKey, version, env])

    return null
}
