import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useRef } from 'react'
import { BasePositionFEN } from '../../../../chess/positionPresets'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { Bot } from '../../../../store/types'
import updateSelectedBotData from '../hooks/updateSelectedBotData'
import BotAvatar from './BotAvatar'

const BotItem: React.FC<{ bot: Bot; selectedBot?: Bot }> = ({ bot, selectedBot }) => {
    const settings = useStoreState((state) => state.gameView.settings)

    return (
        <Stack
            sx={{
                border: '2px solid',
                borderColor: bot.id === selectedBot?.id ? 'primary.main' : 'transparent',
                backgroundColor: 'background.paper',
            }}
        >
            <BotAvatar bot={bot} />
            <Stack
                alignItems="center"
                sx={{
                    pb: 1,
                }}
            >
                <Typography variant="h6">{bot.name}</Typography>
                <Typography variant="body2" fontSize={'14px'}>
                    {`${bot.strengthLevel ? bot.strengthLevel : !settings.friendlyMode ? bot.strengthRating : 'custom'}`}
                </Typography>
            </Stack>
        </Stack>
    )
}

BotItem.displayName = 'BotItem'

const BotOfTheDayItem: React.FC<{ bot: Bot; selectedBot?: Bot }> = ({ bot, selectedBot }) => {
    const settings = useStoreState((state) => state.gameView.settings)

    return (
        <Grid
            container
            sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                backgroundColor: 'background.paper',
            }}
        >
            <Grid item xs={3.8}>
                <BotAvatar bot={bot} hideBotOfTheDay={true} />
            </Grid>
            <Grid
                item
                xs={8.2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Stack direction="row" gap={1} sx={{ px: 1, pt: 1, justifyContent: 'space-between' }}>
                    <Stack>
                        <Typography variant="h5">{bot.name}</Typography>
                        <Typography variant="body2" fontSize={'16px'}>
                            {`${bot.strengthLevel ? bot.strengthLevel : !settings.friendlyMode ? bot.strengthRating : 'custom'}`}
                        </Typography>
                    </Stack>
                    <img width="40px" height="40px" src={bot.botdRewardURL} alt="Featured bot reward" />
                </Stack>
                <Typography
                    variant="h5"
                    color="primary"
                    sx={(theme) => ({
                        p: 1,
                        pb: 0.5,
                        width: '100%',
                        backgroundColor: 'primary.main',
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                    })}
                >
                    featured bot
                </Typography>
            </Grid>
        </Grid>
    )
}

BotOfTheDayItem.displayName = 'BotOfTheDayItem'

const BotsList: React.FC = () => {
    const bots = useStoreState((state) => state.matchMaker.bots)
    const selectedBot = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge.selectedBot)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)

    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)

    const botRefs = useRef<(HTMLDivElement | null)[]>([])

    const onBotSelect = (bot: Bot, index: number) => {
        const updatedSearchOptions = updateSelectedBotData(fullSearchOptions, bot)
        setSearchOptions(updatedSearchOptions)
        setTimeout(() => {
            botRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }, 100)
    }

    useEffect(() => {
        if (selectedBot) {
            setPlayComputerFen(selectedBot.startingFEN || BasePositionFEN)
        }
    }, [selectedBot])

    return (
        <Grid container columnSpacing={0.5} sx={{ flexGrow: 0, minHeight: 0, overflowY: 'auto' }}>
            {bots.map((bot, index) => (
                <Grid
                    key={bot.id}
                    item
                    xs={bot.isBotOfTheDay ? 12 : 4}
                    sx={{
                        mt: 1,
                        cursor: 'pointer',
                        opacity: bot.id === selectedBot?.id ? 1 : 0.5,
                    }}
                    ref={(el) => (botRefs.current[index] = el)}
                    onClick={() => onBotSelect(bot, index)}
                >
                    {bot.isBotOfTheDay ? (
                        <BotOfTheDayItem bot={bot} selectedBot={selectedBot} />
                    ) : (
                        <BotItem bot={bot} selectedBot={selectedBot} />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}

BotsList.displayName = 'BotsList'

export default BotsList
