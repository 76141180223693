import { Box, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'

interface PuzzleStreakCounterProps {
    title: string
    value: number
    sync?: boolean
}

const animationDuration = 1

export const PuzzleStreakCounter: FC<PuzzleStreakCounterProps> = ({ title, value, sync }) => {
    const [styles, setStyles] = useState<any>({})
    const [adjustedValue, setAdjustedValue] = useState<PuzzleStreakCounterProps['value']>()

    useEffect(() => {
        let timeout
        setAdjustedValue((prevValue) => {
            if (prevValue !== undefined && prevValue !== value) {
                setStyles({ transform: 'scale(0.25)', opacity: 0 })

                timeout = setTimeout(() => {
                    setStyles({})
                }, animationDuration * 1000)

                setTimeout(
                    () => {
                        setAdjustedValue(value)
                    },
                    (animationDuration * 1000) / 2 + animationDuration * 150,
                )
            } else {
                return value
            }

            return prevValue
        })

        return () => {
            setStyles({})
            clearTimeout(timeout)
        }
    }, [value])

    return (
        <Box width="88px">
            <Typography
                textTransform="uppercase"
                variant="body2"
                fontSize="18px"
                fontFamily="Space Grotesk"
                fontWeight={700}
            >
                {title}
            </Typography>
            <Typography
                fontFamily="Space Grotesk"
                fontWeight={700}
                sx={{
                    fontSize: { xs: '28px', md: '34px' },
                    height: { xs: '28px', md: '34px' },
                    lineHeight: { xs: '28px', md: '34px' },
                    transform: 'scale(1)',
                    opacity: 1,
                    transition: `transform ${animationDuration}s, opacity ${animationDuration}s, color ${animationDuration}s`,
                    transformOrigin: 'center',
                    // fontVariantNumeric: 'tabular-nums',
                    color: sync ? '#FFD568' : 'inherit',
                    ...styles,
                }}
                variant="h3"
            >
                {adjustedValue}
            </Typography>
        </Box>
    )
}
