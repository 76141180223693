import { UserGroup } from '../../GlobalHeader';

export const getHighestUserGroupName = (userGroups: UserGroup[]) => {
    const sortedGroups = [...userGroups].sort((a, b) => b.id - a.id)
    return sortedGroups.length > 0 ? sortedGroups[0].name : 'visitor'
}

export const getTimeBucket = (timeInMs: number): string => {
    const timeInSeconds = timeInMs / 1000

    if (timeInSeconds < 5) return '<5 sec'
    if (timeInSeconds < 10) return '5-10 sec'
    if (timeInSeconds < 20) return '10-20 sec'
    if (timeInSeconds < 30) return '20-30 sec'
    if (timeInSeconds < 60) return '30-60 sec'
    if (timeInSeconds < 120) return '60-120 sec'
    if (timeInSeconds < 300) return '120-300 sec'
    return '300+ sec'
};