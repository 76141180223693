import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    changePassword,
    editProfile,
    loadUserProfileData,
    updateSessionUser,
    uploadAvatar,
} from '../../../sharedComponents/src/globalHeader/functions/ams-functions'
import { UserData } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import { getAppConfig, IAppConfig } from '../../../sharedComponents/src/globalHeader/services/AppConfigService'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import CountryDropdown, { CountryItem } from '../Inputs/CountryDropdown'
import FileUpload, { FILE_FORMAT, MAX_FILE_SIZE, MB_SIZE } from '../Inputs/FileUpload'
import Input from '../Inputs/Input'

const ProfileAccount: React.FC = () => {
    const user = useStoreState((state) => state.userData.userData)
    const newAvatar = useStoreState((state) => state.profileView.newAvatar)
    const currentAvatar = useStoreState((state) => state.profileView.currentAvatar)
    const updateUserData = useStoreActions((state) => state.updateUserData)
    const setNewAvatar = useStoreActions((state) => state.profileView.setNewAvatar)
    const setCurrentAvatar = useStoreActions((state) => state.profileView.setCurrentAvatar)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)

    const [userData, setUserData] = useState(user || undefined)
    const [avatarError, setAvatarError] = useState<string>('')
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData((prevUserData: any) => {
            return {
                ...prevUserData,
                [e.target.name]: e.target.value,
            }
        })
    }

    const countryChangeHandler = (selectedCountry: CountryItem) => {
        setUserData((prevUserData: any) => {
            return {
                ...prevUserData,
                country: selectedCountry.code,
            }
        })
    }

    const userDataChangeHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (userData) {
            if (newAvatar) {
                const avatarResult = await uploadAvatar(newAvatar)
                setNewAvatar(null)
                if (avatarResult !== true) {
                    setAvatarError(avatarResult.error.message)
                    return
                }
            }
            const result = await editProfile(userData)
            if (result.error) {
                setSnackbarMessage(result.error.message)
            } else {
                const data: UserData = await loadUserProfileData()
                updateUserData(data)
                setCurrentAvatar(data?.avatar_url || '')
                updateSessionUser(data)
                setOverlayTrigger({ name: 'updateUser', data })
                setSnackbarMessage(result.message)
            }
        }
    }

    const changePasswordHandler = async (e: any) => {
        e.preventDefault()

        const data = await changePassword(e.target[0].value, e.target[1].value)
        if (data.message) {
            setSnackbarMessage(data.message)
            return
        } else if (data.error) {
            setSnackbarMessage(data.error.message)
            return
        }

        setSnackbarMessage('Password changed successfully!')
        sessionStorage.setItem('_icc_user', '')
    }

    const onUploadAvatar = async (file: File) => {
        if (!(file.type === FILE_FORMAT.JPEG || file.type === FILE_FORMAT.PNG)) {
            setAvatarError('JPEG or PNG File Required')
        } else if (file.size > MAX_FILE_SIZE * MB_SIZE) {
            setAvatarError(`File exceeds ${MAX_FILE_SIZE}MB`)
        } else {
            setNewAvatar(file)
            if (user) {
                updateUserData({ ...user, avatar_url: URL.createObjectURL(file) })
            }
            setAvatarError('')
        }
    }

    const onAvatarUploadCancel = async () => {
        if (user && currentAvatar) {
            updateUserData({ ...user, avatar_url: currentAvatar })
        }
        setNewAvatar(null)
        setAvatarError('')
    }

    const getUserStatus = (id: number) => {
        switch (id) {
            case 0:
                return 'Member: Free'
            case 1:
                return 'Member: Premium'
            default:
                return 'Unknown status'
        }
    }

    return userData ? (
        <Stack sx={{ px: 3 }}>
            <Stack sx={{ borderBottom: '2px solid', borderBottomColor: 'text.primary', pb: 1 }}>
                <Typography variant="h3">Account</Typography>
            </Stack>
            <form onSubmit={userDataChangeHandler}>
                <Stack spacing={2} sx={{ pt: 1.5, pb: 5, mb: 5 }}>
                    <Input
                        label="Username"
                        readOnly={true}
                        type="text"
                        name="user_name"
                        value={userData.user_name || ''}
                        onChange={onChange}
                    />
                    <Input
                        label="First Name"
                        type="text"
                        name="first_name"
                        value={userData.first_name || ''}
                        onChange={onChange}
                    />
                    <Input
                        label="Last Name"
                        type="text"
                        name="last_name"
                        value={userData.last_name || ''}
                        onChange={onChange}
                    />
                    <Input
                        label="E-mail Address"
                        readOnly={true}
                        type="email"
                        name="email"
                        value={userData?.email || ''}
                        onChange={onChange}
                    />
                    <CountryDropdown
                        label="Country"
                        value={userData?.country || ''}
                        onCountryChange={countryChangeHandler}
                    />
                    <FileUpload
                        value={newAvatar}
                        text="upload avatar"
                        error={avatarError}
                        onChange={onUploadAvatar}
                        onCancel={onAvatarUploadCancel}
                    />
                    <Button
                        disabled={Boolean(avatarError)}
                        type="submit"
                        variant="outlined"
                        sx={{
                            '&:disabled': {
                                color: (theme) => theme.palette.text.disabled,
                            },
                            fontFamily: 'Fira Sans',
                            maxWidth: 'fit-content',
                            px: 3,
                            mt: '3rem !important',
                            letterSpacing: '0.1em',
                        }}
                    >
                        SAVE
                    </Button>
                </Stack>
            </form>
            <Stack sx={{ borderBottom: '2px solid', borderBottomColor: 'text.primary', pb: 1 }}>
                <Typography variant="h5">CHANGE PASSWORD</Typography>
            </Stack>
            <form onSubmit={changePasswordHandler}>
                <Stack spacing={2} sx={{ pt: 1.5, pb: 5, mb: 5 }}>
                    <Input label="Current Password" type="password" />
                    <Input label="New Password" type="password" />
                    <Button
                        type="submit"
                        variant="outlined"
                        sx={{
                            fontFamily: 'Fira Sans',
                            maxWidth: 'fit-content',
                            px: 3,
                            mt: '3rem !important',
                            letterSpacing: '0.1em',
                        }}
                    >
                        CHANGE
                    </Button>
                </Stack>
            </form>

            <Stack sx={{ borderBottom: '2px solid', borderBottomColor: 'text.primary', pb: 1 }}>
                <Typography variant="h5">SUBSCRIPTION INFORMATION</Typography>
            </Stack>
            <Stack spacing={4} sx={{ pt: 3, pb: 10 }}>
                <Grid container>
                    <Grid item xs={4} sx={{ px: 1.5 }}>
                        <Typography variant="body2" textTransform="uppercase">
                            Subscriber
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ px: 1.5 }}>
                        <Typography variant="body2">{getUserStatus(userData.subscription_state)}</Typography>
                    </Grid>
                </Grid>
                {/* <Grid container>
                    <Grid item xs={4} sx={{ px: 1.5 }}>
                        <Typography variant="body2" textTransform="uppercase">
                            Expiration Date
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ px: 1.5 }}>
                        <Typography variant="body2">{formatExpiry(userData.subscription_days_left)}</Typography>
                    </Grid>
                </Grid> */}
                <Stack spacing={2}>
                    <Link style={{ maxWidth: '300px' }} to={`${import.meta.env.VITE_REACT_APP_STRIPE_CLIENT_PORTAL}`}>
                        <Button
                            variant="outlined"
                            sx={{
                                fontFamily: 'Fira Sans',
                            }}
                        >
                            MANAGE MY SUBSCRIPTION
                        </Button>
                    </Link>
                    <Link
                        style={{ maxWidth: '300px' }}
                        to={`${import.meta.env.VITE_REACT_APP_WEBSITE_URL}/memberships`}
                    >
                        <Button
                            sx={{
                                fontFamily: 'Fira Sans',
                            }}
                        >
                            PURCHASE SUBSCRIPTION
                        </Button>
                    </Link>
                </Stack>
            </Stack>

            {appConfig?.hideAccountDeletion === false ? (
                <>
                    <Stack sx={{ borderBottom: '2px solid', borderBottomColor: 'text.primary', pb: 1 }}>
                        <Typography variant="h5">Account deletion</Typography>
                    </Stack>
                    <Stack sx={{ pt: 1.5, pb: 5, my: 2 }}>
                        <Link
                            style={{ maxWidth: '300px' }}
                            to={`${import.meta.env.VITE_REACT_APP_WEBSITE_URL}/account-deletion`}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    fontFamily: 'Fira Sans',
                                }}
                            >
                                Delete my account
                            </Button>
                        </Link>
                    </Stack>
                </>
            ) : null}
        </Stack>
    ) : (
        <Stack>Loading...</Stack>
    )
}

ProfileAccount.displayName = 'ProfileAccount'

export default ProfileAccount
