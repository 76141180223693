import camelCase from 'lodash-es/camelCase'
import { useEffect } from 'react'
import { AreaType, UserData } from '../globalHeader/GlobalHeader'
import { newContentSeen } from '../globalHeader/functions/ams-functions'

export const useNewContent = (user: UserData | null, setUserData: (UserData) => void, area: AreaType) => {
    let internalWindow

    if (typeof window !== 'undefined') {
        internalWindow = window
    }

    useEffect(() => {
        let timeout
        if (internalWindow) {
            let key = camelCase(internalWindow.location.pathname)
            if (!key) {
                // root path
                key = area === AreaType.PLAY_AREA ? 'play' : 'home'
            }

            if (user?.newContent?.[key] !== undefined) {
                timeout = setTimeout(async () => {
                    setUserData({ ...user, newContent: { ...user.newContent, [key]: false } })
                    const newContent = await newContentSeen(key)
                    newContent && setUserData({ ...user, newContent })
                }, 50)
            }
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [internalWindow?.location?.href, user?.id])
}
