import { Color } from '../../../../chess/types'
import { GameResultType, PlayerInGameData } from '../../../../store/types'
import { GAME_END_REASONS } from '../gameServerConnector/GSTypes'

export const dialogData = {
    [GameResultType.Checkmated + Color.White]: {
        winnerMessage: 'You won!',
        description: 'Black was checkmated',
        otherMessage: 'You lost!',
        neutralMessage: 'White won',
    },
    [GameResultType.Checkmated + Color.Black]: {
        winnerMessage: 'You won!',
        description: 'White was checkmated',
        otherMessage: 'You lost!',
        neutralMessage: 'Black won',
    },
    [GameResultType.ForfeitedOnTime + Color.White]: {
        winnerMessage: 'You won!',
        description: 'Black forfeited on time',
        otherMessage: 'You lost!',
        neutralMessage: 'White won',
    },
    [GameResultType.ForfeitedOnTime + Color.Black]: {
        winnerMessage: 'You won!',
        description: 'White forfeited on time',
        otherMessage: 'You lost!',
        neutralMessage: 'Black won',
    },
    [GameResultType.Resigned + Color.White]: {
        winnerMessage: 'You won!',
        description: 'Black resigned',
        otherMessage: 'You lost!',
        neutralMessage: 'White won',
    },
    [GameResultType.Resigned + Color.Black]: {
        winnerMessage: 'You won!',
        description: 'White resigned',
        otherMessage: 'You lost!',
        neutralMessage: 'Black won',
    },
    // [GameResultType.NoPlayerMoveSinceStart + Color.White]: {
    //     winnerMessage: 'Game aborted',
    //     description: 'Black did not move',
    //     otherMessage: 'Game aborted',
    // },
    // [GameResultType.NoPlayerMoveSinceStart + Color.Black]: {
    //     winnerMessage: 'Game aborted',
    //     description: 'White did not move',
    //     otherMessage: 'Game aborted',
    // },
    [GameResultType.PlayerDisconnected + Color.White]: {
        winnerMessage: 'You won!',
        description: 'Black disconnected and forfeits',
        otherMessage: 'You lost!',
        neutralMessage: 'White won',
    },
    [GameResultType.PlayerDisconnected + Color.Black]: {
        winnerMessage: 'You won!',
        description: 'White disconnected and forfeits',
        otherMessage: 'You lost!',
        neutralMessage: 'Black won',
    },
    [GameResultType.AllPlayersDisconnected]: {
        otherMessage: 'Game aborted',
        description: 'Both players disconnected',
        neutralMessage: 'Game aborted',
    },
    [GameResultType.NoPlayerMoveSinceStart]: {
        otherMessage: 'Game aborted',
        description: 'Opening move not made',
        neutralMessage: 'Game aborted',
    },
    [GameResultType.Draw]: {
        otherMessage: 'Game drawn',
        description: 'Draw agreed',
        neutralMessage: 'Game drawn',
        neutralDescription: 'Players agreed on a draw',
    },
    [GameResultType.InsufficientMaterial]: {
        otherMessage: 'Game drawn',
        description: 'Insufficient material',
        neutralMessage: 'Game drawn',
    },
    [GameResultType.Stalemate]: {
        otherMessage: 'Game drawn',
        description: 'Stalemate',
        neutralMessage: 'Game drawn',
    },
    [GameResultType.ThreefoldRepetition]: {
        otherMessage: 'Game drawn',
        description: 'Drawn by threefold repetition',
        neutralMessage: 'Game drawn',
    },
    [GameResultType.FivefoldRepetition]: {
        otherMessage: 'Game drawn',
        description: 'Drawn by fivefold repetition',
        neutralMessage: 'Game drawn',
    },
    [GameResultType.FiftyMoveRule]: {
        otherMessage: 'Game drawn',
        description: 'Drawn by fifty move rule',
        neutralMessage: 'Game drawn',
    },
    [GameResultType.SeventyFiveMoveRule]: {
        otherMessage: 'Game drawn',
        description: 'Drawn by seventy five move rule',
        neutralMessage: 'Game drawn',
    },
    [GameResultType.Aborted]: {
        otherMessage: 'Game aborted',
        description: 'Rating is not affected',
        neutralMessage: 'Game aborted',
    },
    [GameResultType.TechnicalErrorAi]: {
        otherMessage: 'Game aborted',
        description: 'Technical error',
        neutralMessage: 'Game aborted',
    },
    [GameResultType.Unknown]: {
        winnerMessage: 'Game ended',
        otherMessage: 'Game ended',
        description: 'Unknown reason',
        neutralMessage: 'Game ended',
    },
    [GameResultType.Timeout]: {
        otherMessage: 'Game Timeout',
        description: 'Timeout',
        neutralMessage: 'Game Timeout',
    },
    [GameResultType.SecondPlayerTimeout]: {
        otherMessage: 'Game Aborted',
        description: 'opponent failed to connect',
        neutralMessage: 'Game aborted',
    },
}

export const isAWin = (winnerId: string | undefined, player: PlayerInGameData) => {
    return winnerId && winnerId === (player.playerKind === 'machine' ? 'machine' : player.playerId)
}

export const isADraw = (result: { winnerId: string | undefined; type: GameResultType }) => {
    const drawTypes = [
        GameResultType.Draw,
        GameResultType.FiftyMoveRule,
        GameResultType.FivefoldRepetition,
        GameResultType.InsufficientMaterial,
        GameResultType.SeventyFiveMoveRule,
        GameResultType.Stalemate,
        GameResultType.ThreefoldRepetition,
    ]

    return result.winnerId === undefined && drawTypes.includes(result.type)
}

export const getGameResultType = (reason: GAME_END_REASONS) => {
    switch (reason) {
        case GAME_END_REASONS.CHECK_MATE:
            return GameResultType.Checkmated
        case GAME_END_REASONS.RESIGNATION:
            return GameResultType.Resigned
        case GAME_END_REASONS.INSUFFICIENT_MATERIAL:
            return GameResultType.InsufficientMaterial
        case GAME_END_REASONS.STALE_MATE:
            return GameResultType.Stalemate
        case GAME_END_REASONS.THREE_FOLD_REPETITION:
            return GameResultType.ThreefoldRepetition
        case GAME_END_REASONS.FIVE_FOLD_REPETITION:
            return GameResultType.FivefoldRepetition
        case GAME_END_REASONS.FIFTY_MOVE_RULE:
            return GameResultType.FiftyMoveRule
        case GAME_END_REASONS.SEVENTY_FIVE_MOVE_RULE:
            return GameResultType.SeventyFiveMoveRule
        case GAME_END_REASONS.DRAW:
            return GameResultType.Draw
        case GAME_END_REASONS.ABORTED:
            return GameResultType.Aborted
        case GAME_END_REASONS.SECOND_PLAYER_JOIN_TIMEOUT:
            return GameResultType.SecondPlayerTimeout
        case GAME_END_REASONS.CLOCK_EXPIRATION:
            return GameResultType.ForfeitedOnTime
        case GAME_END_REASONS.TECHNICAL_ERROR_AI:
            return GameResultType.TechnicalErrorAi
        case GAME_END_REASONS.NO_PLAYER1_MOVE_SINCE_START:
        case GAME_END_REASONS.NO_PLAYER2_MOVE_SINCE_START:
            return GameResultType.NoPlayerMoveSinceStart
        case GAME_END_REASONS.ALL_PLAYERS_DISCONNECTED:
            return GameResultType.AllPlayersDisconnected
        case GAME_END_REASONS.PLAYER1_DISCONNECTED:
        case GAME_END_REASONS.PLAYER2_DISCONNECTED:
            return GameResultType.PlayerDisconnected
        case GAME_END_REASONS.REASON_NORMAL:
        default:
            return GameResultType.Unknown
    }
}
