import { useEffect } from 'react'
import { useStoreActions, useStoreState } from '../store/hooks'

export const useWebsocketGateway = () => {
    const { connect, disconnect } = useStoreActions((actions) => actions.ws)
    const token = useStoreState((state) => state.token)

    useEffect(() => {
        const timeout = setTimeout(() => {
            token && connect()
        }, 1)
        return () => {
            clearTimeout(timeout)
            disconnect()
        }
    }, [token])
}
