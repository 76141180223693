import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { loadScript } from '../globalHeader/common/loadScript'
import { loginSocial, upgradeGuestSocial } from '../globalHeader/functions/ams-functions'
import { analyticsManager } from '../globalHeader/services/analytics/AnalyticsManager'
import { ServerErrors } from '../utils/serverErrors'
import { SocialType, Theme } from './SocialLogin'

declare global {
    interface Window {
        FB: any
        fbAsyncInit: any
    }
}

interface IProps {
    apiURL: string
    facebookAppId: string
    facebookApiVersion: string
    isGuest: boolean
    onSignUpSocial: (socialResponse: any) => void
    onLoginSuccessful: () => void
    logoutHandler: () => Promise<void>
    theme: Theme
}

const FacebookAuth: React.FC<IProps> = (props) => {
    const {
        onSignUpSocial,
        onLoginSuccessful,
        isGuest,
        logoutHandler,
        apiURL,
        facebookAppId,
        facebookApiVersion,
        theme,
    } = props
    const [socialType, setSocialType] = useState(SocialType.FACEBOOK)

    useEffect(() => {
        const src = 'https://connect.facebook.net/en_US/sdk.js'

        loadScript(src)
            .then(() => {
                window.fbAsyncInit = function () {
                    window.FB.init({
                        appId: facebookAppId,
                        cookie: true, // Enable cookies to allow the server to access the session.
                        xfbml: true, // Parse social plugins on this webpage.
                        version: facebookApiVersion, // Use this Graph API version for this call.
                    })
                }
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    const authCallback = (response: any, data: any) => {
        if (data.error) {
            if (
                data.error.code === ServerErrors.USERNAME_IS_MANDATORY_FOR_FIRST_LOGIN ||
                data.error.code === ServerErrors.SOCIAL_LOGIN_MANDATORY_FIELDS_IS_MISSING
            ) {
                onSignUpSocial({ ...response, socialType })
                // social signup with facebook
            } else {
                console.log('Facebook auth error:', data.error)
            }
        } else {
            analyticsManager.dispatchEvent('completeLogin', {
                authType: 'facebook',
                userId: data.user.id,
                userName: data.user.user_name,
            })
            onLoginSuccessful()
        }
    }

    const handleResponse = () => {
        window.FB.login(
            (response: any) => {
                if (response.status === 'connected') {
                    // Logged into your webpage and Facebook.
                    if (isGuest) {
                        upgradeGuestSocial(
                            response.authResponse.accessToken,
                            response.authResponse.signedRequest,
                            socialType,
                            logoutHandler,
                        ).then((data) => authCallback(response, data))
                    } else {
                        loginSocial(
                            response.authResponse.accessToken,
                            response.authResponse.signedRequest,
                            socialType,
                        ).then((data) => authCallback(response, data))
                    }
                } else {
                    // The person is not logged into your webpage or we are unable to tell.
                    console.log('Facebook auth declined')
                }
            },
            { scope: 'public_profile,email' },
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                px: 2,
            }}
        >
            <Box
                sx={{
                    border: '2px solid #000000',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    background: '#FFFFFF',
                }}
                onClick={handleResponse}
            >
                <FacebookRoundedIcon
                    sx={{
                        width: '26px',
                        height: '26px',
                        fill: '#2374f2',
                    }}
                />
            </Box>
            <Typography
                sx={{
                    color: theme === 'light' ? 'rgb(16 24 32)' : '#F5F5F5',
                    fontWeight: 400,
                    pt: 1,
                    fontSize: '1rem',
                    fontFamily: 'Space Grotesk',
                }}
            >
                Facebook
            </Typography>
        </Box>
    )
}

FacebookAuth.displayName = 'FacebookAuth'

export default FacebookAuth
