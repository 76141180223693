import csAPI from "../../API/csConfig"
import { RatingType } from "../../components/matchmaking/ChallengeCard"
import GetCookie from "../../sharedComponents/src/globalHeader/common/getCookie"
import { createQueryParams } from "../../sharedComponents/src/globalHeader/common/queryParams"
import { errorHandler, tokenCookieKey } from "../../sharedComponents/src/globalHeader/functions/ams-functions"
import { GameHistoryTypes } from "./constants"

interface HistoryPlayer {
    id: string
    country: string
    userName: string
    rating?: number
    color: 'white' | 'black'
    kind: string
    provisionalProfile: boolean
    title?: string
}

export interface GameHistory {
    matchId: string
    matchKind: 'human_vs_ai' | 'human_vs_human'
    player1: HistoryPlayer
    player2: HistoryPlayer
    timeMode: {
        durationMinutes: number
        clockIncrementSeconds: number
        name: RatingType
    }
    outcome: '1-0' | '0-1' | '1/2-1/2' | '*'
    method: string
    pgn: string
    createdAt: string
    startedAt: string
    endedAt: string
    reason: string
    finalFen: string
    rated: boolean
}

export interface GameHistoryData {
    matchHistory: Array<GameHistory>
    recordAmount: number
    error?: any
}

export const getGameHistory = async (token: string, gameType: GameHistoryTypes, limit: number, offset: number, sort?: string) => {
    try {
        const response = await csAPI.get(
            `/api/history${createQueryParams({
                gameType,
                limit,
                offset,
                sort,
                token
            })}`)

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const getGameHistoryByID = async (id: string) => {
    try {
        const token = GetCookie(tokenCookieKey)
        const response = await csAPI.get(
            `/api/history/${id}${createQueryParams({
                token
            })}`)

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}
