import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useStoreState } from '../../../../store/hooks'
import BotDetails from './BotDetails'

const AboutBot: React.FC = () => {
    const selectedBot = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge.selectedBot)
    const [expanded, setExpanded] = React.useState<boolean>(selectedBot?.difficultyLevel === -1 || false)
    const [prevExpanded, setPrevExpanded] = React.useState<boolean>(selectedBot?.difficultyLevel === -1 || false)

    useEffect(() => {
        if (selectedBot?.difficultyLevel === -1) {
            setPrevExpanded(expanded)
            setExpanded(true)
        } else {
            setExpanded(prevExpanded)
        }
    }, [selectedBot])

    const handleExpandedChange = () => {
        setExpanded(!expanded)
        setPrevExpanded(!expanded)
    }

    return (
        <Accordion expanded={expanded} onChange={handleExpandedChange}>
            <AccordionSummary color="secondary" expandIcon={<ExpandLessIcon />}>
                <Typography variant="h6">About</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <BotDetails />
            </AccordionDetails>
        </Accordion>
    )
}

AboutBot.displayName = 'AboutBot'

export default AboutBot
