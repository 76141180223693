import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { Stack } from '@mui/system'
import React from 'react'
import DrawIcon from '../../../../assets/icons/draw.svg?react'
import CircularButton from '../../../analysisView/components/analyisControls/CircularButton'
import FlipIcon from './icons/Flip'
import ResignIcon from './icons/Resign'

export type GameEndControlsProps = {
    onResignClick: () => void
    onDrawClick: () => void
    onAbortClick: () => void
    onFlipClick: () => void
    setSentOfferDraw: (setSentOfferDraw: boolean) => void
    vsHuman: boolean
    drawEnabled: boolean
    abortEnabled: boolean
    sentOfferDraw: boolean
    disabled: boolean
}
export const GameEndControls = React.memo((props: GameEndControlsProps) => {
    const {
        onResignClick,
        onDrawClick,
        onAbortClick,
        onFlipClick,
        setSentOfferDraw,
        sentOfferDraw,
        vsHuman,
        drawEnabled,
        abortEnabled,
        disabled,
    } = props
    const [drawTimer, setDrawTimer] = React.useState(0)

    // drawTimer
    React.useEffect(() => {
        let intervalIdDraw: NodeJS.Timeout
        if (sentOfferDraw && drawTimer === 0) {
            setDrawTimer(100)
        }
        if (drawEnabled && drawTimer > 0) {
            intervalIdDraw = setInterval(() => {
                setDrawTimer(drawTimer - 1)
            }, 600)
            if (drawTimer === 1) {
                setSentOfferDraw(false)
                setDrawTimer(0)
            }
        }

        return () => {
            clearInterval(intervalIdDraw)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentOfferDraw, drawTimer])

    return (
        <Stack direction="row" justifyContent="center" gap="16px">
            <CircularButton disabled={disabled} title="Resign" onClick={onResignClick} icon={<ResignIcon />} />

            <CircularButton disabled={disabled} title="Flip board" onClick={onFlipClick} icon={<FlipIcon />} />

            {vsHuman && (
                <>
                    <Box style={{ position: 'relative' }}>
                        <CircularProgress
                            className="circularProgress"
                            variant="determinate"
                            value={drawTimer}
                            size={'3rem'}
                            style={{
                                display: drawEnabled ? 'block' : 'none',
                                zIndex: 4, // set a lower z-index value than the abort button
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                margin: 'auto',

                                position: 'absolute',
                                pointerEvents: 'none',
                            }}
                        />
                        <CircularButton
                            title="Offer draw"
                            onClick={() => onDrawClick()}
                            icon={<DrawIcon />}
                            disabled={!drawEnabled || sentOfferDraw || disabled}
                        />
                    </Box>

                    <CircularButton
                        title="Abort"
                        onClick={onAbortClick}
                        icon={<CloseIcon sx={{ fontSize: '28px' }} />}
                        disabled={!abortEnabled || disabled}
                    />
                </>
            )}
        </Stack>
    )
})
