// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.2
// source: popup-service/fetch_popups_response.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "popup_service";

export interface Popup {
  name: string;
  asset: string;
  ctaText: string;
  ctaLink: string;
  textColor: string;
  bgColor: string;
}

export interface FetchPopupsResponse {
  popups: Popup[];
}

function createBasePopup(): Popup {
  return { name: "", asset: "", ctaText: "", ctaLink: "", textColor: "", bgColor: "" };
}

export const Popup: MessageFns<Popup> = {
  encode(message: Popup, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.asset !== "") {
      writer.uint32(18).string(message.asset);
    }
    if (message.ctaText !== "") {
      writer.uint32(26).string(message.ctaText);
    }
    if (message.ctaLink !== "") {
      writer.uint32(34).string(message.ctaLink);
    }
    if (message.textColor !== "") {
      writer.uint32(42).string(message.textColor);
    }
    if (message.bgColor !== "") {
      writer.uint32(50).string(message.bgColor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Popup {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePopup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.asset = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.ctaText = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.ctaLink = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.textColor = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.bgColor = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseFetchPopupsResponse(): FetchPopupsResponse {
  return { popups: [] };
}

export const FetchPopupsResponse: MessageFns<FetchPopupsResponse> = {
  encode(message: FetchPopupsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.popups) {
      Popup.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FetchPopupsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchPopupsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.popups.push(Popup.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
}
