import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Bot } from '../../../../store/types'

interface IProps {
    bot: Bot
    hideBotOfTheDay?: boolean
}

const BotAvatar: React.FC<IProps> = ({ bot, hideBotOfTheDay = false }) => {
    const isBotOfTheDay = bot.isBotOfTheDay && !hideBotOfTheDay
    return (
        <Stack
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
                position: isBotOfTheDay ? 'relative' : 'unset',
            }}
        >
            <Avatar
                src={bot.avatarURL}
                variant="square"
                sx={{
                    height: '100%',
                    width: '100%',
                    aspectRatio: '1 / 1',
                    border: '0px',
                }}
            />
            {isBotOfTheDay && (
                <Typography
                    variant="h5"
                    sx={(theme) => ({
                        fontSize: '12px !important',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        textAlign: 'center',
                        backgroundColor: 'primary.main',
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                    })}
                >
                    featured bot
                </Typography>
            )}
        </Stack>
    )
}

BotAvatar.displayName = 'BotAvatar'

export default BotAvatar
