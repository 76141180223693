import { Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'
import { lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBeforeUnload, useNavigate } from 'react-router-dom'
import { nameOfSquare } from '../../chess/basics'
import {
    addMoveToGTPosition,
    getLastPosition,
    getMoveById,
    getNextPosition,
    getPositionById,
    getPreviousPosition,
    getStartingPosition,
} from '../../chess/gameTree'
import { createPuzzlePGNString, gameFromPGN } from '../../chess/pgn'
import { Color, Move } from '../../chess/types'
import AppLayout from '../../components/AppLayout/AppLayout'
import { HeaderStripe } from '../../components/HeaderStripe/HeaderStripe'
import { PuzzleStreakCounter } from '../../components/PuzzleStreakCounter/PuzzleStreakCounter'
import { PuzzleStreakOverlay, StreakAnimation } from '../../components/PuzzleStreakOverlay/PuzzleStreakOverlay'
import PuzzleRating from '../../components/Rating/PuzzleRating'
import { NotificationDialog } from '../../components/overlays/NotificationDialog/NotificationDialog'
import { Square } from '../../react-chessboard/src/chessboard/types'
import { GroupNames, LoginState } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { Layout, useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { ServerErrors } from '../../sharedComponents/src/utils/serverErrors'
import { useStoreActions, useStoreState } from '../../store/hooks'
import PuzzlesChessBoard from '../chessboard/PuzzlesChessboard'
import { NotationControls } from '../notation/components/NotationControls'
import PuzzleControls from './components/PuzzleControls'
import { Description } from './components/v2/Description'
import { NotationHeader } from './components/v2/NotationHeader'
import { Notations, NotationsType } from './components/v2/Notations'
import PuzzleButtons from './components/v2/PuzzleButtons'
import PuzzlesLimitDialog from './components/v2/PuzzleLimitDialog'
import PuzzleTags from './components/v2/PuzzleTags'
import { PuzzleStatus, getPracticePuzzle, getPuzzleTags, resolvePracticePuzzle } from './functions/puzzlesApi'
import useResultSounds from './hooks/useResultSounds'

const WelcomeDialog = lazy(() => import('./components/v2/PuzzlesWelcomeDialog'))

interface IPuzzle {
    id: string
    pgnString: string
    fen: string
    rating: number
    description: string
    isFirstMove: boolean
    streak: {
        best: number
        current: number
        updatedAt: string
    }
}

export const BOT_MOVING_DELAY = 1000 // 1 sec

const PuzzlesTrainingViewV2: React.FC = () => {
    const navigate = useNavigate()

    const { layout } = useResponsiveSizings()
    const [isPuzzleStarted, setPuzzleStarted] = useState<boolean>(false)
    const [isLastMoveCorrect, setIsLastMoveCorrect] = useState<boolean>(false)
    // const [welcomeOpen, setWelcomeOpen] = useState<boolean>(false)
    const [welcomeDescOpen, setWelcomeDescOpen] = useState<boolean>(false)
    const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false)
    const [joinUsOpen, setJoinUsOpen] = useState<boolean>(false)
    const [tagsOpen, setTagsOpen] = useState<boolean>(false)
    const [morePuzzlesOpen, setMorePuzzlesOpen] = useState<boolean>(false)
    const [showSolution, setShowSolution] = useState<boolean>(false)
    const [usedShowSolution, setUsedShowSolution] = useState<boolean>(false)
    const [currentPuzzle, setCurrentPuzzle] = useState<IPuzzle | null>(null)
    const [currentPuzzleState, setCurrentPuzzleState] = useState<boolean | null>(null)
    const [lastPuzzle, setLastPuzzle] = useState<boolean>(false)
    const [waitingForOpponentMove, setWaitingForOpponentMove] = useState<boolean>(false)
    const [isBotMoving, setIsBotMoving] = useState<boolean>(false)
    const [hint, setHint] = useState<Square>()
    const [hintsCounter, setHintsCounter] = useState<number>(0)
    const [attemptsCounter, setAttemptsCounter] = useState<number>(1)
    const [tagsIDs, setTagsIDs] = useState<number[]>([])
    const [disableNextButton, setDisableNextButton] = useState<boolean>(false)
    const [notations, setNotations] = useState<Array<NotationsType>>([])

    const puzzleGameTree = useStoreState((state) => state.puzzlesView.puzzleGameTree)
    const gameTree = useStoreState((state) => state.puzzlesView.gameTree)
    const progressCurrentPositionId = useStoreState((state) => state.puzzlesView.progressCurrentPositionId)
    const currentPositionId = useStoreState((state) => state.puzzlesView.currentPositionId)
    const flipped = useStoreState((state) => state.puzzlesView.flipped)
    const myColor = useStoreState((state) => state.puzzlesView.myColor)
    const token = useStoreState((state) => state.token)
    const userDataState = useStoreState((state) => state.userData.state)
    const userData = useStoreState((state) => state.userData.userData)
    const userGroups = useStoreState((state) => state.userData.userData?.groups)
    const userRating = useStoreState((state) => state.puzzlesView.userRating)
    const settings = useStoreState((state) => state.gameView.settings)
    const setPGNHeader = useStoreActions((state) => state.setPGNHeader)
    const setAnalysisGameTree = useStoreActions((state) => state.analysisMode.setGameTree)
    const setProgressCurrentPositionId = useStoreActions((state) => state.puzzlesView.setProgressCurrentPositionId)
    const setCurrentPositionId = useStoreActions((state) => state.puzzlesView.setCurrentPositionId)
    const initialiseGame = useStoreActions((state) => state.puzzlesView.initialiseGame)
    const setFlipBoard = useStoreActions((state) => state.puzzlesView.setFlipBoard)
    const undoLastMove = useStoreActions((state) => state.puzzlesView.undoLastMove)
    const setAllTags = useStoreActions((state) => state.puzzlesView.setAllTags)
    const resetGame = useStoreActions((state) => state.puzzlesView.resetGame)
    const setUserRating = useStoreActions((state) => state.puzzlesView.setUserRating)

    const playResultSound = useResultSounds(settings)
    const { palette } = useTheme()
    const retryTimeout = useRef<NodeJS.Timeout>()
    const nextTimeout = useRef<NodeJS.Timeout>()
    const autoMoveTimeout = useRef<NodeJS.Timeout>()
    const opponentMoveTimeout = useRef<NodeJS.Timeout>()

    const [showStreak, setShowStreak] = useState(false)
    const [streaks, setStreaks] = useState({
        current: 0,
        best: 0,
        updatedAt: new Date().toISOString(),
    })

    const handleBeforeUnload = (event) => {
        event.preventDefault()
        currentPuzzleState !== true && attemptsCounter > 1 && sendPuzzle()
    }

    useBeforeUnload(handleBeforeUnload)

    const gtPos = useMemo(() => getPositionById(gameTree, currentPositionId), [gameTree, currentPositionId])
    let loadFirstPuzzle = false

    // ---------- load data ---------- //

    // load tags data
    const getAllTags = async () => {
        if (token) {
            const tagsData = await getPuzzleTags()
            if (tagsData?.tags) {
                setAllTags(tagsData.tags)
            }
        }
    }

    // load puzzle data
    const getNextPuzzle = async () => {
        const puzzleData = await getPracticePuzzle(tagsIDs)
        if (puzzleData?.puzzle) {
            const puzzle = puzzleData.puzzle
            const pgnString = createPuzzlePGNString(puzzle.startingFen, puzzle.moves)

            setUserRating(puzzleData.userPuzzleRating || 0)
            setCurrentPuzzle({
                id: puzzle.id,
                pgnString: pgnString,
                fen: puzzle.startingFen,
                rating: puzzle.rating,
                description: puzzle.description,
                isFirstMove: puzzle.isFirstMove,
                streak: puzzleData.streak!,
            })

            setStreaks(puzzleData.streak!)
        }
        if (puzzleData?.error?.code === ServerErrors.PRACTICE_PUZZLE_LIMIT_REACHED) {
            setLastPuzzle(true)
            const isGuest = userGroups?.some((group: any) => group.name === GroupNames.GUEST)
            const isMember = userGroups?.some((group: any) => group.name === GroupNames.MEMBER)

            if (isGuest) {
                setJoinUsOpen(true)
            } else if (isMember) {
                setMorePuzzlesOpen(true)
            }
        }
        if (puzzleData?.error?.code === ServerErrors.PRACTICE_PUZZLE_FEATURE_DISABLED) {
            setJoinUsOpen(true)
        }
    }

    // clear all intervals
    const clearAllTimers = () => {
        clearTimeout(opponentMoveTimeout.current)
        clearTimeout(autoMoveTimeout.current)
        clearTimeout(nextTimeout.current)
        clearTimeout(retryTimeout.current)
    }

    // reset all states to default
    const resetAllStates = () => {
        clearAllTimers()
        setCurrentPuzzle(null)
        resetGame()
        setHint(undefined)
        setHintsCounter(0)
        setAttemptsCounter(1)
        setUsedShowSolution(false)
        setLastPuzzle(false)
    }

    // load puzzle data
    useEffect(() => {
        if (userDataState === LoginState.NOT_LOGGED_IN) {
            setJoinUsOpen(true)
            loadFirstPuzzle = false
            resetAllStates()
        } else {
            setJoinUsOpen(false)
            if (token && loadFirstPuzzle === false) {
                loadFirstPuzzle = true
                resetAllStates()
                getAllTags()
                getNextPuzzle()
            }
        }
    }, [userDataState, userGroups])

    useEffect(() => {
        return () => {
            resetAllStates()
        }
    }, [])

    // ---------- end load data ---------- //

    // ---------- puzzle init and controls ---------- //

    // init game board
    useEffect(() => {
        if (currentPuzzle && userData?.ftue.puzzle) {
            const puzzleGameTree = gameFromPGN(currentPuzzle.pgnString)
            initialiseGame({
                gameTree: puzzleGameTree,
                isFirstMove: currentPuzzle.isFirstMove,
            })
            setCurrentPuzzleState(null)

            // opponent first move
            if (!currentPuzzle.isFirstMove) {
                opponentMoveTimeout.current = setTimeout(() => {
                    setIsBotMoving(true)
                    setWaitingForOpponentMove(true)
                    setPuzzleStarted(true)
                }, 1750)
            } else {
                setPuzzleStarted(true)
            }
        }
    }, [currentPuzzle, userData?.ftue.puzzle])

    // load next puzzle or retry after error
    useEffect(() => {
        if (currentPuzzleState !== null) {
            setStreaks((prevStreaks) => {
                const newStreak =
                    currentPuzzleState && attemptsCounter <= 1 && !showSolution ? prevStreaks.current + 1 : 0

                if (prevStreaks.current === prevStreaks.best && !newStreak && prevStreaks.best) {
                    setShowStreak(true)
                    return prevStreaks
                }

                return {
                    current: newStreak,
                    best: newStreak > prevStreaks.best ? newStreak : prevStreaks.best,
                    updatedAt: new Date().toISOString(),
                }
            })
        }

        if (currentPuzzleState === true) {
            // load next puzzle
            if (showSolution) {
                setShowSolution(false)
                setDisableNextButton(false)
                setUsedShowSolution(true)
                playResultSound(true)
                sendPuzzle()
            } else {
                playResultSound(true)
                sendPuzzle()
                nextTimeout.current = setTimeout(() => {
                    onNextClick()
                }, 5000)
            }
        } else if (currentPuzzleState === false) {
            // retry current puzzle
            setAttemptsCounter(attemptsCounter + 1)
            playResultSound(false)
            retryTimeout.current = setTimeout(() => {
                undoLastMove(gameTree)
                setCurrentPuzzleState(null)
            }, 2500)
        }
    }, [currentPuzzleState])

    // resolve puzzle
    const sendPuzzle = useCallback(async () => {
        if (currentPuzzle) {
            setDisableNextButton(true)

            let status = PuzzleStatus.FAILED
            if (usedShowSolution) {
                status = PuzzleStatus.SHOWED
            } else if (currentPuzzleState === true) {
                status = PuzzleStatus.PASSED
            } else if (currentPuzzleState === null && hintsCounter === 0 && attemptsCounter === 1) {
                status = PuzzleStatus.SKIPPED
            }

            const data = await resolvePracticePuzzle({
                puzzleId: currentPuzzle.id,
                status: status,
                usedHints: hintsCounter,
                attempts: attemptsCounter,
            })

            if (data.result) {
                analyticsManager.dispatchEvent('completedPuzzle', {
                    origin: 'practicePuzzle',
                    puzzleId: currentPuzzle.id,
                    puzzleRating: currentPuzzle.rating,
                    puzzleSource: 0,
                    userPuzzleRating: userRating,
                    result: status,
                    attempts: attemptsCounter,
                    usedHints: hintsCounter,
                })
                setUserRating(data.userPuzzleRating || 0)
            }
            setDisableNextButton(false)
        }
    }, [currentPuzzle, usedShowSolution, currentPuzzleState, hintsCounter, attemptsCounter])

    const handleClickAnywhere = () => {
        if (!!nextTimeout.current) {
            clearTimeout(nextTimeout.current)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickAnywhere)
        return () => {
            document.removeEventListener('mousedown', handleClickAnywhere)
        }
    }, [])

    // load next puzzle
    const onNextClick = async () => {
        setDisableNextButton(true)
        clearAllTimers()

        // skip puzzle manually
        if (!usedShowSolution && currentPuzzleState !== true) {
            await sendPuzzle()
        }

        setHint(undefined)
        setHintsCounter(0)
        setAttemptsCounter(1)
        setCurrentPuzzleState(null)
        setUsedShowSolution(false)

        await getNextPuzzle()

        setDisableNextButton(false)
    }

    // ---------- end puzzle init and controls ---------- //

    // ---------- moves ---------- //

    // opponent or show solution auto move
    const autoMove = () => {
        const currentProgressPosition = getPositionById(puzzleGameTree, progressCurrentPositionId)

        const nextProgressMove = getMoveById(puzzleGameTree, currentProgressPosition?.nextMoveIds[0])
        if (nextProgressMove) {
            setProgressCurrentPositionId(nextProgressMove.nextPositionId)
            const currentPosition = getPositionById(gameTree, currentPositionId)
            const nextPosition = addMoveToGTPosition(gameTree, currentPosition, nextProgressMove.move)
            setCurrentPositionId(nextPosition.id)

            if (currentProgressPosition.position.turn === myColor) {
                const nextProgressPosition = getPositionById(puzzleGameTree, nextProgressMove.nextPositionId)
                if (nextProgressPosition.nextMoveIds.length === 0) {
                    setHint(undefined)
                    setCurrentPuzzleState(true)
                } else {
                    opponentMoveTimeout.current = setTimeout(() => {
                        setIsBotMoving(true)
                        setWaitingForOpponentMove(true)
                    }, BOT_MOVING_DELAY)
                }
            }
        }
    }

    // opponent move or show solution move
    useEffect(() => {
        setHint(undefined)
        if (waitingForOpponentMove) {
            autoMove()
            setWaitingForOpponentMove(false)
            setTimeout(() => {
                setIsBotMoving(false)
            }, BOT_MOVING_DELAY)
        } else if (!waitingForOpponentMove && showSolution) {
            autoMoveTimeout.current = setTimeout(() => {
                autoMove()
            }, BOT_MOVING_DELAY)
        }
    }, [waitingForOpponentMove, showSolution])

    // manually move
    const onMove = useCallback(
        (move: Move) => {
            // if it not my turn, no interaction
            if (gtPos.position.turn !== myColor) return false

            const currentProgressPosition = getPositionById(puzzleGameTree, progressCurrentPositionId)
            const expectedProgressMove = getMoveById(puzzleGameTree, currentProgressPosition?.nextMoveIds[0])

            const currentPosition = getPositionById(gameTree, currentPositionId)

            if (!expectedProgressMove) return false

            const isMoveCorrect =
                move.from === expectedProgressMove.move.from &&
                move.to === expectedProgressMove.move.to &&
                move.promotion === expectedProgressMove.move.promotion

            if (isMoveCorrect) {
                setProgressCurrentPositionId(expectedProgressMove.nextPositionId)
                const nextPosition = addMoveToGTPosition(gameTree, currentPosition, expectedProgressMove.move)
                setCurrentPositionId(nextPosition.id)

                const nextProgressPosition = getPositionById(puzzleGameTree, expectedProgressMove.nextPositionId)
                if (nextProgressPosition.nextMoveIds.length === 0) {
                    setHint(undefined)
                    setCurrentPuzzleState(true)
                } else {
                    setIsLastMoveCorrect(true)
                    opponentMoveTimeout.current = setTimeout(() => {
                        setIsBotMoving(true)
                        setIsLastMoveCorrect(false)
                        setWaitingForOpponentMove(true)
                    }, BOT_MOVING_DELAY)
                }

                return true
            }

            const nextPosition = addMoveToGTPosition(gameTree, currentPosition, move)
            setCurrentPositionId(nextPosition.id)

            setCurrentPuzzleState(false)
            return false
        },
        [gameTree, currentPositionId, setCurrentPositionId],
    )

    // ---------- end moves ---------- //

    // ---------- circle buttons ---------- //

    // show solution handler
    const onShowSolutionClick = () => {
        setDisableNextButton(true)
        setUsedShowSolution(true)
        setShowSolution(true)
    }

    // flip board handler
    const onFlipClick = () => {
        setFlipBoard(!flipped)
    }

    // hint handler
    const onHintClick = () => {
        if (!hint) {
            setHintsCounter(hintsCounter + 1)
            const currentProgressPosition = getPositionById(puzzleGameTree, progressCurrentPositionId)
            const expectedProgressMove = getMoveById(puzzleGameTree, currentProgressPosition?.nextMoveIds[0])
            if (expectedProgressMove && currentProgressPosition.position.turn === myColor) {
                const nextMove = nameOfSquare(expectedProgressMove?.move.from)
                setHint(nextMove as Square)
            }
        }
    }

    // open tags dialog window handler
    const onTagsClick = () => {
        setTagsOpen(true)
    }

    const onShowDescriptionClick = () => {
        setDescriptionOpen(true)
    }

    // ---------- end circle buttons ---------- //

    // ---------- dialogs ---------- //

    // apply tags changes handler
    const onTagsApply = (tagsIDs: number[]) => {
        setTagsIDs(tagsIDs)
        setTagsOpen(false)
    }

    // ---------- end dialogs ---------- //

    // ---------- analysis ---------- //

    // analysis handler
    const onAnalysisClick = () => {
        if (currentPuzzle) {
            // const pgn = convertGameTreeToPGN(gameTree)
            // let result = createPuzzlePGNString(currentPuzzle.fen, pgn)
            // navigate(`/analysis?pgn=${encodeURIComponent(result.replaceAll('"', '%22'))}`)
            setPGNHeader({
                Event: 'Puzzle from chessclub.com',
                Site: 'Internet Chess Club http://chessclub.com/',
                Date: format(new Date(), 'yyyy.MM.dd'),
                Time: format(new Date(), 'HH:mm:ss'),
                FEN: currentPuzzle.fen,
                Round: '-',
                White: myColor === Color.White ? userData?.user_name || '' : '',
                WhiteAvatarUrl: (myColor === Color.White && userData?.avatar_url) || '',
                WhiteTitle: (myColor === Color.White && userData?.title) || '',
                WhiteCountry: (myColor === Color.White && userData?.country) || '',
                Black: myColor === Color.Black ? userData?.user_name || '' : '',
                BlackAvatarUrl: (myColor === Color.Black && userData?.avatar_url) || '',
                BlackTitle: (myColor === Color.Black && userData?.title) || '',
                BlackCountry: (myColor === Color.Black && userData?.country) || '',
                Result: '*',
            })

            setAnalysisGameTree(gameTree)
            navigate('/analysis')
        }
    }

    // ---------- end analysis ---------- //

    // ---------- notation controls ---------- //

    // next and prev moves available
    const hasNextMoves = gtPos.nextMoveIds.length > 0
    const hasPrevMoves = gtPos.previousMoveId !== undefined

    // start position handler
    const onFirst = () => {
        setCurrentPositionId(getStartingPosition(gameTree).id)
    }

    // prev position handler
    const onPrev = () => {
        const previousPosition = getPreviousPosition(gameTree, currentPositionId)
        if (!previousPosition) return
        setCurrentPositionId(previousPosition.id)
    }

    // next position handler
    const onNext = () => {
        const nextPosition = getNextPosition(gameTree, currentPositionId)
        if (!nextPosition) return
        setCurrentPositionId(nextPosition.id)
    }

    // last position handler
    const onLast = () => {
        setCurrentPositionId(getLastPosition(gameTree).id)
    }

    // ---------- end notation controls ---------- //

    const isPlayerInteractedWithBoard = notations.length > 1 || attemptsCounter > 1

    const Header = useMemo(
        () => (
            <HeaderStripe
                leftChildren={
                    <PuzzleStreakCounter
                        value={streaks.current}
                        title="Current"
                        sync={streaks.current === streaks.best && streaks.current > 0}
                    />
                }
                rightChildren={
                    <PuzzleStreakCounter
                        value={streaks.best}
                        title="Best"
                        sync={streaks.current === streaks.best && streaks.current > 0}
                    />
                }
                bottomChildren={
                    !settings.friendlyMode && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            height="24px"
                            gap="15px"
                        >
                            <Typography
                                fontSize="24px"
                                justifyContent="center"
                                textAlign="center"
                                color="secondary"
                                variant="h6"
                                lineHeight="24px"
                            >
                                Puzzle rating
                            </Typography>
                            <PuzzleRating
                                wrapperStyles={{
                                    gap: '15px',
                                    alignItems: 'center',
                                    img: {
                                        pb: '2px',
                                        height: '24px !important',
                                        width: '22px !important',
                                    },
                                    '& p': {
                                        fontSize: '24px',
                                        lineHeight: '24px',
                                    },
                                    ' h6': {
                                        marginLeft: '0 !important',
                                    },
                                }}
                                userRating={userRating}
                            />
                        </Box>
                    )
                }
                title="YOUR STREAKS!"
            />
        ),
        [streaks, settings.friendlyMode],
    )

    const handleAnimationEnd = () => {
        setStreaks((prevStreaks) => {
            return {
                ...prevStreaks,
                current: 0,
                updatedAt: new Date().toISOString(),
            }
        })
        setShowStreak(false)
    }

    return (
        <>
            <AppLayout
                aboveBoard={layout === Layout.MOBILE ? Header : <></>}
                board={
                    <>
                        {showStreak && (
                            <PuzzleStreakOverlay
                                value={currentPuzzle?.streak.best || 0}
                                variant={StreakAnimation.PRACTICE}
                                onAnimationEnd={handleAnimationEnd}
                            />
                        )}

                        <PuzzlesChessBoard
                            mode="puzzle"
                            gameTree={gameTree}
                            currentPositionId={currentPositionId}
                            flipped={flipped}
                            onMove={onMove}
                            myColor={myColor}
                            hint={hint}
                            disableBoard={gtPos.position.turn !== myColor || showSolution}
                            moveStatus={currentPuzzleState === null ? undefined : currentPuzzleState}
                        />
                    </>
                }
            >
                {layout === Layout.DESKTOP && (
                    <>
                        {Header}
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                flexWrap: 'nowrap',
                                minHeight: '300px',
                                border: `.125rem solid ${palette.text.primary}`,
                                borderTopLeftRadius: '8px !important',
                                borderTopRightRadius: '8px !important',
                                backgroundColor: 'background.paper',
                                overflow: 'hidden',
                            }}
                        >
                            {Boolean(userRating) && (
                                <>
                                    <NotationHeader
                                        myColor={myColor}
                                        usedShowSolution={usedShowSolution}
                                        hint={Boolean(hint)}
                                        usedAttempts={attemptsCounter > 1}
                                        usedHint={Boolean(hintsCounter)}
                                        isBotMoving={isBotMoving}
                                        puzzleState={currentPuzzleState}
                                        isPuzzleStarted={isPuzzleStarted}
                                        isLastMoveCorrect={isLastMoveCorrect}
                                    />
                                    <Notations
                                        myColor={myColor}
                                        notations={notations}
                                        setNotations={setNotations}
                                        usedShowSolution={usedShowSolution}
                                        currentHint={hint}
                                        gameTree={gameTree}
                                        puzzleState={currentPuzzleState}
                                        currentPositionId={currentPositionId}
                                        setCurrentPositionId={setCurrentPositionId}
                                        disabled={currentPuzzleState === false}
                                    />
                                    <Description
                                        myColor={myColor}
                                        description={currentPuzzle?.description}
                                        puzzleGameTree={puzzleGameTree}
                                    />
                                </>
                            )}
                        </Grid>
                    </>
                )}
                {layout === Layout.MOBILE && (
                    <>
                        <NotationHeader
                            myColor={myColor}
                            usedShowSolution={usedShowSolution}
                            hint={Boolean(hint)}
                            usedAttempts={attemptsCounter > 1}
                            usedHint={Boolean(hintsCounter)}
                            isBotMoving={isBotMoving}
                            puzzleState={currentPuzzleState}
                            isPuzzleStarted={isPuzzleStarted}
                            isLastMoveCorrect={isLastMoveCorrect}
                        />
                        <PuzzleControls
                            welcomeDescOpen={welcomeDescOpen}
                            showDescriptionButton={true}
                            disableByState={currentPuzzleState !== null}
                            isLastPuzzle={lastPuzzle}
                            showSolutionDisabled={showSolution}
                            isInitialized={Boolean(notations.length)}
                            tagsBadge={tagsIDs.length}
                            onShowSolution={onShowSolutionClick}
                            onFlip={onFlipClick}
                            onHint={onHintClick}
                            onTags={onTagsClick}
                            onShowDescription={onShowDescriptionClick}
                        />
                        <PuzzleButtons
                            isPlayerInteractedWithBoard={isPlayerInteractedWithBoard}
                            disableNextButton={disableNextButton}
                            onAnalysis={onAnalysisClick}
                            onNext={onNextClick}
                        />
                        <Box sx={{ border: '0.125rem solid white', width: '100%', padding: '0.5rem' }}>
                            <Notations
                                myColor={myColor}
                                notations={notations}
                                setNotations={setNotations}
                                usedShowSolution={usedShowSolution}
                                currentHint={hint}
                                gameTree={gameTree}
                                puzzleState={currentPuzzleState}
                                currentPositionId={currentPositionId}
                                setCurrentPositionId={setCurrentPositionId}
                                disabled={currentPuzzleState === false}
                            />
                        </Box>

                        {/* DIALOGS */}

                        <NotificationDialog
                            handleClose={() => {
                                setWelcomeDescOpen(false)
                            }}
                            open={welcomeDescOpen}
                            header="description"
                            description="Each puzzle comes with its own unique brief, offering a helpful hint to guide you. If you prefer a greater challenge, you can easily toggle them off."
                        >
                            <Typography align="center" mt="2rem" variant="body1" color="secondary">
                                Use the brief button to open the puzzle description
                            </Typography>
                        </NotificationDialog>
                        <Description
                            dialog={{
                                open: descriptionOpen,
                                handleClose: () => {
                                    setDescriptionOpen(false)
                                },
                            }}
                            myColor={myColor}
                            puzzleGameTree={puzzleGameTree}
                            description={currentPuzzle?.description}
                        />
                    </>
                )}
                <NotationControls
                    vertical={layout === Layout.MOBILE}
                    commonDisabled={notations.length <= 1 || (usedShowSolution && currentPuzzleState === null)}
                    hasNextMoves={hasNextMoves}
                    hasPrevMoves={hasPrevMoves}
                    onFirstMove={onFirst}
                    onPrevMove={onPrev}
                    onNextMove={onNext}
                    onLastMove={onLast}
                />
                {layout === Layout.DESKTOP && (
                    <>
                        <PuzzleControls
                            showDescriptionButton={false}
                            disableByState={currentPuzzleState !== null}
                            isLastPuzzle={lastPuzzle}
                            showSolutionDisabled={showSolution}
                            tagsBadge={tagsIDs.length}
                            isInitialized={Boolean(notations.length)}
                            onShowSolution={onShowSolutionClick}
                            onFlip={onFlipClick}
                            onHint={onHintClick}
                            onTags={onTagsClick}
                            onShowDescription={onShowDescriptionClick}
                        />
                        <PuzzleButtons
                            isPlayerInteractedWithBoard={isPlayerInteractedWithBoard}
                            disableNextButton={disableNextButton}
                            onAnalysis={onAnalysisClick}
                            onNext={onNextClick}
                        />
                    </>
                )}

                {/* {welcomeOpen && (
                    <Suspense>
                        <WelcomeDialog
                            screens={[
                                {
                                    title: { top: 'welcome', middle: 'TO', bottom: 'puzzle practice' },
                                    imageOrAnimation: '/src/assets/animations/puzzles/puzzle-practice-welcome-1.lottie',
                                    backgroundImage: PuzzlePracticeImageWelcomeBackground1,
                                },
                                {
                                    title: { top: 'learn', middle: 'with', bottom: 'assistive features' },
                                    imageOrAnimation: PuzzlePracticeImageWelcome2,
                                    hint:
                                        layout === Layout.DESKTOP
                                            ? 'Strategically use hints and solutions to improve your skills and enhance your chess game while gaining deeper insights into the game.'
                                            : 'Utilize hints and solutions to enhance your skills',
                                },
                            ]}
                            actionText="let's start"
                            open={welcomeOpen}
                            onClose={() => {
                                setWelcomeOpen(false)
                                layout === Layout.MOBILE && setWelcomeDescOpen(true)
                            }}
                        />
                    </Suspense>
                )} */}
                {morePuzzlesOpen && <PuzzlesLimitDialog variant="puzzlePracticePremium" open={morePuzzlesOpen} />}
                {joinUsOpen && <PuzzlesLimitDialog variant="puzzlePracticeMember" open={joinUsOpen} />}
                <PuzzleTags
                    open={tagsOpen}
                    currentTags={tagsIDs}
                    onClose={() => {
                        setTagsOpen(false)
                    }}
                    onApply={onTagsApply}
                />
            </AppLayout>
        </>
    )
}

PuzzlesTrainingViewV2.displayName = 'PuzzlesTrainingView'

export default PuzzlesTrainingViewV2
