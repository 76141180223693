export const API_URL = import.meta.env.VITE_REACT_APP_AMS_URL
export const FORUMS_URL = import.meta.env.VITE_REACT_APP_FORUMS_URL
export const LEARNING_URL = import.meta.env.VITE_REACT_APP_LEARNING_URL
export const PLAY_AREA_URL = import.meta.env.VITE_REACT_APP_PLAYAREA_URL
export const WEBSITE_URL = import.meta.env.VITE_REACT_APP_WEBSITE_URL
export const SHOP_URL = import.meta.env.VITE_REACT_APP_SHOP_URL
export const ENV = import.meta.env.VITE_REACT_APP_ENV
export const GA_GAME_KEY = import.meta.env.VITE_REACT_APP_GAME_KEY
export const GA_SECRET_KEY = import.meta.env.VITE_REACT_APP_SECRET_KEY
export const GOOGLE_ANALYTICS_ID = import.meta.env.VITE_REACT_APP_GOOGLE_ANALYTICS_ID
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID
export const FACEBOOK_APP_ID = import.meta.env.VITE_REACT_APP_FACEBOOK_APP_ID
export const FACEBOOK_API_VERSION = import.meta.env.VITE_REACT_APP_FACEBOOK_API_VERSION
export const APPLE_CLIENT_ID = import.meta.env.VITE_REACT_APP_APPLE_CLIENT_ID
export const APPLE_REDIRECT_URL = import.meta.env.VITE_REACT_APP_APPLE_REDIRECT_URL
export const IMAGES_CDN_URL = import.meta.env.VITE_REACT_APP_IMAGES_CDN_URL
export const WS_GATEWAY_URL = import.meta.env.VITE_REACT_APP_WS_GATEWAY
