import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CustomTimeSelector } from '../../components/CustomTimeSelector'
import { ColorSelection, ColorSelector } from '../../components/colorSelector/ColorSelector'
import { NewChallengeSelector } from '../../components/newChallengeSelector/NewChallengeSelector'
import { UserSearchResult } from '../../sharedComponents/src/globalHeader/functions/ams-functions'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { MMChallengeType } from '../../store/types'

interface IDirectChallengeFields {
    selectedChallenge?: string
    rated?: boolean
    color?: ColorSelection
    customTime?: boolean
    minutes?: number
    increment?: number
}

export type DirectChallengeDrawerProps = {
    onClose: () => void
    user: UserSearchResult | undefined
    setActiveMainTab: (tab: number) => void
}

function DirectChallengeDrawer(props: DirectChallengeDrawerProps) {
    const { user, onClose } = props

    const directChallenge = useStoreState((state) => state.matchMaker.searchOptions.directChallenge)
    const selectedChallenge = useStoreState((state) => state.matchMaker.searchOptions.directChallenge.selectedChallenge)
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions)
    const settings = useStoreState((state) => state.gameView.settings)
    const userData = useStoreState((state) => state.userData.userData)

    const setActiveTab = useStoreActions((state) => state.setActiveTab)
    const sendPlayerChallenge = useStoreActions((state) => state.matchMaker.sendPlayerChallenge)
    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)

    const [playDisabled, setPlayDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (directChallenge.minutes > 0 && directChallenge.increment >= 0) {
            setPlayDisabled(false)
        } else {
            setPlayDisabled(true)
        }
    }, [directChallenge.minutes, directChallenge.increment])

    const onCreateChallenge = () => {
        sendPlayerChallenge({
            rated: directChallenge.rated,
            color: directChallenge.color,
            time: directChallenge.minutes,
            increment: directChallenge.increment,
            challengeeId: user?.id,
            challengeType: MMChallengeType.DIRECT,
        })
        analyticsManager.dispatchEvent('directChallenge', {
            minutes: directChallenge.minutes,
            increment: directChallenge.increment,
            color: directChallenge.color === 'no_color' ? 'noColor' : directChallenge.color,
            rated: directChallenge.rated,
            userID: userData?.id || '',
            opponentID: user?.id || '',
        })
        onClose()
    }

    const onUpdateDirectChallenge = (fields: IDirectChallengeFields) => {
        setSearchOptions({
            ...searchOptions,
            directChallenge: {
                ...directChallenge,
                ...fields,
            },
        })
    }

    const challengeButtons = [
        '1,0,bullet',
        '3,0,blitz',
        '3,2,blitz',
        '5,0,blitz',
        '5,3,blitz',
        '10,0,rapid',
        '15,10,rapid',
        '30,0,rapid',
    ]

    return (
        <>
            <Box display="flex">
                <Typography sx={{ whiteSpace: 'nowrap', marginRight: 1 }} variant={'h4'}>
                    VS
                </Typography>
                <Typography color="secondary.main" noWrap textTransform="none" variant={'h4'}>
                    {user?.userName}
                </Typography>
            </Box>
            <Box
                p={0}
                style={{
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {!directChallenge.customTime && (
                    <NewChallengeSelector
                        headerTitle="Time control"
                        launchedChallenges={selectedChallenge != null ? [selectedChallenge] : []}
                        onSelected={(challenge) => {
                            const parsedData = challenge.split(',')
                            let minutes = 0
                            let increment = 0
                            if (parsedData.length > 1) {
                                minutes = Number(parsedData[0])
                                increment = Number(parsedData[1])
                            }
                            onUpdateDirectChallenge({
                                minutes: minutes,
                                increment: increment,
                                selectedChallenge: challenge,
                            })
                        }}
                        vsHuman={false}
                        buttons={challengeButtons}
                        handleCustomButton={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onUpdateDirectChallenge({
                                customTime: true,
                            })
                        }}
                    />
                )}
                {directChallenge.customTime && (
                    <Box>
                        <CustomTimeSelector
                            time={directChallenge.minutes}
                            increment={directChallenge.increment}
                            setTime={(time) =>
                                onUpdateDirectChallenge({
                                    minutes: time,
                                })
                            }
                            setIncrement={(increment) =>
                                onUpdateDirectChallenge({
                                    increment: increment,
                                })
                            }
                        />
                    </Box>
                )}

                {!settings.friendlyMode && (
                    <FormControlLabel
                        sx={{ margin: 0, justifyContent: 'space-between' }}
                        control={<Checkbox color="info" />}
                        label="Rated Game"
                        labelPlacement="start"
                        onChange={(e: any) =>
                            onUpdateDirectChallenge({
                                rated: e.target.checked,
                            })
                        }
                        checked={directChallenge.rated}
                    />
                )}

                <ColorSelector
                    value={directChallenge.color}
                    onChange={(color) =>
                        onUpdateDirectChallenge({
                            color: color,
                        })
                    }
                />
            </Box>

            {directChallenge.customTime && (
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() =>
                        onUpdateDirectChallenge({
                            customTime: false,
                            selectedChallenge: '3,0,blitz',
                            minutes: 3,
                            increment: 0,
                        })
                    }
                >
                    Back to presets
                </Button>
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '3.125rem',
                    backgroundColor: '#101820',
                    outline: '1.25rem solid #101820',
                    flex: '0 1 3.125rem',
                }}
                gap={1}
            >
                <Button variant="outlined" fullWidth onClick={onClose}>
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        onCreateChallenge()
                        onClose()
                        setActiveTab(0)
                    }}
                    disabled={playDisabled}
                >
                    CREATE
                </Button>
            </Box>
        </>
    )
}

export default DirectChallengeDrawer
