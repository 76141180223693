import { Typography, useTheme } from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { getClockInstantCurrentRemaining } from '../../../../functions/getClockInstantCurrentRemaining'
import toPrecision from '../../../../functions/toPrecision'
import { colors } from '../../../../sharedComponents/src/globalHeader/theme/colors'
import { useStoreState } from '../../../../store/hooks'
import { ClockInstant } from '../../../../store/types'
import useGameSounds from '../../../chessboard/hooks/useGameSounds'
import { GameViewState } from '../../GameViewModel'
export type ClockProps = {
    onAddTime?: () => void
    extraClass?: string
    running: boolean
    instant?: ClockInstant
    suffix?: string
    callbackOnEnd?: () => void
    isMine?: boolean
}

const calculateClock = (instant: ClockInstant | undefined, running: boolean) => {
    const remaining = running ? getClockInstantCurrentRemaining(instant) : instant?.secondsRemaining || 0
    const rounded = remaining > 20 ? Math.round(remaining) : toPrecision(remaining, 1)

    return rounded
}

export const Clock: React.FC<ClockProps> = ({
    onAddTime,
    extraClass,
    running,
    instant,
    suffix,
    callbackOnEnd,
    isMine = false,
}) => {
    const { palette } = useTheme()

    const settings = useStoreState((state) => state.gameView.settings)
    const gameState = useStoreState((state) => state.gameView.gameState)

    const [clock, setClock] = React.useState<number>(calculateClock(instant, running))
    const [soundPlayed, setSoundPlayed] = React.useState<boolean>(false)

    const requestRef = React.useRef<number>()
    const timeoutRef = React.useRef<NodeJS.Timeout>()

    const { playTimeLeftSound } = useGameSounds(settings)

    useEffect(() => {
        const updateClock = () => {
            setClock(calculateClock(instant, running))
            requestRef.current = requestAnimationFrame(updateClock)
        }

        requestRef.current = requestAnimationFrame(updateClock)

        return () => {
            if (requestRef.current) cancelAnimationFrame(requestRef.current)
        }
    }, [running, instant])

    // TODO: DELETE WHEN WE WILL HAVE NORMAL TIMER
    useEffect(() => {
        const time = instant?.secondsRemaining || 0
        if (running && settings.soundOn && isMine && time > 20 && !soundPlayed) {
            const executeTime = (time - 20) * 1000
            timeoutRef.current = setTimeout(() => {
                playTimeLeftSound()
                setSoundPlayed(true)
            }, executeTime)
        }

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [running, instant, settings.soundOn, isMine, soundPlayed])

    useEffect(() => {
        if (gameState !== GameViewState.GAME_RUNNING) {
            setSoundPlayed(false)
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        }
    }, [gameState])

    useEffect(() => {
        if (!clock && callbackOnEnd) {
            callbackOnEnd()
        }
    }, [clock, callbackOnEnd])

    const warn = clock !== 0 && clock <= 20

    let clockString = format((clock + new Date(0).getTimezoneOffset() * 60) * 1000, 'H:mm:ss ')
    if (clock < 3600 && clock > 20) clockString = format((clock + new Date(0).getTimezoneOffset() * 60) * 1000, 'm:ss ')
    else if (clock <= 20) clockString = format((clock + new Date(0).getTimezoneOffset() * 60) * 1000, 'm:ss:S')

    return (
        <Typography
            sx={{
                overflow: 'hidden',
                fontVariantNumeric: 'tabular-nums',
                fontFamily: 'Space Grotesk',
                fontWeight: '700',
                fontSize: '32px',
                lineHeight: '32px',
                color: warn ? colors.red : palette.text.primary,
                margin: 0,
            }}
        >
            {clockString} {suffix && <span>{suffix}</span>}
        </Typography>
    )
}
