import { Box } from '@mui/material'
import { UserSearchResult } from '../../sharedComponents/src/globalHeader/functions/ams-functions'
import { ChallengeEntry } from '../../store/types'
import ProfileInfo from '../ProfileInfo/ProfileInfo'
import { ChallengeCard, RatingType } from '../matchmaking/ChallengeCard'

type Props = {
    user: UserSearchResult
    onChallengeClick: () => void
    filter: string
}

export const UserResult = ({ user, onChallengeClick, filter }: Props) => {
    const timeModeName = ['bullet', 'blitz', 'rapid', 'classic'].find((mode) => mode === filter) || 'blitz'

    const player: ChallengeEntry['challenger'] = {
        username: user.userName,
        rating: user.ratings[timeModeName].rating,
        is_provisional: user.ratings[timeModeName].is_provisional,
        nationality: user.country || 'WORLD',
        title: user.title,
        avatarUrl: user.avatarUrl,
        botdRewardUrl: user.botdRewardUrl,
        reward: {
            tier: user.reward?.tier,
            season: user.reward?.season,
        },
    }

    return (
        <Box sx={{ padding: '0 16px' }}>
            <ChallengeCard
                isOnline={user.status === 'online'}
                actionDisabled={user.status === 'offline'}
                avatarWrapper={(avatar) => (
                    <ProfileInfo user={user} showOnHover={false} onStartChallenge={onChallengeClick}>
                        <Box sx={{ position: 'relative', cursor: 'help' }}>
                            {avatar}
                            {/* <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: '-2px',
                                    right: '-2px',
                                    zIndex: 30,
                                    border: '1px solid white !important',
                                    borderRadius: '100px',
                                }}
                            >
                                <StatusIndicator status={user.status} />
                            </Box> */}
                        </Box>
                    </ProfileInfo>
                )}
                handleAction={onChallengeClick}
                type="player"
                player={player}
                ratingType={timeModeName as RatingType}
            />
        </Box>
    )
}
