export enum ProfilePages {
    DASHBOARD = 'dashboard',
    SETTINGS = 'settings',
    ACCOUNT = 'account',
}

export enum GameHistoryTypes {
    all = 'all',
    bullet = 'bullet',
    blitz = 'blitz',
    rapid = 'rapid',
    classic = 'classic',
}

export enum SortDirection {
    asc = 'asc',
    desc = 'desc',
}

export enum GameHistoryColumns {
    game = 'game',
    date = 'date',
    white = 'white',
    black = 'black',
    result = 'result',
    opponent = 'opponent',
}

export const GAME_HISTORY_COLUMNS = [
    {
        id: GameHistoryColumns.game,
        name: 'Game',
        sortable: true,
        width: '20%',
    },
    {
        id: GameHistoryColumns.date,
        name: 'Date',
        sortable: true,
        width: '20%',
    },
    {
        id: GameHistoryColumns.white,
        name: 'White',
        sortable: false,
        width: '20%',
    },
    {
        id: GameHistoryColumns.black,
        name: 'Black',
        sortable: false,
        width: '20%',
    },
    {
        id: GameHistoryColumns.result,
        name: 'Result',
        sortable: false,
        width: '20%',
    },
]

export const GAME_HISTORY_PHONE_COLUMNS = [
    {
        id: GameHistoryColumns.game,
        name: 'Game',
        sortable: true,
        width: '20%',
    },
    {
        id: GameHistoryColumns.opponent,
        name: 'Opponent',
        sortable: false,
        width: '58%',
    },
    {
        id: GameHistoryColumns.result,
        name: 'Result',
        sortable: false,
        width: '22%',
    },
]