import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const PuzzleQuestNavigation = () => {
    const navigate = useNavigate()

    return (
        <Box display="flex" gap={1.5} justifyContent="center">
            <Button onClick={() => navigate('/puzzles')}>More Puzzles</Button>
            <Button onClick={() => navigate('/daily-puzzle')}>Daily Puzzle</Button>
        </Box>
    )
}
