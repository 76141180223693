export type OpenSignupEvent = {
    origin: 'header' | 'welcomeScreen' | 'loginDialog' | 'guestWelcome' | 'guestProfileDropdown' | 'puzzleLimit'
}

export type OpenLoginEvent = {
    origin: 'header' | 'welcomeScreen' | 'signupDialog' | 'guestWelcome' | 'guestProfileDropdown' | 'puzzleLimit'
}

export type CompleteSignupEvent = {
    country: string
    authType: 'email/user_name' | 'google' | 'apple' | 'facebook' | 'guest'
    userId?: string
    userName?: string
}

export type CompleteLoginEvent = { authType: 'email/user_name' | 'google' | 'apple' | 'facebook', userId: string, userName: string }

export type LogoutEvent = {}

export type CustomChallengeEvent = {
    minutes: number // this or challengeString?
    increment: number
    rated: boolean
    color: 'white' | 'black' | 'noColor'
    ratingFrom: number
    ratingTo: number
}

export type BotChallengeEvent = {
    minutes: number
    increment: number
    difficulty: number
    color: 'white' | 'black' | 'noColor'
    startingFEN: string
    botName: string
    botId: string
}

export type EnterAutoPoolEvent = {
    gameType: 'bullet' | 'blitz' | 'rapid' | 'classic'
    minutes: number
    increment: number
    challengeID: string
}

export type ExitAutoPoolEvent = {
    gameType: 'bullet' | 'blitz' | 'rapid' | 'classic'
    minutes: number
    increment: number
    challengeID: string
}

export type GameStartedEvent = {
    matchKind: 'human_vs_human' | 'human_vs_ai'
    minutes: number
    increment: number
    rated: boolean
    timeInQueue: number
    player1ID: string
    player2ID: string
    player1Color: 'white' | 'black'
    player2Color: 'white' | 'black'
    matchID: string
}

export type ResignEvent = {
    minutes: number
    increment: number
    color: 'white' | 'black'
    rated: boolean
    opponentID: string
    matchID: string
}

export type GameOfferSentEvent = {
    type: 'draw' | 'abort'
    minutes: number
    increment: number
    color: 'white' | 'black' | 'noColor'
    rated: boolean
    opponentID: string
    matchID: string
}

export type GameOfferResultEvent = {
    type: 'draw' | 'abort'
    result: 'accepted' | 'rejected'
    minutes: number
    increment: number
    color: 'white' | 'black' | 'noColor'
    rated: boolean
    opponentID: string
    matchID: string
}

export type OfferRematchEvent = {
    minutes: number
    increment: number
    color: 'white' | 'black' | 'noColor'
    rated: boolean
    opponentID: string
    parentMatchID: string
}

export type OfferRematchResultEvent = {
    result: 'accepted' | 'rejected'
    parentMatchID: string
}

export type GameFinishedEvent = {
    matchKind: 'human_vs_human' | 'human_vs_ai'
    minutes: number
    increment: number
    method: string
    reason: string
    rated: boolean
    player1ID: string
    player2ID: string
    player1Color: 'white' | 'black'
    player2Color: 'white' | 'black'
    whiteClockLeft: number
    blackClockLeft: number
    matchID: string
    timeInGame: number
    rating: number
}

export type ClickVideoEvent = {
    videoUrl: string
}

export type CompletedPuzzleEvent = {
    origin: 'puzzleQuest' | 'dailyPuzzle' | 'practicePuzzle'
    result: 'solution_showed' | 'passed' | 'skipped' | 'failed'
    attempts: number
    usedHints: number
    puzzleSource: number
    userPuzzleRating: number
    puzzleId: string
    puzzleRating: number
}

export type RewardEvent = {
    type: 'frame' | 'crown'
    tier: number
    amount: number
    expirationDate: string
}

export type DirectChallengeEvent = {
    minutes: number
    increment: number
    color: 'white' | 'black' | 'noColor'
    rated: boolean
    userID: string
    opponentID: string
}

export type EmailActivatedEvent = {}

export type AnalyticsEventMap = {
    botChallenge: BotChallengeEvent
    completeLogin: CompleteLoginEvent
    completeSignup: CompleteSignupEvent
    openSignup: OpenSignupEvent
    clickVideo: ClickVideoEvent
    logout: LogoutEvent
    gameOfferSent: GameOfferSentEvent
    gameOfferResult: GameOfferResultEvent
    openLogin: OpenLoginEvent
    exitAutoPool: ExitAutoPoolEvent
    customChallenge: CustomChallengeEvent
    offerRematch: OfferRematchEvent
    resign: ResignEvent
    completedPuzzle: CompletedPuzzleEvent
    gameFinished: GameFinishedEvent
    enterAutoPool: EnterAutoPoolEvent
    gameStarted: GameStartedEvent
    reward: RewardEvent
    directChallenge: DirectChallengeEvent
    offerRematchResult: OfferRematchResultEvent
    emailActivated: EmailActivatedEvent
}

export type EventListenerFunction<K extends keyof AnalyticsEventMap> = (ev: AnalyticsEventMap[K]) => any

export type EventListeners = {
    [key in keyof AnalyticsEventMap]?: EventListenerFunction<key>[]
}

export interface AnalyticsManagerInterface {
    markAsLoaded(): void

    addEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void

    removeEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void

    dispatchEvent<K extends keyof AnalyticsEventMap>(type: K, ev: AnalyticsEventMap[K]): void
}

export class AnalyticsManager implements AnalyticsManagerInterface {
    private eventListeners: EventListeners
    private loading: Promise<void>
    private resolveLoading!: () => void

    constructor() {
        this.eventListeners = {}
        this.loading = new Promise((resolve) => {
            this.resolveLoading = resolve
        })
    }

    markAsLoaded(): void {
        this.resolveLoading()
    }

    addEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void {
        if (!this.eventListeners[type]) {
            this.eventListeners[type] = []
        }

        this.eventListeners[type]!.push(listener)
    }

    removeEventListener<K extends keyof AnalyticsEventMap>(type: K, listener: EventListenerFunction<K>): void {
        const listeners = this.eventListeners[type]
        if (!listeners) {
            return
        }

        this.eventListeners[type] = listeners.filter((l) => l !== listener) as typeof listeners
    }

    async dispatchEvent<K extends keyof AnalyticsEventMap>(type: K, ev: AnalyticsEventMap[K]): Promise<void> {
        await this.loading
        this.eventListeners[type]?.forEach((listener) => {
            try {
                listener(ev)
            } catch (e) {
                console.error(`Error occurred in listener for event type '${type}':`, e)
            }
        })
    }
}

export const analyticsManager = new AnalyticsManager()