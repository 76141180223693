import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material'
import { colors } from '../../../../sharedComponents/src/globalHeader/theme/colors'

export enum ConfirmationType {
    RESIGN = 'resign',
    OFFER_DRAW = 'offerDraw',
    ACCEPT_DRAW = 'acceptDraw',
    ASK_FOR_ABORT = 'askForAbort',
    ACCEPT_REMATCH = 'acceptRematch',
}

export type ConfirmationDialogsProps = {
    type: ConfirmationType
    onConfirm: (type: ConfirmationType) => void
    onClose: (type: ConfirmationType) => void
    rematchUser?: string
}

type DialogData = {
    title: string
    message?: string
    yesButtonLabel: string
    noButtonLabel: string
    yesIsCritical: boolean
}

const dialogStyle = {
    border: `0.1875rem solid ${colors.white}`,
    width: '35rem',
    height: 'fit-content',
    position: 'relative',
    padding: '1rem',
}
const dialogTitleStyle = {
    fontFamily: 'Fira Sans',
    fontSize: '38px',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '0',
    position: 'relative',
}
const dialogTextStyle = {
    fontSize: '1.3rem',
    textAlign: 'center',
    padding: '0',
}
const dialogActionsStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem',
}

const closeIconStyle = {
    color: colors.white,
    backgroundColor: 'transparent',
    zIndex: '1001',
    minWidth: '3.125rem',
    width: '3.125rem',
    height: '3.125rem',
}
const closeButtonStyle = {
    color: colors.white,
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    padding: '0',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}

function ConfirmationDialogs(props: ConfirmationDialogsProps) {
    const { type, onConfirm, onClose, rematchUser } = props

    const dialogData: Record<ConfirmationType, DialogData> = {
        [ConfirmationType.RESIGN]: {
            title: 'Resign game',
            message: 'Are you sure you want to resign?',
            yesButtonLabel: 'Yes',
            noButtonLabel: 'No',
            yesIsCritical: true,
        },
        [ConfirmationType.OFFER_DRAW]: {
            title: 'Offer draw',
            message: 'Are you sure you want to send a Draw request?',
            yesButtonLabel: 'Yes',
            noButtonLabel: 'No',
            yesIsCritical: true,
        },
        [ConfirmationType.ACCEPT_DRAW]: {
            title: 'Draw offer',
            message: 'You are asked to finish the game in a Draw.',
            yesButtonLabel: 'Reject',
            noButtonLabel: 'Accept',
            yesIsCritical: false,
        },
        [ConfirmationType.ASK_FOR_ABORT]: {
            title: 'Abort game',
            message: 'Are you sure you want to abort the game?',
            yesButtonLabel: 'Yes',
            noButtonLabel: 'No',
            yesIsCritical: true,
        },
        [ConfirmationType.ACCEPT_REMATCH]: {
            title: 'Rematch',
            message: `${rematchUser} is asking for a rematch.`,
            yesButtonLabel: 'Reject',
            noButtonLabel: 'Accept',
            yesIsCritical: false,
        },
    }

    const dd = dialogData[type]

    return (
        <Dialog
            open={true}
            sx={{ display: 'block !important' }}
            onClose={(e: React.SyntheticEvent, reason: string) => (reason === 'backdropClick' ? onClose(type) : null)}
        >
            <Box sx={dialogStyle}>
                <Box>
                    <IconButton sx={closeButtonStyle} onClick={() => onClose(type)}>
                        <CloseIcon sx={closeIconStyle} />
                    </IconButton>
                    <DialogTitle sx={dialogTitleStyle}>{dd.title}</DialogTitle>
                </Box>
                <DialogContent>
                    <DialogContentText sx={dialogTextStyle}>{dd.message}</DialogContentText>
                </DialogContent>
                <DialogActions sx={dialogActionsStyle}>
                    <Button
                        variant={dd.yesIsCritical ? 'outlined' : 'contained'}
                        onClick={() => (dd.yesIsCritical ? onClose(type) : onConfirm(type))}
                    >
                        {dd.noButtonLabel}
                    </Button>
                    <Button
                        variant={dd.yesIsCritical ? 'contained' : 'outlined'}
                        onClick={() => (dd.yesIsCritical ? onConfirm(type) : onClose(type))}
                    >
                        {dd.yesButtonLabel}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ConfirmationDialogs
