import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Variant } from '@mui/material/styles/createTypography'
import React from 'react'
import HelpTooltip from '../../sharedComponents/src/globalHeader/components/HelpTooltip'
import { useStoreState } from '../../store/hooks'
import { RatingObject } from '../../store/types'
import { Blitz } from '../challengesList/gameTypeIcons/Blitz'
import { Bullet } from '../challengesList/gameTypeIcons/Bullet'
import { Classical } from '../challengesList/gameTypeIcons/Classical'
import { Rapid } from '../challengesList/gameTypeIcons/Rapid'
import { RatingType } from '../matchmaking/ChallengeCard'

type IconSizeType = 'small' | 'medium'

interface IRatingIconProps {
    type: RatingType
    iconSize: IconSizeType
}

const RatingIcon: React.FC<IRatingIconProps> = ({ type, iconSize }) => {
    switch (type) {
        case 'bullet':
            return <Bullet />
        case 'blitz':
            return <Blitz />
        case 'rapid':
            return <Rapid />
        case 'classic':
            return <Classical />
        default:
            return null
    }
}

RatingIcon.displayName = 'RatingIcon'

interface IProps {
    data?: number | string | RatingObject
    textSize?: Variant
    iconSize?: IconSizeType
    usePadding?: boolean
    type?: RatingType
    isProvisional?: boolean
}

const Rating: React.FC<IProps> = (props) => {
    const { data, textSize = 'body2', iconSize = 'small', usePadding = false, type, isProvisional = false } = props
    const settings = useStoreState((state) => state.gameView.settings)

    const isObject = typeof data === 'object'
    const isString = typeof data === 'string'
    const gameType = type ? type : isObject ? data.game_type : undefined
    const isProvisionalRating = isProvisional === true || (isObject && data?.is_provisional === true)

    if (data === undefined || data === null) {
        return null
    }

    if (!settings.friendlyMode) {
        return (
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center',
                    py: usePadding ? '4px' : 0,
                    px: usePadding ? '8px' : 0,
                    mx: usePadding ? '.35rem' : 0,
                    backgroundColor: usePadding ? '#111921' : 'transparent',
                    borderRadius: usePadding ? '8px' : 0,
                    marginRight: isProvisionalRating ? '8px' : 0,
                    gap: 0.5,
                }}
            >
                {gameType && (
                    <Typography
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: '1',

                            '& svg': {
                                fill: theme.palette.secondary.main,
                                width: iconSize === 'small' ? '16px' : '20px',
                                height: iconSize === 'small' ? '16px' : '20px',
                            },
                        })}
                    >
                        <RatingIcon iconSize={iconSize} type={gameType} />
                    </Typography>
                )}
                <HelpTooltip
                    title={'Provisional Rating'}
                    hideTooltip={isProvisionalRating !== true}
                    placement="bottom-end"
                    iconStyle={{ right: '-10px', top: '-7px' }}
                >
                    <Typography
                        sx={{
                            pr: isProvisionalRating !== true ? 0 : 0.5,
                            fontFamily: 'Space Grotesk',
                            fontWeight: 700,
                            fontVariantNumeric: 'tabular-nums',
                        }}
                        variant={textSize}
                        noWrap
                        overflow="unset"
                    >
                        {isObject ? data.rating : isString ? data : Math.round(data)}
                    </Typography>
                </HelpTooltip>
            </Stack>
        )
    }

    return null
}

Rating.displayName = 'Rating'

export default Rating
