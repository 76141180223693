import SettingsIcon from '@mui/icons-material/Settings'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Collapse,
    FormControlLabel,
    Paper,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isCheckmate, isStalemate } from '../../../../chess/core'
import { positionFromFEN } from '../../../../chess/fen'
import { Move } from '../../../../chess/types'
import { saveUserSettings } from '../../../../sharedComponents/src/globalHeader/functions/ams-functions'
import { Settings } from '../../../../sharedComponents/src/globalHeader/GlobalHeader'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { StockfishLine } from '../../useStockfish/StockfishLine'
import { StockEngineResultType } from '../../useStockfish/useStockFish'

type Props = {
    isEngineLoaded: boolean
    startEngine: () => void
    stopEngine: () => void
    allVariations: StockEngineResultType
    addEngineMove?: (move: Move | Move[]) => void
    colorToMove: number
    isCalculating: boolean
}

const EngineSection: React.FC<Props> = ({
    isEngineLoaded,
    startEngine,
    stopEngine,
    allVariations,
    addEngineMove,
    colorToMove,
    isCalculating,
}) => {
    const userData = useStoreState((state) => state.userData)
    const settings = useStoreState((state) => state.gameView.settings)

    const setSettings = useStoreActions((state) => state.gameView.setSettings)
    const setUserData = useStoreActions((state) => state.setUserData)

    const [finishText, setFinishText] = useState<string>('')
    const [settingsVisibility, setSettingsVisibility] = useState<boolean>(false)

    useEffect(() => {
        if (isCalculating === false) {
            const checkMated = Object.values(allVariations).every((variation) => !Boolean(variation.scoreType))
            if (checkMated) {
                const position = positionFromFEN(allVariations.v1.startFen)
                const isMate = isCheckmate(position)
                if (isMate) {
                    setFinishText(`${colorToMove === 1 ? 'White' : 'Black'} Checkmated`)
                } else {
                    const isStale = isStalemate(position)
                    if (isStale) {
                        setFinishText(`${colorToMove === 1 ? 'White' : 'Black'} Stalemated`)
                    }
                }
            } else {
                setFinishText('')
            }
        } else {
            setFinishText('')
        }
    }, [allVariations, isCalculating])

    useEffect(() => {
        if (!isEngineLoaded && (settings.engineToggle || settings.evaluationBar)) {
            startEngine()
        } else if (isEngineLoaded && !settings.engineToggle && !settings.evaluationBar) {
            stopEngine()
        }
    }, [settings.engineToggle, settings.evaluationBar])

    const onEngineChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        event.stopPropagation()
        event.preventDefault()
        saveEngineSettings({ engineToggle: checked })
    }

    const onEvalBarChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        saveEngineSettings({ evaluationBar: checked })
    }

    const saveEngineSettings = async (partialSettings: Partial<Settings>) => {
        if (userData.userData) {
            const updatedSettings = { ...settings, ...partialSettings }
            setUserData({ ...userData, userData: { ...userData.userData, game_settings: updatedSettings } })
            setSettings(updatedSettings)
            await saveUserSettings(updatedSettings)
        }
    }

    return (
        <>
            <Accordion
                sx={{
                    py: 0,
                    px: 1.5,
                    m: 0,
                }}
                expanded={settingsVisibility}
                onChange={(event, expanded) => setSettingsVisibility(expanded)}
            >
                <AccordionSummary
                    sx={{
                        p: 0,
                        pl: 1,
                        minHeight: 'auto',
                        '& .MuiAccordionSummary-content': {
                            m: 0,
                            '&.Mui-expanded': {
                                m: 0,
                            },
                        },
                        '&.Mui-expanded': {
                            minHeight: 'auto',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            backgroundColor: settingsVisibility ? 'text.primary' : 'background.paper',
                            p: 0.5,
                            transition: 'background-color 150ms',
                        },
                    }}
                    expandIcon={
                        <Tooltip title="Analysis settings" placement="top">
                            <SettingsIcon
                                sx={{
                                    color: settingsVisibility ? 'background.default' : 'text.primary',
                                    transition: 'color 150ms, background-color 150ms',
                                }}
                            />
                        </Tooltip>
                    }
                >
                    <Box onClick={(event) => event.stopPropagation()}>
                        <FormControlLabel
                            labelPlacement="end"
                            control={
                                <Switch color="secondary" checked={settings.engineToggle} onChange={onEngineChange} />
                            }
                            label="Engine"
                        />
                    </Box>
                </AccordionSummary>

                <AccordionDetails sx={{ px: 1, py: 0, m: 0 }}>
                    <FormControlLabel
                        labelPlacement="end"
                        control={
                            <Switch color="secondary" checked={settings.evaluationBar} onChange={onEvalBarChange} />
                        }
                        label="Evaluation Bar"
                    />
                </AccordionDetails>
            </Accordion>
            {isEngineLoaded && settings.engineToggle && (
                <Stack direction="row" alignItems={'center'} width="100%">
                    <Box sx={{ flexGrow: 1, minWidth: '0' }}>
                        <Collapse in={isEngineLoaded}>
                            <Paper className={'StockFishEngine'}>
                                <Stack direction="column" gap={1}>
                                    {finishText !== '' ? (
                                        <Typography ml={1} color="text.disabled" variant="body2">
                                            {finishText}
                                        </Typography>
                                    ) : (
                                        Object.keys(allVariations).map((key, index) => {
                                            const variation = allVariations[key]
                                            return (
                                                variation.startFen && (
                                                    <StockfishLine
                                                        key={index}
                                                        isCalculating={isCalculating}
                                                        analysisResults={variation}
                                                        onMoveSelected={
                                                            addEngineMove
                                                                ? addEngineMove
                                                                : () => console.log('no addEngineMove function')
                                                        }
                                                        colorToMove={colorToMove}
                                                    />
                                                )
                                            )
                                        })
                                    )}
                                </Stack>
                            </Paper>
                        </Collapse>
                    </Box>
                </Stack>
            )}
        </>
    )
}

export default EngineSection
