import Stack from '@mui/material/Stack'
import React from 'react'
import { RatingType } from '../../../components/matchmaking/ChallengeCard'
import { Rules } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import { Layout, useResponsiveSizings } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import LoginAction from '../../../sharedComponents/src/LoginAction/LoginAction'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import GameHistory from './GameHistory'
import StatsContainer from './StatsContainer'

const MobileDashboard: React.FC = () => {
    const rules = useStoreState((state) => state.rules)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)

    const { layout } = useResponsiveSizings()
    const ratingTypes: RatingType[] = ['bullet', 'blitz', 'rapid', 'classic']
    const profileEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.PROFILE)

    return layout === Layout.MOBILE ? (
        <Stack gap={4}>
            {profileEnabled ? (
                <>
                    <Stack>
                        {ratingTypes.map((ratingType, index) => (
                            <StatsContainer key={index} ratingType={ratingType} />
                        ))}
                    </Stack>
                    <GameHistory />
                </>
            ) : (
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <LoginAction
                        title="Unlock Your stats and game history!"
                        description="You need to register to view your game history, stats, and ratings. It is FREE, and it takes just a few seconds."
                        openSignInForm={() => setOverlayTrigger('signin')}
                        openSignUpForm={() => setOverlayTrigger('signup')}
                    />
                </Stack>
            )}
        </Stack>
    ) : null
}

MobileDashboard.displayName = 'MobileDashboard'

export default MobileDashboard
