import { IMAGES_CDN_LINK } from '../../utils/links'

// PO said no alphabet order for list
export enum PiecesTheme {
    'DEFAULT' = 'default',
    'MODERN' = 'modern',
    'BOLD' = 'bold',
    'MOCHA' = 'mocha',
    'BASIC' = 'basic',
    'BLITZIN' = 'blitzin',
    'CHERRY' = 'cherry',
    'CHOCOLATE' = 'chocolate',
    'CITRUS' = 'citrus',
    'CLASSIC' = 'classic',
    'DASHER' = 'dasher',
    'ICE' = 'ice',
    'MARBLE' = 'marble',
    'SMOOTH' = 'smooth',
    'TOON' = 'toon',
    'WOODEN' = 'wooden',
}

interface IPieceProps {
    isDragging: boolean
    squareWidth: number | string
    props?: any
}

export const imgFallback = (e: React.SyntheticEvent<HTMLImageElement, Event>, piece: string) => {
    const { currentTarget } = e
    currentTarget.onerror = null // prevents looping
    currentTarget.src = `${IMAGES_CDN_LINK}/static/pieces/${PiecesTheme.DEFAULT}/${piece}.svg`
}

export const piecesProvider = (providedTheme: PiecesTheme) => {
    const theme = Object.values(PiecesTheme).includes(providedTheme) ? providedTheme : PiecesTheme.DEFAULT
    console.log('IMAGES_CDN_LINK', `${IMAGES_CDN_LINK}/static/pieces/${theme}/wP.svg`)
    return {
        wP: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'wP')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wP.svg`}
                alt="wP"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wR: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'wR')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wR.svg`}
                alt="wR"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wN: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'wN')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wN.svg`}
                alt="wN"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wB: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'wB')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wB.svg`}
                alt="wB"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wQ: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'wQ')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wQ.svg`}
                alt="wQ"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        wK: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'wK')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/wK.svg`}
                alt="wK"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bP: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'bP')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bP.svg`}
                alt="bP"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bR: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'bR')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bR.svg`}
                alt="bR"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bN: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'bN')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bN.svg`}
                alt="bN"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bB: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'bB')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bB.svg`}
                alt="bB"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bQ: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'bQ')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bQ.svg`}
                alt="bQ"
                width={squareWidth}
                height={squareWidth}
            />
        ),
        bK: ({ isDragging, squareWidth, props = {} }: IPieceProps) => (
            <img
                {...props}
                onError={(e) => imgFallback(e, 'bK')}
                src={`${IMAGES_CDN_LINK}/static/pieces/${theme}/bK.svg`}
                alt="bK"
                width={squareWidth}
                height={squareWidth}
            />
        ),
    }
}
