import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, IconButton, Link, Stack, Typography } from '@mui/material'

const buttonStyle = {
    borderRadius: '0',
    mx: 4,
    width: '100%',
    fontFamily: 'Fira Sans',
    fontSize: '27px',
    lineHeight: '30px',
    letterSpacing: '2.74px',
    border: '2px solid #000000',
    justifyContent: 'center',
    alignItems: 'center',
    '&.btn-auth': {
        backgroundColor: '#000000',
        color: '#FFFFFF',

        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#000000',
        },
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const paragraphStyle = {
    color: '#000000',
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '2rem',
    fontFamily: 'Space Grotesk',
    width: '100%',
    padding: '0 1rem',
}

const stackStyle = {
    backgroundColor: '#FFFFFF',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #101820',
}

const linkStyle = {
    fontFamily: 'Space Grotesk',
    color: '#000000',
    marginTop: '20px',
    fontSize: '1rem',
    textAlign: 'center',
}

const loginLinkStyle = {
    paddingLeft: '10px',
    color: '#000000',
    fontSize: '1rem',
    textDecoration: 'underline',
    cursor: 'pointer',
}

// -------------------------------------------------------

export type DiveInFormProps = {
    onClose: () => void
    onSignIn: () => void
    onSignUp: () => void
}

function DiveInForm(props: DiveInFormProps) {
    const { onClose, onSignUp, onSignIn } = props

    return (
        <Dialog
            onClose={onClose}
            open={true}
            sx={{ zIndex: '1600', display: 'block !important' }}
            PaperProps={{ sx: { m: 0 } }}
        >
            <Stack sx={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={onClose} style={{ color: '#000000' }}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Stack
                    style={{
                        padding: '0 1rem 1rem 1rem',
                    }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: '60px',
                            color: '#000000',
                            textAlign: 'center',
                            marginBottom: '2rem',
                        }}
                    >
                        Join US!
                    </Typography>

                    <Typography sx={paragraphStyle}>
                        You have plenty of interesting articles at your fingertips. All you have to do is sign up to
                        become a member for FREE.
                    </Typography>

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button className="btn-auth" sx={buttonStyle} onClick={onSignUp}>
                            Sign Up
                        </Button>
                    </Box>

                    <Box sx={linkStyle}>
                        Already have an account?
                        <Link
                            sx={loginLinkStyle}
                            onClick={() => {
                                onSignIn()
                            }}
                        >
                            Log in
                        </Link>
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Button
                            sx={{
                                ...buttonStyle,
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                border: '2px solid #000000',
                                marginTop: '20px',
                                '&:hover': {
                                    backgroundColor: '#000000',
                                    color: '#FFFFFF',
                                },
                            }}
                            onClick={onClose}
                        >
                            Back
                        </Button>
                    </Box>
                    <Typography
                        sx={{
                            color: '#666979',
                            fontSize: '14px',
                            textAlign: 'center',
                            marginTop: '2rem',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        P.S. Signing up takes 60 seconds or less
                    </Typography>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default DiveInForm
