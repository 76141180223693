import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Tooltip } from '@mui/material'
import DraggablePiece from './DraggablePiece'
import { BlackPieces } from './constant/blackPieces'
import { WhitePieces } from './constant/whitePieces'
export type PieceSelectionProps = {
    deleteModeOn: boolean
    selectedPiece: any
    onSelect: any
    onDeleteClick: () => void
    onTrashClick: () => void
    setDeleteModeOn: (value: boolean) => void
}
function PieceSelection(props: PieceSelectionProps) {
    const { deleteModeOn, setDeleteModeOn, onDeleteClick, onTrashClick, onSelect, selectedPiece } = props

    return (
        // <DndProvider backend={HTML5Backend}>
        <Box
            width="calc(100% + 32px)"
            marginLeft="-16px"
            display="grid"
            gridTemplateColumns="repeat(14, 1fr)"
            justifyItems="center"
            alignItems="center"
            maxHeight="48px"
        >
            {BlackPieces.map((piece) => {
                return (
                    <DraggablePiece
                        piece={piece.piece}
                        key={piece.id}
                        isSelected={piece.piece === selectedPiece}
                        onSelect={onSelect}
                    />
                )
            })}

            <Tooltip title="Delete mode">
                <IconButton
                    disableRipple
                    sx={{
                        border: '1px solid white',
                        borderRadius: '100px',
                        padding: '2px',
                        backgroundColor: deleteModeOn ? 'error.main' : '',
                        '&:hover': {
                            backgroundColor: deleteModeOn ? 'error.dark' : '',
                        },
                    }}
                    onClick={() => {
                        onDeleteClick()
                        setDeleteModeOn(!deleteModeOn)
                    }}
                >
                    <ClearIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Clear board">
                <IconButton
                    disableRipple
                    onClick={() => {
                        onTrashClick()
                    }}
                    sx={{ marginLeft: '4px', border: '1px solid white', borderRadius: '100px', padding: '2px' }}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            {WhitePieces.map((piece) => {
                return (
                    <DraggablePiece
                        key={piece.id}
                        piece={piece.piece}
                        isSelected={piece.piece === selectedPiece}
                        onSelect={onSelect}
                    />
                )
            })}
        </Box>
        // </DndProvider>
    )
}

export default PieceSelection
