import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, IconButton, Link, Stack, Typography } from '@mui/material'

const buttonStyle = {
    padding: '10px ',
    fontFamily: 'Fira Sans',
    backgroundColor: '#101820',
    color: '#FFFFFF',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    borderRadius: '0',
    '&:hover': {
        backgroundColor: '#FFFFFF',
        color: '#101820',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const typographyStyle = {
    color: 'rgb(16 24 32)',
    fontSize: '1rem',
    fontFamily: 'Space Grotesk',
}

const stackStyle = {
    padding: '16px',
    backgroundColor: '#FFFFFF',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #101820',
}

const linkContainerStyle = {
    color: '#000000',
    py: 2,
    fontSize: '14px',
    fontFamily: 'Space Grotesk',
    textAlign: 'center',
}

const loginLinkStyle = {
    paddingLeft: '10px',
    color: '#000000',
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
}

export interface ConfirmEmailFormProps {
    onClose: () => void
    onSignIn: () => void
    onTryAgain: () => void
}
function ErrorForm(props: ConfirmEmailFormProps) {
    const { onClose, onSignIn, onTryAgain } = props

    return (
        <Dialog
            onClose={onClose}
            open={true}
            sx={{ zIndex: '1600', display: 'block !important' }}
            PaperProps={{ sx: { m: 0 } }}
        >
            <Stack style={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton sx={{ color: 'black' }} onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Typography
                    variant="h1"
                    sx={{
                        color: 'rgb(16 24 32)',
                        textAlign: 'center',
                    }}
                >
                    OOOPS!
                </Typography>
                <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, paddingLeft: '20px' }}>
                    <img src="/assets/images/horse.svg" alt="error" />
                </Box>

                <Box justifyContent={'center'} alignSelf={'center'}>
                    <Typography sx={typographyStyle} style={{ textAlign: 'center', paddingTop: '20px' }}>
                        An unknown error has occurred. <br /> Please try again.
                    </Typography>
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '35px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Button sx={buttonStyle} onClick={onTryAgain}>
                        Try again
                    </Button>
                </Box>
                <Box sx={linkContainerStyle}>
                    Already have an account?
                    <Link sx={loginLinkStyle} onClick={onSignIn}>
                        Log in
                    </Link>
                </Box>
            </Stack>
        </Dialog>
    )
}

export default ErrorForm
