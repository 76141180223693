import { Box, Stack } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'

import { GameViewState, StateChangeRequest } from '../../features/gameView/GameViewModel'
import ConfirmationDialogs, {
    ConfirmationType,
} from '../../features/gameView/components/confirmationDialogs/ConfirmationDialogs'
import { LoginState } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { Layout, useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { FTUE } from '../FTUE/FTUE'
import { LoginMenu } from '../LoginMenu'
import { ConnectionFailedOverlay } from '../overlays/ConnectionFailedOverlay/ConnectionFailedOverlay'
import { sizings } from './AppLayout'
import { OpponentDisconnectedSnackbar } from './StyledSnackbar/OpponentDisconnectedSnackbar'
import StyledSnackbar from './StyledSnackbar/StyledSnackbar'

type Props = {
    children?: React.ReactNode
    dataProvider?: JSX.Element
}

const AppLayoutWithoutBoard = forwardRef<HTMLDivElement, Props>(({ dataProvider, children }: Props, ref) => {
    const { layout } = useResponsiveSizings()
    const userData = useStoreState((state) => state.userData)
    const rematchStatus = useStoreState((state) => state.matchMaker.rematchStatus)
    const rematchMatchData = useStoreState((state) => state.matchMaker.rematchMatchData)
    const snackbarMessage = useStoreState((state) => state.snackbarMessage)
    const gameState = useStoreState((state) => state.gameView.gameState)
    const connectionStatus = useStoreState((state) => state.matchMaker.connectionStatus)

    const setGameEndEnabled = useStoreActions((state) => state.gameView.setGameEndEnabled)
    const acceptChallenge = useStoreActions((state) => state.matchMaker.acceptChallenge)
    const sendRejectChallenge = useStoreActions((state) => state.matchMaker.sendRejectChallenge)
    const setRematchStatus = useStoreActions((state) => state.matchMaker.setRematchStatus)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)

    const [dialog, setDialog] = useState<ConfirmationType | undefined>()
    const [snackBarOpen, setSnackBarOpen] = useState(false)

    // if a rematch offer has come in, open the dialog
    useEffect(() => {
        if (rematchStatus === StateChangeRequest.RECEIVED && gameState !== GameViewState.GAME_RUNNING)
            setDialog(ConfirmationType.ACCEPT_REMATCH)
    }, [rematchStatus])

    useEffect(() => {
        if (snackbarMessage !== undefined) {
            setSnackBarOpen(true)
        }
    }, [snackbarMessage])

    //  confirm dialog confirm handling
    const handleConfirm = (type: ConfirmationType) => {
        if (type === ConfirmationType.ACCEPT_REMATCH) {
            setRematchStatus(undefined)
            if (rematchMatchData) {
                acceptChallenge({ id: rematchMatchData.pairId })
                setGameEndEnabled(false)
                analyticsManager.dispatchEvent('offerRematchResult', {
                    result: 'accepted',
                    parentMatchID: rematchMatchData.pairId,
                })
            }
        }
        setDialog(undefined)
    }

    //  confirm dialog cancel handling
    const handleCancel = (type: ConfirmationType) => {
        if (type === ConfirmationType.ACCEPT_REMATCH) {
            setRematchStatus(undefined)
            if (rematchMatchData) {
                sendRejectChallenge({ pairId: rematchMatchData.pairId, isRematch: true })
            }
        }
        setDialog(undefined)
    }

    const handleSnackBarOpenClose = () => {
        setSnackBarOpen(false)
        setRematchStatus(undefined)
        setSnackbarMessage(undefined)
    }

    return (
        <>
            {dataProvider}
            <Box ref={ref}>
                <Stack
                    direction={layout === Layout.MOBILE ? 'column' : 'row'}
                    alignItems="stretch"
                    justifyContent="center"
                    mx={{ xs: `calc(${sizings.margin}/4)`, md: sizings.margin }}
                    mb={{ xs: `calc(${sizings.margin}/4)`, md: sizings.margin }}
                    gap={sizings.gap}
                    maxHeight={{ xs: 'unset', md: `calc(100vh - ${sizings.margin} - ${sizings.header})` }}
                    margin="0 auto"
                >
                    <Box
                        flexGrow={1}
                        width="100%"
                        // MIGHT BREAK STUFF
                        overflow="visible"
                        minWidth={{
                            xs: '100%',
                            md: userData.state === LoginState.NOT_LOGGED_IN ? sizings.rightPanelMin : '100%',
                        }}
                        maxWidth={{
                            xs: '100%',
                            md: userData.state === LoginState.NOT_LOGGED_IN ? sizings.rightPanelMax : '100%',
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="stretch"
                            flexDirection="column"
                            flexGrow={1}
                            justifyContent="flex-start"
                            height="100%"
                            gap="12px"
                        >
                            {userData.state === LoginState.NOT_LOGGED_IN ? <LoginMenu dialog={false} /> : children}
                        </Box>
                    </Box>
                </Stack>
            </Box>
            {dialog && (
                <ConfirmationDialogs
                    type={dialog}
                    onClose={handleCancel}
                    onConfirm={handleConfirm}
                    rematchUser={rematchMatchData?.userName ? rematchMatchData.userName : 'Opponent'}
                />
            )}
            <StyledSnackbar isOpen={snackBarOpen} message={snackbarMessage} onClose={handleSnackBarOpenClose} />
            <OpponentDisconnectedSnackbar />
            <ConnectionFailedOverlay connectionStatus={connectionStatus} />
            <FTUE />
        </>
    )
})

AppLayoutWithoutBoard.displayName = 'AppLayoutWithoutBoard'

export default AppLayoutWithoutBoard
