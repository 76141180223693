import { Button, Stack, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { colors } from '../../../sharedComponents/src/globalHeader/theme/colors'

export const FILE_FORMAT = {
    PNG: 'image/png',
    PDF: 'application/pdf',
    JPEG: 'image/jpeg',
}

export const MB_SIZE = 1048576 // 1MB in bytes
export const MAX_FILE_SIZE = 5 // in MB

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

interface IProps {
    value: File | null
    onChange: Function
    onCancel: Function
    text: string
    disabled?: boolean
    error?: string
}

const FileUpload: React.FC<IProps> = (props) => {
    const { value, disabled = false, onChange, text, onCancel, error = '' } = props
    const [isFileExist, setIsFileExist] = useState<boolean>(!!value)

    const onChangeHandler = (event: any) => {
        const { files } = event.target
        setIsFileExist(true)
        onChange(files[0])
        event.target.value = ''
    }

    const onCancelHandler = () => {
        setIsFileExist(false)
        onCancel()
    }

    useEffect(() => {
        setIsFileExist(!!value)
    }, [value])

    return (
        <Stack gap={1}>
            <Stack direction="row" spacing="1rem">
                <Button
                    variant="outlined"
                    sx={{
                        fontFamily: 'Fira Sans',
                        maxWidth: 'fit-content',
                    }}
                    component="label"
                    tabIndex={-1}
                    disabled={disabled}
                >
                    {text}
                    <VisuallyHiddenInput
                        type="file"
                        onChange={onChangeHandler}
                        disabled={disabled}
                        accept=".png, .jpg, .jpeg, image/*;capture=camera"
                    />
                </Button>
                {isFileExist && (
                    <Button
                        variant="outlined"
                        sx={{
                            fontFamily: 'Fira Sans',
                            maxWidth: 'fit-content',
                        }}
                        onClick={onCancelHandler}
                    >
                        Cancel
                    </Button>
                )}
            </Stack>
            {error && error !== '' && (
                <Typography variant="body1" color={colors.error.main}>
                    {error}
                </Typography>
            )}
        </Stack>
    )
}

FileUpload.displayName = 'FileUpload'

export default FileUpload
