import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import React from 'react'
import { modeIcon, RatingType } from '../../../components/matchmaking/ChallengeCard'
import HelpTooltip from '../../../sharedComponents/src/globalHeader/components/HelpTooltip'
import { Rules } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import LoginAction from '../../../sharedComponents/src/LoginAction/LoginAction'
import { useStoreActions, useStoreState } from '../../../store/hooks'

interface ITooltipProps {
    text: string
}

export const TooltipText: React.FC<ITooltipProps> = ({ text }) => {
    return (
        <Typography align="center" textTransform="capitalize" variant="body2" sx={{ maxWidth: '130px' }}>
            {text}
        </Typography>
    )
}

TooltipText.displayName = 'TooltipText'

interface IHeaderProps {
    text: RatingType
    basis: string
}

const StatsHeaderColumn: React.FC<IHeaderProps> = (props) => {
    const { text, basis } = props
    return (
        <Stack
            gap={1}
            sx={(theme) => ({
                flexDirection: 'row',
                px: 2,
                py: 1,
                flexBasis: basis,
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                    fill: theme.palette.text.primary,
                    width: '25px',
                    height: '100%',
                },
            })}
        >
            {modeIcon(text)}
            <Typography variant="h5" textTransform="capitalize">
                {text}
            </Typography>
        </Stack>
    )
}

StatsHeaderColumn.displayName = 'StatsHeaderColumn'

interface IColumnProps {
    text: number
    basis: string
    color: string
    secondaryText?: string
    hide?: boolean
    isProvisional?: boolean
}

export const StatsColumn: React.FC<IColumnProps> = (props) => {
    const { text, secondaryText, basis, color, hide, isProvisional = false } = props
    const isHide = hide !== undefined ? hide : false
    const isSecondaryShow = text > 0 && !!secondaryText && !isHide && !isProvisional ? true : false

    return (
        <Stack
            sx={{
                flexBasis: basis,
                alignItems: 'center',
                justifyContent: 'center',
                px: 2,
                py: isSecondaryShow ? 0 : 2,
                position: 'relative',
            }}
        >
            <HelpTooltip
                title={
                    <TooltipText
                        text={!!isHide ? 'Turn OFF Casual Play in your settings to view ratings' : 'Provisional Rating'}
                    />
                }
                hideTooltip={isHide === false ? isProvisional === false : false}
                placement="right-start"
                wrapperStyle={
                    isHide === true || isProvisional === true
                        ? {
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translateX(-50%) translateY(-50%)',
                          }
                        : {}
                }
            >
                <Stack direction="column" alignItems="center">
                    <Typography variant="h5" textTransform="capitalize" color={color}>
                        {!!isHide || (!!isProvisional && !!secondaryText) ? '-' : text}
                    </Typography>
                    {isSecondaryShow && !!secondaryText ? (
                        <Typography variant="h6" textTransform="capitalize" fontFamily="Fira Sans">
                            {new Date(secondaryText).toLocaleDateString('en-US')}
                        </Typography>
                    ) : null}
                </Stack>
            </HelpTooltip>
        </Stack>
    )
}

StatsColumn.displayName = 'StatsColumn'

const StatsTable: React.FC = () => {
    const stats = useStoreState((state) => state.profileView.stats)
    const rules = useStoreState((state) => state.rules)

    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)

    const ratingTypes: RatingType[] = ['bullet', 'blitz', 'rapid', 'classic']
    const profileEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.PROFILE)

    return (
        <Stack sx={{ backgroundColor: 'background.paper', py: 1 }}>
            <Stack direction="row">
                <Stack sx={{ flexBasis: '21%', px: 2, py: 1, alignItems: 'center' }}>
                    <Typography variant="h2">Stats</Typography>
                </Stack>
                {ratingTypes.map((ratingType, index) => (
                    <StatsHeaderColumn text={ratingType} basis={`${index === ratingTypes.length - 1 ? '22' : '19'}%`} />
                ))}
            </Stack>
            {profileEnabled ? (
                stats.map((item, index) => (
                    <Stack key={index} direction="row">
                        <Stack sx={{ flexBasis: '21%', p: 2, alignItems: 'center' }}>
                            <Typography variant="h5" textTransform="capitalize">
                                {item.name}
                            </Typography>
                        </Stack>
                        {ratingTypes.map((ratingType, index) => (
                            <StatsColumn
                                key={index}
                                text={item[ratingType]}
                                secondaryText={item[`${ratingType}Info`]}
                                basis={index === ratingTypes.length - 1 ? '22%' : '19%'}
                                color={item.color}
                                hide={item.hideRating}
                                isProvisional={item[`${ratingType}IsProvisional`]}
                            />
                        ))}
                    </Stack>
                ))
            ) : (
                <Stack direction="row">
                    <Stack sx={{ flexBasis: '21%', alignItems: 'center' }}>
                        {stats.map((item, index) => (
                            <Typography key={index} sx={{ p: 2 }} variant="h5" textTransform="capitalize">
                                {item.name}
                            </Typography>
                        ))}
                    </Stack>
                    <Stack sx={{ flexBasis: '79%', justifyContent: 'center', alignItems: 'center' }}>
                        <LoginAction
                            title="Unlock Your stats and game history!"
                            description="You need to register to view your game history, stats, and ratings. It is FREE, and it takes just a few seconds."
                            openSignInForm={() => setOverlayTrigger('signin')}
                            openSignUpForm={() => setOverlayTrigger('signup')}
                        />
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}

StatsTable.displayName = 'StatsTable'

export default StatsTable
