import { useCallback, useEffect, useMemo, useState } from 'react'
import { moveToShortNotation, nameOfSquare, squareByName } from '../../chess/basics'
import { generateMoves, makeMove } from '../../chess/core'
import { positionToFEN } from '../../chess/fen'
import { getLastPosition, getMoveById, getPositionById } from '../../chess/gameTree'
import { Color, GameTree, Move, MoveType, Piece, Position } from '../../chess/types'
import { Chessboard } from '../../react-chessboard/src'
import { Piece as LibPiece, Square } from '../../react-chessboard/src/chessboard/types'
import { piecesProvider } from '../../sharedComponents/src/globalHeader/common/PiecesProvider'
import { boardColors } from '../../sharedComponents/src/globalHeader/theme/colors'
import { useStoreState } from '../../store/hooks'
import useGameSounds from './hooks/useGameSounds'
import usePremove from './hooks/usePremove'
import {
    combineStyles,
    getCheckHighlightStyles,
    getLastMoveHighlightsStyles,
    getPremoveStyles,
    getRightClickedSquareStyles,
    getSelectedSquareStyles,
} from './utils'

// const Chessboard = lazy(() => import('../../react-chessboard/src').then(({ Chessboard }) => ({ default: Chessboard })))

// TODO: react lazy load chess pieces

export const BoardOffsetX = 25
export const BoardOffsetY = 21
export const BoardSquareSize = 70.25

// which side of the board is up
export enum BoardDirection {
    WHITE_VIEW = 1,
    BLACK_VIEW = 0,
}

// what types of interactions can a user have with the current position
export enum InteractionMode {
    VIEW_ONLY,
    BLACK_TO_MOVE,
    WHITE_TO_MOVE,
    FREE,
}

export type PieceProps = {
    color: Color
    enabled: boolean
    balance?: boolean
}

export type ChessBoardProps = {
    gameTree: GameTree
    currentPositionId?: string | undefined
    position: Position
    flipped: boolean
    myColor: Color
    onMove: (mov: Move) => boolean
    gameRunning: boolean
    myTurn?: boolean
    observer?: boolean
    mobile?: boolean
    processingMove?: boolean
}

const promotionPieceSelect = (piece: string): Piece => {
    let promotionPiece: Piece = Piece.Queen
    switch (piece) {
        case 'wR':
            promotionPiece = Piece.WhiteRook
            break
        case 'wN':
            promotionPiece = Piece.WhiteKnight
            break
        case 'wB':
            promotionPiece = Piece.WhiteBishop
            break
        case 'bR':
            promotionPiece = Piece.BlackRook
            break
        case 'bN':
            promotionPiece = Piece.BlackKnight
            break
        case 'bB':
            promotionPiece = Piece.BlackBishop
            break
        case 'wQ':
            promotionPiece = Piece.WhiteQueen
            break
        case 'bQ':
            promotionPiece = Piece.BlackQueen
            break
    }
    return promotionPiece
}

export const ChessBoard: React.FC<ChessBoardProps> = (props) => {
    const {
        gameTree,
        gameRunning,
        currentPositionId,
        position,
        flipped,
        onMove,
        myColor,
        observer = false,
        processingMove,
    } = props

    const settings = useStoreState((state) => state.gameView.settings)
    const [rightClickedSquares, setRightClickedSquares] = useState<Square[]>([])
    const [moveFrom, setMoveFrom] = useState<Square | undefined>(undefined)
    const [moveTo, setMoveTo] = useState<Square | undefined>(undefined)
    const [promotionReason, setPromotionReason] = useState<'move' | 'premove' | undefined>(undefined)
    const [dragPiece, setDragPiece] = useState<LibPiece | undefined>(undefined)
    const [newPosition, setNewPosition] = useState<Position>(position)
    const [localLastMove, setLocalLastMove] = useState<{ move: Move; shortNotation: string } | undefined>(undefined)
    const isLastPosition = useMemo(() => {
        return currentPositionId === getLastPosition(gameTree).id
    }, [gameTree, currentPositionId])

    const resetIntermediaryState = () => {
        if (!moveFrom) {
            setMoveFrom(undefined)
            clearPremove()
            setLegalPremoves([])
        }
        setMoveTo(undefined)
        setDragPiece(undefined)
        setPromotionReason(undefined)
        setLocalLastMove(undefined)
        setRightClickedSquares([])
    }

    const { playMoveSound } = useGameSounds(settings)

    const makeMoveHandler = useCallback(
        (move: Move) => {
            if (!isLastPosition || processingMove) return

            onMove(move)
            if (moveFrom === nameOfSquare(move.from)) {
                setMoveFrom(undefined)
            }
            setMoveTo(undefined)
            setNewPosition(makeMove(position, move))
            setLocalLastMove({ move, shortNotation: moveToShortNotation(position, move) })
        },
        [position, onMove, isLastPosition, processingMove, moveFrom],
    )

    const { premove, legalPremoves, prepareLegalPremoves, preparePremove, clearPremove, setLegalPremoves } = usePremove(
        newPosition,
        myColor,
        settings.preMove,
    )

    useEffect(() => {
        if (!gameRunning) {
            resetIntermediaryState()
        }
    }, [gameRunning, clearPremove])

    const activeMove = useMemo(() => {
        if (gameTree && currentPositionId) {
            const tp = getPositionById(gameTree, currentPositionId)

            if (tp && tp.previousMoveId !== undefined) {
                return getMoveById(gameTree, tp!.previousMoveId)
            }
        }
    }, [gameTree, currentPositionId])

    useEffect(() => {
        // main game state updater
        setNewPosition(position)
        resetIntermediaryState()
    }, [position])

    useEffect(() => {
        if (positionToFEN(position) === positionToFEN(newPosition)) return

        if (observer) {
            playMoveSound(activeMove?.displayString)
        } else {
            if (localLastMove) {
                playMoveSound(localLastMove.shortNotation)
            } else if (position.turn === myColor) {
                playMoveSound(activeMove?.displayString)
            } else if (activeMove?.nextPositionId !== getLastPosition(gameTree).id) {
                playMoveSound(activeMove?.displayString)
            }
        }
    }, [localLastMove, activeMove, position, newPosition])

    useEffect(() => {
        if (!gameRunning) {
            setRightClickedSquares([])
        }
    }, [gameRunning])

    const onFirstClick = useCallback(
        (sourceSquare: Square) => {
            if (observer) return
            setRightClickedSquares([])

            clearPremove()
            if (position.turn !== myColor) {
                prepareLegalPremoves(sourceSquare)
            }

            setMoveFrom(sourceSquare)
        },
        [clearPremove, prepareLegalPremoves, position, myColor, observer],
    )

    const onSecondClick = useCallback(
        (moveFrom: Square, sourceSquare: Square) => {
            if (!gameRunning) {
                onFirstClick(sourceSquare)
                return
            }

            if (moveFrom === sourceSquare) return

            if (position.turn === myColor) {
                // regular move
                const moves = generateMoves(position, squareByName(moveFrom))
                const legalMove = moves.find((m) => nameOfSquare(m.to) === sourceSquare)
                if (legalMove) {
                    if (legalMove.promotion) {
                        if (settings.autoQueen) {
                            const move = {
                                from: squareByName(moveFrom),
                                to: squareByName(sourceSquare),
                                type: MoveType.Promotion,
                                promotion: myColor === Color.White ? Piece.WhiteQueen : Piece.BlackQueen,
                            }

                            makeMoveHandler(move)
                        } else {
                            setMoveTo(sourceSquare)
                            setPromotionReason('move')
                        }

                        return
                    } else {
                        makeMoveHandler(legalMove)
                    }
                } else {
                    onFirstClick(sourceSquare)
                }
            } else {
                // premove
                const legalPremove = legalPremoves.find((m) => nameOfSquare(m.to) === sourceSquare)
                if (legalPremove) {
                    if (legalPremove.type === MoveType.Promotion) {
                        if (!settings.autoQueen) {
                            setMoveTo(sourceSquare)
                            setPromotionReason('premove')
                        } else {
                            const move = {
                                from: squareByName(moveFrom),
                                to: squareByName(sourceSquare),
                                type: MoveType.Promotion,
                                promotion: myColor === Color.White ? Piece.WhiteQueen : Piece.BlackQueen,
                            }

                            preparePremove(move)
                        }
                        return
                    } else {
                        preparePremove(legalPremove)
                    }
                    setLegalPremoves([])
                } else {
                    onFirstClick(sourceSquare)
                }
            }
        },
        [position, myColor, legalPremoves, makeMoveHandler, preparePremove],
    )

    const onClickHandler = useCallback(
        (sourceSquare: Square) => {
            if (!moveFrom) {
                onFirstClick(sourceSquare)
                return
            }

            onSecondClick(moveFrom, sourceSquare)
        },
        [onFirstClick, onSecondClick, moveFrom],
    )

    useEffect(() => {
        if (!premove) return
        if (position.turn !== myColor) return

        const moves = generateMoves(position, premove.from)
        const legalMove = moves.find((m) => m.to === premove.to && m.from === premove.from)
        if (legalMove) {
            makeMoveHandler({ ...legalMove, promotion: premove.promotion })
        }
        clearPremove()
    }, [position, onMove, clearPremove, premove])

    const pieceDragBeginHandler = useCallback(
        (piece, sourceSquare) => {
            setDragPiece(piece)
            setMoveFrom(sourceSquare)
            prepareLegalPremoves(sourceSquare)
        },
        [newPosition, myColor],
    )

    const pieceDropHandler = useCallback(
        (sourceSquare: Square, targetSquare: Square, piece: LibPiece) => {
            setRightClickedSquares([])
            setLegalPremoves([])

            if (!gameRunning) {
                return false
            }

            if (position.turn === myColor) {
                // regular move D&D
                const moves = generateMoves(position, squareByName(sourceSquare))
                const legalMove = moves.find((m) => nameOfSquare(m.to) === targetSquare)
                if (legalMove) {
                    if (legalMove.promotion !== undefined) {
                        if (settings.autoQueen) {
                            makeMoveHandler(legalMove)
                        } else {
                            setMoveFrom(sourceSquare)
                            setMoveTo(targetSquare)
                            setPromotionReason('move')
                        }
                    } else {
                        makeMoveHandler(legalMove)
                        return true
                    }
                }

                return false
            } else {
                // premove D&D
                if (premove) {
                    clearPremove()
                }

                const legalPremove = legalPremoves.find((m) => nameOfSquare(m.to) === targetSquare)
                if (legalPremove) {
                    if (legalPremove.type === MoveType.Promotion) {
                        if (settings.autoQueen) {
                            const move = {
                                from: squareByName(sourceSquare),
                                to: squareByName(targetSquare),
                                type: MoveType.Promotion,
                                promotion: myColor === Color.White ? Piece.WhiteQueen : Piece.BlackQueen,
                            }

                            preparePremove(move)
                        } else {
                            setMoveFrom(sourceSquare)
                            setMoveTo(targetSquare)
                            setPromotionReason('premove')
                        }
                    } else {
                        preparePremove(legalPremove)
                        setMoveFrom(undefined)
                        setLegalPremoves([])
                    }
                    return false
                }

                setLegalPremoves([])
                setMoveFrom(undefined)
                return false
            }
        },
        [dragPiece, makeMoveHandler, position, gameRunning, myColor, clearPremove, legalPremoves],
    )

    const squareRightClickHandler = useCallback(
        (sourceSquare) => {
            if (premove) {
                clearPremove()
                return
            }

            setRightClickedSquares((prevState) => {
                if (prevState.some((square) => square === sourceSquare)) {
                    return prevState.filter((square) => square !== sourceSquare)
                } else {
                    return [...prevState, sourceSquare]
                }
            })
        },
        [premove, moveFrom, clearPremove],
    )

    const direction = flipped ? BoardDirection.WHITE_VIEW : BoardDirection.BLACK_VIEW
    const boardColor = boardColors[settings.boardStyle] || boardColors.default

    const customPieces = useMemo(() => piecesProvider(settings.pieceStyle), [settings.pieceStyle])

    let promotionDialogVariant: 'modal' | 'vertical' = 'vertical'
    if (flipped) {
        promotionDialogVariant = myColor === Color.White ? 'modal' : 'vertical'
    } else {
        promotionDialogVariant = myColor === Color.Black ? 'modal' : 'vertical'
    }

    const customSquareStyles = useMemo(() => {
        return combineStyles(
            getLastMoveHighlightsStyles(localLastMove?.move || activeMove?.move),
            getCheckHighlightStyles(newPosition),
            getRightClickedSquareStyles(rightClickedSquares),
            getSelectedSquareStyles(settings.showLegalMoves, newPosition, moveFrom, legalPremoves, myColor),
            getPremoveStyles(premove),
        )
    }, [localLastMove, activeMove, newPosition, rightClickedSquares, moveFrom, legalPremoves, myColor, premove])

    const isDraggablePiece = useCallback(
        ({ piece, sourceSquare }) => {
            if (window.location.pathname === '/watch') {
                return false
            }
            return !observer && myColor === Color.White ? piece.startsWith('w') : piece.startsWith('b')
        },
        [observer, myColor],
    )

    return (
        <Chessboard
            arePiecesDraggable={gameRunning}
            clearPremove={clearPremove}
            allowDragOutsideBoard={false}
            animationDuration={50}
            autoPromoteToQueen={settings.autoQueen}
            promotionDialogVariant={promotionDialogVariant}
            customSquareStyles={customSquareStyles}
            position={positionToFEN(newPosition)}
            customDarkSquareStyle={boardColor.secondary}
            customLightSquareStyle={boardColor.main}
            showBoardNotation={settings.coordinates}
            boardOrientation={direction === BoardDirection.WHITE_VIEW ? 'black' : 'white'}
            isDraggablePiece={isDraggablePiece}
            onPieceDragBegin={pieceDragBeginHandler}
            onSquareClick={onClickHandler}
            onSquareRightClick={squareRightClickHandler}
            onPieceDrop={pieceDropHandler}
            customPieces={customPieces}
            customDropSquareStyle={{}}
            areArrowsAllowed={true}
            onPromotionCheck={() => false}
            {...(promotionReason !== undefined && {
                showPromotionDialog: true,
                promotionToSquare: moveTo as Square,
                onPromotionPieceSelect: (piece) => {
                    if (piece) {
                        const promotionPiece = promotionPieceSelect(piece)
                        const move = {
                            from: squareByName(moveFrom!),
                            to: squareByName(moveTo!),
                            type: MoveType.Promotion,
                            promotion: promotionPiece,
                        }
                        if (promotionReason === 'move') {
                            makeMoveHandler(move)
                            return true
                        } else {
                            preparePremove(move)
                            setPromotionReason(undefined)
                            setLegalPremoves([])
                            return false
                        }
                    } else {
                        setPromotionReason(undefined)
                        // setLegalPremoves([])
                        return false
                    }
                },
            })}
        />
    )
}
