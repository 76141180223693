import { DotLottie, DotLottieReact } from '@lottiefiles/dotlottie-react'
import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import useSound from 'use-sound'
import { useStoreState } from '../../store/hooks'
import { StreakResult } from './StreakResult'

export enum StreakAnimation {
    PRACTICE = '/assets/animations/puzzles/streak-practice-puzzle.lottie',
    DAILY = '/assets/animations/puzzles/streak-daily-puzzle.lottie',
}

interface PuzzleStreakOverlayProps {
    variant: StreakAnimation
    value: number
    onAnimationEnd: () => void
}

export const PuzzleStreakOverlay: FC<PuzzleStreakOverlayProps> = ({ variant, value, onAnimationEnd }) => {
    const settings = useStoreState((state) => state.gameView.settings)

    const [dotLottie, setDotLottie] = useState<DotLottie>()
    const [showValue, setShowValue] = useState(false)
    const [playAudio, setPlayAudio] = useState(false)
    const [streakSound] = useSound(`/assets/sounds/default/StreakSound.mp3`, { volume: 0.25 })

    useEffect(() => {
        playAudio && settings.soundOn && streakSound()
    }, [playAudio])

    useEffect(() => {
        // This function will be called when the animation starts playing.
        function onPlay() {
            console.log('Animation start playing')
            // setPlayAudio(true)
        }

        // This function will be called when the animation is paused.
        function onPause() {
            console.log('Animation paused')
        }

        // This function will be called when the animation is completed.
        function onComplete() {
            console.log('Animation completed')
            onAnimationEnd()
        }

        function onFrameChange({ currentFrame }) {
            // console.log('Current frame: ', currentFrame)
            if (currentFrame > 5 && currentFrame < 110) {
                setShowValue(true)
                setPlayAudio(true)
            } else {
                setShowValue(false)
            }
        }

        // Listen to events emitted by the DotLottie instance when it is available.
        if (dotLottie) {
            dotLottie.addEventListener('play', onPlay)
            dotLottie.addEventListener('pause', onPause)
            dotLottie.addEventListener('complete', onComplete)
            dotLottie.addEventListener('frame', onFrameChange)
        }

        return () => {
            // Remove event listeners when the component is unmounted.
            if (dotLottie) {
                dotLottie.removeEventListener('play', onPlay)
                dotLottie.removeEventListener('pause', onPause)
                dotLottie.removeEventListener('complete', onComplete)
                dotLottie.removeEventListener('frame', onFrameChange)
            }
        }
    }, [dotLottie])

    const dotLottieRefCallback = (dotLottie) => {
        setDotLottie(dotLottie)
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: '1000',
            }}
        >
            <Box position="relative" width="100%" height="100%">
                <DotLottieReact
                    // segment={weekProgress?.rewardsTotal ? undefined : [0, 28]}
                    src={variant}
                    autoplay
                    // loop
                    layout={{ fit: 'fill', align: [0, 0] }}
                    dotLottieRefCallback={dotLottieRefCallback}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    <StreakResult value={value} show={showValue} />
                </Box>
            </Box>
        </Box>
    )
}
