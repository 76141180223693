import { Action, action } from 'easy-peasy'
import { moveToAlgebraicLong } from '../../chess/basics'
import {
    addMoveToMainline,
    addStrMoveToMainline,
    createRootGameTree,
    getLastPosition,
    getMoveById,
    getMoveId,
    getNextPosition,
    getPreviousPosition,
    getStartingPosition,
} from '../../chess/gameTree'
import { gameFromPGN, getDataFromPGN, PGNFields } from '../../chess/pgn'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Color, GameTree, Move } from '../../chess/types'
import toPrecision from '../../functions/toPrecision'
import { Settings } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { PiecesTheme } from '../../sharedComponents/src/globalHeader/common/PiecesProvider'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import ReconnectingWebSocket from '../../socket/ReconnectingWebsocket'
import {
    ClockData,
    ClockInstant,
    ConnectionStatus,
    GameData,
    GameDataOnReconnect,
    GameResult,
    GameResultAnalyticsData,
    GameResultType,
    MatchData,
} from '../../store/types'
import { GS_REQUESTS } from './components/gameServerConnector/GSTypes'

export enum GameViewState {
    PRE_GAME,
    GAME_RUNNING,
    GAME_OVER,
    GAME_INITIALIZING,
    GAME_PRELOADING,
}

export enum StateChangeRequest {
    REQUESTED,
    RECEIVED,
    REJECTED,
    ERROR,
    REMOVED,
}

const defaultSettings: Settings = {
    autoAccept: false,
    boardStyle: 'default',
    coordinates: true,
    dragAndDrop: true,
    pieceStyle: PiecesTheme.DEFAULT,
    preMove: true,
    soundCheckOn: true,
    soundDrawOn: true,
    soundMateOn: true,
    soundMoveOn: true,
    soundOn: true,
    soundSmateOn: true,
    autoQueen: true,
    soundStyle: 'default',
    darkMode: true,
    briefToggle: true,
    friendlyMode: true,
    showLegalMoves: true,
    engineToggle: true,
    evaluationBar: false,
    highlightTopMoves: true,
    multipleLines: 3,
}

// ----------------------------------------------------------------------------- type

export type GameViewModel = {
    socket: ReconnectingWebSocket | undefined
    connectionStatus: ConnectionStatus
    clockInstantWhite?: ClockInstant
    clockInstantBlack?: ClockInstant
    clockStartServer: number
    clockStartClient: number
    matchData: MatchData | undefined
    gameData: GameData | undefined
    myColor: Color
    myRating: number
    flipped: boolean
    gameState: GameViewState
    gameResult: GameResult | undefined
    gameTree: GameTree
    deferredGameTree: GameTree
    currentPositionId: string
    deferredCurrentPositionId: string
    drawOfferStatus?: StateChangeRequest
    gameEndEnabled: boolean
    enableAbort: boolean
    opening: string | undefined
    settings: Settings
    engineDifficulty: { level: number } | undefined
    gameStartedTimestamp: number | undefined
    clockRunningFor: Color
    opponentDisconnected: { timestamp: number; secondsTillVictoryClaim: number } | undefined
    opponentConnected: boolean
    canClaimVictory: boolean
    sessionResultHistory: {
        whitePlayerId: string
        blackPlayerId: string
        winnerId: string | undefined
        type: GameResultType
    }[]
    myLastMove?: Move
    lastMoveReceivedAt: number
    processingMove: boolean
    evalBarVisibility: boolean

    setSettings: Action<GameViewModel, Settings>
    setSocket: Action<GameViewModel, ReconnectingWebSocket | undefined>
    setGameState: Action<GameViewModel, GameViewState>
    setConnectionStatus: Action<GameViewModel, ConnectionStatus>
    setMatchData: Action<GameViewModel, MatchData | undefined>
    setGameResult: Action<GameViewModel, GameResult>
    sendGameResultToAnalytics: Action<GameViewModel, GameResultAnalyticsData>
    setCurrentPositionId: Action<GameViewModel, string>
    setFlipBoard: Action<GameViewModel, boolean>
    setMyColor: Action<GameViewModel, Color>
    setDrawStatus: Action<GameViewModel, StateChangeRequest | undefined>
    addMoveOpening: Action<GameViewModel, { moveID: string; opening: string }>
    setGameTree: Action<GameViewModel, GameTree>
    setGameEndEnabled: Action<GameViewModel, boolean>
    setClockInstantWhite: Action<GameViewModel, ClockInstant | undefined>
    setClockInstantBlack: Action<GameViewModel, ClockInstant | undefined>
    receiveMove: Action<
        GameViewModel,
        {
            moveStr: string
            clock: ClockData
            opening?: string
            receivedAt: number
        }
    >
    receiveClockUpdate: Action<GameViewModel, { clockWhite: number; clockBlack: number }>
    setGameStartedTimestamp: Action<GameViewModel, number>
    sendJoin: Action<GameViewModel>
    sendResign: Action<GameViewModel>
    sendOfferDraw: Action<GameViewModel>
    sendAbortRequest: Action<GameViewModel>
    sendAcceptOfferDraw: Action<GameViewModel>
    sendRejectOfferDraw: Action<GameViewModel>
    sendAdd30Seconds: Action<GameViewModel>
    sendMove: Action<GameViewModel, Move>
    initialiseGame: Action<GameViewModel, { gameData: GameData; userID: string; timeStamp: number }>
    resetGame: Action<GameViewModel, GameViewState>
    preloadGame: Action<GameViewModel, NonNullable<MatchData['result']>>
    shutDownGSConnection: Action<GameViewModel>
    receiveMoveRequestAcknowledgement: Action<GameViewModel, { clock: ClockData }>
    setProcessingMove: Action<GameViewModel, boolean>
    setOpponentDisconnected: Action<GameViewModel, { timestamp: number; secondsTillVictoryClaim: number } | undefined>
    setOpponentConnected: Action<GameViewModel, boolean>
    setCanClaimVictory: Action<GameViewModel, boolean>
    claimVictory: Action<GameViewModel>
    waitForOpponent: Action<GameViewModel>
    initializeGameOnReconnect: Action<
        GameViewModel,
        { gameData: GameDataOnReconnect; timeStamp: number; userID: string }
    >
    updateDeferredGameTree: Action<GameViewModel>
    setToPreviousPosition: Action<GameViewModel>
    setToNextPosition: Action<GameViewModel>
    setToFirstPosition: Action<GameViewModel>
    setToLastPosition: Action<GameViewModel>
    setEvalBarVisibility: Action<GameViewModel, boolean>
}

// ----------------------------------------------------------------------------- initial state

const initialGameTree = createRootGameTree(BasePositionFEN)

const gameViewModel: GameViewModel = {
    socket: undefined,
    connectionStatus: ConnectionStatus.NOT_INITIALIZED,
    matchData: undefined,
    gameData: undefined,
    clockStartClient: 0,
    clockStartServer: 0,
    myColor: Color.White,
    myRating: 0,
    flipped: false,
    gameState: GameViewState.PRE_GAME,
    gameResult: undefined,
    gameTree: initialGameTree,
    deferredGameTree: initialGameTree,
    currentPositionId: getStartingPosition(initialGameTree).id,
    deferredCurrentPositionId: getStartingPosition(initialGameTree).id,
    drawOfferStatus: undefined,
    gameEndEnabled: false,
    enableAbort: false,
    opening: undefined,
    settings: defaultSettings,
    engineDifficulty: undefined,
    gameStartedTimestamp: undefined,
    clockRunningFor: Color.White,
    opponentDisconnected: undefined,
    opponentConnected: false,
    sessionResultHistory: [],
    canClaimVictory: false,
    myLastMove: undefined,
    lastMoveReceivedAt: 0,
    processingMove: false,
    evalBarVisibility: false,

    // ----------------------------------------------------------------------------- simple actions

    setMatchData: action((state, md) => {
        state.matchData = md
    }),

    setGameTree: action((state, gt) => {
        state.gameTree = gt
        state.deferredGameTree = gt
    }),

    setSocket: action((state, socket) => {
        state.socket = socket
    }),

    setConnectionStatus: action((state, status) => {
        state.connectionStatus = status
    }),

    setCurrentPositionId: action((state, id) => {
        state.currentPositionId = id
        state.deferredCurrentPositionId = id
    }),

    setGameState: action((state, gameState) => {
        state.gameState = gameState
    }),

    setFlipBoard: action((state, flipped) => {
        state.flipped = flipped
    }),

    setMyColor: action((state, color) => {
        state.myColor = color
    }),

    setDrawStatus: action((state, newStatus) => {
        state.drawOfferStatus = newStatus
    }),

    setSettings: action((state, settings) => {
        state.settings = settings
    }),

    setGameResult: action((state, result) => {
        // console.log('clocks at moment of game end', state.clockWhite, state.clockBlack)
        state.gameResult = result
        state.gameState = GameViewState.GAME_OVER
        state.lastMoveReceivedAt = 0

        if (!!result.clock) {
            const now = new Date().getTime()
            state.clockInstantBlack = {
                secondsRemaining: result.clock.blackLeft < 0 ? 0 : result.clock.blackLeft,
                when: state.clockInstantBlack?.when || now,
            }
            state.clockInstantWhite = {
                secondsRemaining: result.clock.whiteLeft < 0 ? 0 : result.clock.whiteLeft,
                when: state.clockInstantWhite?.when || now,
            }
        }

        if (!state.gameData) return

        const { player1, player2 } = state.gameData

        const [whitePlayer, blackPlayer] = player1.playerColor === 'white' ? [player1, player2] : [player2, player1]
        const whitePlayerId = whitePlayer.playerKind === 'machine' ? 'machine' : whitePlayer.playerId
        const blackPlayerId = blackPlayer.playerKind === 'machine' ? 'machine' : blackPlayer.playerId

        const winnerId =
            result.winner === Color.White ? whitePlayerId : result.winner === Color.Black ? blackPlayerId : undefined
        const type = result.type

        const newResult = { whitePlayerId, blackPlayerId, winnerId, type }

        const prevResult = state.sessionResultHistory[state.sessionResultHistory.length - 1]

        if (
            prevResult &&
            ((prevResult.whitePlayerId === newResult.whitePlayerId &&
                prevResult.blackPlayerId === newResult.blackPlayerId) ||
                (prevResult.whitePlayerId === newResult.blackPlayerId &&
                    prevResult.blackPlayerId === newResult.whitePlayerId))
        ) {
            state.sessionResultHistory.push(newResult)
        } else {
            state.sessionResultHistory = [newResult]
        }
    }),

    sendGameResultToAnalytics: action((state, data) => {
        if (!state.gameData) return

        const timeInGame = state.gameStartedTimestamp ? Date.now() - state.gameStartedTimestamp : 0
        const { player1, player2, timeMode, matchId, matchKind, rated } = state.gameData
        let rating = state.myRating
        const myColorText = state.myColor === Color.White ? 'white' : 'black'
        if (data.newRating && myColorText === player1.playerColor && player1.playerId === data.newRating.player1_id) {
            rating = data.newRating.user1_rating
        } else if (data.newRating && myColorText === player2.playerColor && player2.playerId === data.newRating.player2_id) {
            rating = data.newRating.user2_rating
        }

        const whiteClockLeft = data.clock ? data.clock.whiteLeft : (state.clockInstantWhite ? state.clockInstantWhite.secondsRemaining : 0)
        const blackClockLeft = data.clock ? data.clock.blackLeft : (state.clockInstantBlack ? state.clockInstantBlack.secondsRemaining : 0)

        analyticsManager.dispatchEvent('gameFinished', {
            matchKind: matchKind,
            minutes: timeMode.durationMinutes,
            increment: timeMode.clockIncrementSeconds,
            method: data.method,
            reason: data.reason,
            rated: rated,
            player1ID: player1.playerId,
            player2ID: player2.playerId,
            player1Color: player1.playerColor,
            player2Color: player2.playerColor,
            whiteClockLeft: whiteClockLeft,
            blackClockLeft: blackClockLeft,
            matchID: matchId,
            timeInGame: timeInGame / 1000, // in seconds
            rating: rating,
        })
    }),

    addMoveOpening: action((state, config) => {
        // const m = getMoveById(state.gameTree, config.moveID)
        // if (!m) return
        // m.opening = config.opening
    }),

    setGameEndEnabled: action((state, open) => {
        state.gameEndEnabled = open
    }),

    setClockInstantWhite: action((state, clockInstant) => {
        state.clockInstantWhite = clockInstant
    }),

    setClockInstantBlack: action((state, clockInstant) => {
        state.clockInstantBlack = clockInstant
    }),

    setGameStartedTimestamp: action((state, ts) => {
        state.gameStartedTimestamp = ts
    }),

    setOpponentDisconnected: action((state, disconnectedInfo) => {
        state.opponentDisconnected = disconnectedInfo
    }),

    setOpponentConnected: action((state, disconnectedInfo) => {
        state.opponentConnected = disconnectedInfo
    }),

    setCanClaimVictory: action((state, canClaim) => {
        state.canClaimVictory = canClaim
    }),

    claimVictory: action((state) => {
        if (state.socket === undefined) return

        const msg = JSON.stringify({ requestType: GS_REQUESTS.MATCH_CLAIM_VICTORY })
        state.socket.send(msg)
    }),

    waitForOpponent: action((state) => {
        if (state.socket === undefined) return

        const msg = JSON.stringify({ requestType: GS_REQUESTS.MATCH_WAIT_FOR_OPPONENT })
        state.socket.send(msg)
    }),

    receiveMove: action((state, update) => {
        const { clock, moveStr, receivedAt } = update

        const now = new Date().getTime()

        const moveTime = clock.moveDuration
        // how long between start of game and last move
        const moveStartOnServer = clock.timeStamp - state.clockStartServer
        // how long between receiving begin of game and receiving last move
        const moveStartOnClient = now - state.clockStartClient
        // delta how much longer it was from start to end on client vs. server
        const delta = moveStartOnClient - moveStartOnServer

        console.log('time delta client/server in ms', delta)
        console.log('GS matchId: ', state.gameData?.matchId)

        if (state.myColor === Color.White) {
            state.clockInstantWhite = { secondsRemaining: clock.whiteLeft, when: now }
            state.clockInstantBlack = {
                secondsRemaining: clock.blackLeft,
                when: state.clockInstantBlack?.when || now,
            }
        } else {
            state.clockInstantBlack = { secondsRemaining: clock.blackLeft, when: now }
            state.clockInstantWhite = {
                secondsRemaining: clock.whiteLeft,
                when: state.clockInstantWhite?.when || now,
            }
        }

        const opponentClock = toPrecision(state.myColor === Color.White ? clock.blackLeft : clock.whiteLeft, 1)

        const newPos = addStrMoveToMainline(
            state.gameTree,
            moveStr,
            undefined,
            undefined,
            opponentClock,
            moveTime,
            clock.timeStamp,
        )
        if (!newPos) {
            console.log('could not create a new position for the move ' + moveStr)
            throw new Error('could not create a new position for the move ' + moveStr)
        }
        if (update.opening && !!newPos.previousMoveId) {
            const m = getMoveById(state.gameTree, newPos.previousMoveId)
            if (!!m) m.opening = update.opening
        }

        if (newPos.position.turn === Color.Black) {
            console.log('compensating clock for black', clock.blackLeft, delta / 1000)
            clock.blackLeft -= delta / 1000
        } else {
            console.log('compensating clock for white', clock.whiteLeft, delta / 1000)
            clock.whiteLeft -= delta / 1000
        }

        state.currentPositionId = newPos.id
        state.opening = update.opening
        state.clockRunningFor = state.myColor
        state.deferredGameTree = state.gameTree
        state.deferredCurrentPositionId = state.currentPositionId
        state.lastMoveReceivedAt = receivedAt
        state.processingMove = false
    }),

    receiveClockUpdate: action((state, update) => {
        const now = new Date().getTime()
        state.clockInstantBlack = { secondsRemaining: update.clockBlack, when: now }
        state.clockInstantWhite = { secondsRemaining: update.clockWhite, when: now }
    }),

    // ----------------------------------------------------------------------------- socket actions

    sendJoin: action((state) => {
        if (state.socket === undefined || state.matchData === undefined) return
        const msg = JSON.stringify({
            requestType: GS_REQUESTS.JOIN,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendOfferDraw: action((state) => {
        // console.log('send offer draw')
        if (state.socket === undefined || state.matchData === undefined) return
        const msg = JSON.stringify({
            requestType: GS_REQUESTS.DRAW_OFFER,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendAbortRequest: action((state) => {
        if (state.socket === undefined || state.matchData === undefined) return
        const msg = JSON.stringify({
            requestType: GS_REQUESTS.ABORT,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendAdd30Seconds: action((state) => {
        if (state.socket === undefined || state.matchData === undefined) return
        const msg = JSON.stringify({
            requestType: GS_REQUESTS.ADD30_SECONDS,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendAcceptOfferDraw: action((state) => {
        if (state.socket === undefined || state.matchData === undefined) return
        const msg = JSON.stringify({
            requestType: GS_REQUESTS.DRAW_ACCEPTED,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendRejectOfferDraw: action((state) => {
        if (state.socket === undefined || state.matchData === undefined) return
        const msg = JSON.stringify({
            requestType: GS_REQUESTS.DRAW_REJECTED,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendResign: action((state) => {
        if (state.socket === undefined || state.matchData === undefined) return

        const msg = JSON.stringify({
            requestType: GS_REQUESTS.RESIGNATION,
            rawData: {},
        })
        state.socket.send(msg)
    }),

    sendMove: action((state, move) => {
        const m = moveToAlgebraicLong(move)

        if (state.socket === undefined || state.matchData === undefined) return

        const msg = JSON.stringify({
            requestType: GS_REQUESTS.MOVE,
            rawData: { Move: m, moveEndedAt: Date.now(), moveStartedAt: state.lastMoveReceivedAt },
            correlationID: getMoveId(state.gameTree, move),
        })

        state.socket.send(msg)

        // this will be unnecessary if we receive the move string from the server in moveRequestAcknowledgement
        state.myLastMove = move
        state.processingMove = true
        state.enableAbort = false
    }),

    receiveMoveRequestAcknowledgement: action((state, data) => {
        if (!state.myLastMove) return
        console.log('GS matchId: ', state.gameData?.matchId)

        const now = new Date().getTime()

        const moveTime = data.clock.moveDuration

        if (state.myColor === Color.White) {
            state.clockInstantBlack = { secondsRemaining: data.clock.blackLeft, when: now }
            state.clockInstantWhite = {
                secondsRemaining: data.clock.whiteLeft,
                when: state.clockInstantWhite?.when || now,
            }
        } else {
            state.clockInstantWhite = { secondsRemaining: data.clock.whiteLeft, when: now }
            state.clockInstantBlack = {
                secondsRemaining: data.clock.blackLeft,
                when: state.clockInstantBlack?.when || now,
            }
        }

        const myClock = toPrecision(state.myColor === Color.White ? data.clock.whiteLeft : data.clock.blackLeft, 1)

        const newPos = addMoveToMainline(
            state.gameTree,
            state.myLastMove,
            undefined,
            undefined,
            myClock,
            moveTime,
            data.clock.timeStamp,
        )

        state.currentPositionId = newPos.id
        state.clockRunningFor = -state.myColor
    }),

    initializeGameOnReconnect: action((state, { gameData, userID, timeStamp }) => {
        const now = new Date().getTime()

        const newGameTree = gameFromPGN(gameData.pgn || '')
        const newLastPos = getLastPosition(newGameTree)
        const currentLastPos = getLastPosition(state.gameTree)
        const opening = getDataFromPGN(gameData.pgn, [PGNFields.Opening])?.[PGNFields.Opening]
        if (newGameTree.moves.length === 0) {
            state.clockStartServer = gameData.clock.timeStamp
        } else {
            const lastMoveId = newLastPos.previousMoveId
            const lastMove = lastMoveId ? getMoveById(newGameTree, lastMoveId) : undefined
            if (lastMove) lastMove.timestamp = gameData.clock.timeStamp
        }

        if (
            newLastPos.position.moveNum !== currentLastPos.position.moveNum ||
            newLastPos.position.halfmoveClock !== currentLastPos.position.halfmoveClock
        ) {
            // with moves
            state.gameTree = newGameTree
            state.currentPositionId = getLastPosition(newGameTree).id
        } else {
            // without moves
            state.gameTree = newGameTree
            state.currentPositionId = getStartingPosition(newGameTree).id
        }

        state.gameState = GameViewState.GAME_RUNNING

        const clockRunningFor = getLastPosition(state.gameTree).position.turn
        console.log('clockRunningFor --------->', clockRunningFor)
        state.clockRunningFor = clockRunningFor

        state.clockInstantBlack = {
            secondsRemaining: gameData.clock.blackLeft,
            when: now,
        }
        state.clockInstantWhite = {
            secondsRemaining: gameData.clock.whiteLeft,
            when: now,
        }

        const timeControl = gameData.timeMode.name

        const myPlayerInfo = gameData.player1.playerId === userID ? gameData.player1 : gameData.player2
        const myColor = myPlayerInfo.playerColor === 'white' ? Color.White : Color.Black
        const myRating = myPlayerInfo.ratings![timeControl.toLowerCase()].rating

        let enableAbort = true
        // if it's my turn and I have moves to make, I can't abort
        if (newGameTree.positions.some((p) => p.position.turn === myColor && p.nextMoveIds.length > 0)) {
            enableAbort = false
        }

        state.enableAbort = enableAbort
        state.myColor = myColor
        state.myRating = myRating
        state.flipped = myColor === Color.Black
        state.engineDifficulty = gameData.difficulty
        state.gameData = { ...gameData, timeStamp: timeStamp }
        state.gameStartedTimestamp = new Date(gameData.createdAt).getTime()
        state.processingMove = false
        state.opening = opening
        state.deferredGameTree = state.gameTree
        state.deferredCurrentPositionId = state.currentPositionId
    }),

    setProcessingMove: action((state, progressive) => {
        state.processingMove = progressive
    }),

    initialiseGame: action((state, gd) => {
        const newRoot = createRootGameTree(gd.gameData.startingFen || BasePositionFEN)
        state.gameTree = newRoot
        const now = new Date().getTime()
        state.clockInstantBlack = { secondsRemaining: gd.gameData.timeMode.durationMinutes * 60, when: now }
        state.clockInstantWhite = { secondsRemaining: gd.gameData.timeMode.durationMinutes * 60, when: now }
        state.clockStartServer = gd.timeStamp
        state.clockStartClient = now
        console.log('clock delta at start of game in ms', now - gd.timeStamp)

        state.currentPositionId = getStartingPosition(newRoot).id

        const timeControl = gd.gameData.timeMode.name

        const myPlayerInfo = gd.gameData.player1.playerId === gd.userID ? gd.gameData.player1 : gd.gameData.player2
        const myColor = myPlayerInfo.playerColor === 'white' ? Color.White : Color.Black
        const myRating = myPlayerInfo.ratings![timeControl.toLowerCase()].rating

        if (myColor === Color.Black) {
            state.flipped = true
        }

        state.engineDifficulty = gd.gameData.difficulty
        state.myRating = myRating
        state.myColor = myColor
        state.gameData = gd.gameData
        state.gameStartedTimestamp = now
        state.processingMove = false
        state.enableAbort = true

        const lastPos = getLastPosition(newRoot)
        state.clockRunningFor = lastPos.position.turn

        state.deferredGameTree = state.gameTree
        state.deferredCurrentPositionId = state.currentPositionId
    }),

    updateDeferredGameTree: action((state) => {
        state.deferredGameTree = state.gameTree
        state.deferredCurrentPositionId = state.currentPositionId
    }),

    setToPreviousPosition: action((state) => {
        const prevPosition = getPreviousPosition(state.gameTree, state.currentPositionId)
        if (prevPosition) {
            state.currentPositionId = prevPosition.id
            state.deferredCurrentPositionId = prevPosition.id
        }
    }),

    setToNextPosition: action((state) => {
        const nextPosition = getNextPosition(state.gameTree, state.currentPositionId)
        if (nextPosition) {
            state.currentPositionId = nextPosition.id
            state.deferredCurrentPositionId = nextPosition.id
        }
    }),

    setToFirstPosition: action((state) => {
        const firstPosition = getStartingPosition(state.gameTree)
        state.currentPositionId = firstPosition.id
        state.deferredCurrentPositionId = firstPosition.id
    }),

    setToLastPosition: action((state) => {
        const lastPosition = getLastPosition(state.gameTree)
        state.currentPositionId = lastPosition.id
        state.deferredCurrentPositionId = lastPosition.id
    }),

    resetGame: action((state, gameState: GameViewState) => {
        const newRoot = createRootGameTree(BasePositionFEN)

        state.gameTree = newRoot
        state.deferredGameTree = newRoot
        state.gameData = undefined
        state.clockInstantBlack = undefined
        state.clockInstantWhite = undefined
        state.clockStartClient = 0
        state.clockStartServer = 0
        state.myColor = Color.White
        state.flipped = false
        state.gameState = gameState
        state.gameResult = undefined
        state.currentPositionId = getStartingPosition(newRoot).id
        state.deferredCurrentPositionId = getStartingPosition(newRoot).id
        state.drawOfferStatus = undefined
        state.opening = undefined
        state.engineDifficulty = undefined
        state.opponentConnected = false
        // fix for DEV-2696
        // state.opponentDisconnected = undefined
        state.canClaimVictory = false
        state.gameEndEnabled = false
        state.processingMove = false
        state.enableAbort = false
        state.evalBarVisibility = false
        state.lastMoveReceivedAt = 0
    }),

    preloadGame: action((state, data) => {
        if (data.player1) {
            state.clockInstantWhite = { secondsRemaining: parseInt(`${data.timeMode.durationMinutes}`) * 60, when: 0 }
            state.clockInstantBlack = { secondsRemaining: parseInt(`${data.timeMode.durationMinutes}`) * 60, when: 0 }
            state.gameData = undefined
            state.gameData = {
                ...data,
                matchId: data.pairId,
                // @ts-expect-error partial filling
                player1: {
                    ...data.player1,
                    userName: data.player1.username,
                    playerColor: data.player1.color,
                    countryId: data.player1.country,
                    playerId: data.player1.id,
                },
                // @ts-expect-error partial filling
                player2: {
                    ...data.player2,
                    userName: data.player2.username,
                    playerColor: data.player2.color,
                    countryId: data.player2.country,
                    playerId: data.player2.id,
                },
            }
        }

        const newRoot = createRootGameTree(data.startingFEN || BasePositionFEN)
        state.flipped = false
        state.gameResult = undefined
        state.opening = undefined
        state.matchData = undefined
        state.engineDifficulty = undefined
        state.evalBarVisibility = false
        state.gameState = GameViewState.GAME_PRELOADING

        state.gameTree = newRoot
        state.deferredGameTree = newRoot
        state.currentPositionId = getStartingPosition(newRoot).id
        state.deferredCurrentPositionId = getStartingPosition(newRoot).id
    }),

    shutDownGSConnection: action((state) => {
        state.socket?.close()
        state.socket = undefined
        state.connectionStatus = ConnectionStatus.NOT_INITIALIZED
        state.matchData = undefined
    }),

    setEvalBarVisibility: action((state, data) => {
        state.evalBarVisibility = data
    }),
}

export default gameViewModel
