import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Stack,
    Typography,
} from '@mui/material'
import debounce from 'lodash-es/debounce'
import { useCallback, useEffect, useState } from 'react'
import { resetPassword } from '../functions/ams-functions'

const inputStyle = {
    margin: '0 0 16px 0',
    borderColor: 'rgb(16 24 32)',
    '.MuiFormLabel-root': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInputBase-input': {
        borderBottom: '1px solid rgb(16 24 32)',
        color: 'rgb(16 24 32)',
    },
    '.MuiTypography-body1': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiTypography-body2': {
        color: 'red',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInput-underline:after': {
        borderBottom: '1px solid rgb(16 24 32)',
    },
    '.MuiInputLabel-root.Mui-focused': {
        color: 'rgb(16, 24, 32)',
    },
}

const buttonStyle = {
    padding: '10px ',
    fontFamily: 'Fira Sans',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiButton-text': {
        paddingTop: '15px',
    },
    '&:hover': {
        backgroundColor: '#101820',
        color: '#FFFFFF',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const errorBox = {
    padding: '10px',
    textAlign: 'center',
    fontFamily: 'Space Grotesk',
    color: 'rgb(220 38 38)',
    backgroundColor: ' rgb(254 226 226)',
    fontSize: '12px',
    marginBottom: '16px',
}
const stackStyle = {
    padding: '16px',
    backgroundColor: '#fff',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #101820',
}

export interface ResetPasswordFormProps {
    onClose: () => void
    apiURL: string
    newPasswordToken: string
}

function ResetPasswordForm(props: ResetPasswordFormProps) {
    const { onClose, apiURL, newPasswordToken } = props
    const [isPasswordValid, setIsPasswordValid] = useState<boolean | undefined>(undefined)
    const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean | undefined>(undefined)
    const [password, setPassword] = useState('')
    const [confirmationPassword, setConfirmationPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (isPasswordValid && doPasswordsMatch) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [isPasswordValid, doPasswordsMatch])

    // -------------------------------------------------------------- Password validation -----------------

    const onPasswordChange = (password: string) => {
        setIsPasswordValid(password.length > 7)
    }

    const debouncedPasswordChange = useCallback(debounce(onPasswordChange, 1000), [])

    useEffect(() => {
        if (password === '') return

        const timer = setTimeout(() => {
            debouncedPasswordChange(password)
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [password, debouncedPasswordChange])

    // -------------------------------------------------------------- Confirm password validation -----------------

    const onConfirmationPasswordChange = (confirmationPassword: string, password: string) => {
        setDoPasswordsMatch(confirmationPassword === password)
    }

    const debouncedConfirmationPasswordChange = useCallback(debounce(onConfirmationPasswordChange, 500), [])

    useEffect(() => {
        if (confirmationPassword === '') return

        const timer = setTimeout(() => {
            debouncedConfirmationPasswordChange(confirmationPassword, password)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
        //I left password out of dependencies to avoid running when not intended
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationPassword, debouncedConfirmationPasswordChange])

    const submitHandler = async () => {
        if (isPasswordValid) {
            const result = await resetPassword(newPasswordToken, password)
            if (!result.error) {
                // TODO: dialog instead of alert
                alert('Your password has been successfully reset.')
                onClose()
            } else {
                setErrorMessage(result.error.message)
            }
        } else {
            // TODO: figure this out
            //passwordInputRef.current.focus()
        }
    }

    return (
        <Dialog
            onClose={onClose}
            open={true}
            sx={{ zIndex: '1600', display: 'block !important' }}
            PaperProps={{ sx: { m: 0 } }}
        >
            <Stack className="stack" style={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton sx={{ color: 'black' }} onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Stack style={{ padding: 16 }}>
                    <Typography
                        variant="h3"
                        style={{
                            color: 'rgb(16 24 32)',
                            textAlign: 'center',
                        }}
                    >
                        Reset your password
                    </Typography>
                    {errorMessage && <Box sx={errorBox}>{errorMessage}</Box>}
                    <FormControl sx={inputStyle} variant="standard" style={{ marginTop: '50px' }}>
                        <InputLabel htmlFor="password">Enter your password</InputLabel>
                        <Input
                            id="password"
                            sx={inputStyle}
                            type={showPassword ? 'text' : 'password'}
                            style={{ marginBottom: 0 }}
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        tabIndex={-1}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/icons/pass-hidden.svg" alt="hide password" />
                                        ) : (
                                            <img src="/assets/images/icons/pass-show.svg" alt="show password" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={!isPasswordValid && isPasswordValid !== undefined}
                        />

                        {!isPasswordValid && isPasswordValid !== undefined && (
                            <FormHelperText id="password-helper-text" style={{ color: 'red' }}>
                                <Typography variant="body2">Password must be at least 8 characters long</Typography>
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={inputStyle} variant="standard">
                        <InputLabel htmlFor="password">Confirm your password</InputLabel>
                        <Input
                            id="confirmationPassword"
                            sx={inputStyle}
                            type={showConfirmationPassword ? 'text' : 'password'}
                            style={{ marginBottom: 0 }}
                            value={confirmationPassword}
                            onChange={(e) => setConfirmationPassword(e.currentTarget.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmationPassword(!showConfirmationPassword)}
                                        edge="end"
                                        tabIndex={-1}
                                    >
                                        {showConfirmationPassword ? (
                                            <img src="/assets/images/icons/pass-hidden.svg" alt="hide password" />
                                        ) : (
                                            <img src="/assets/images/icons/pass-show.svg" alt="show password" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={!doPasswordsMatch && doPasswordsMatch !== undefined}
                        />
                        {!doPasswordsMatch && doPasswordsMatch !== undefined && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">Passwords do not match</Typography>
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '100px',
                        }}
                    >
                        <Button sx={buttonStyle} disabled={!isFormValid} onClick={submitHandler}>
                            Reset
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default ResetPasswordForm
