import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import SocialLogin from '../../SocialLogin/SocialLogin'
import { ServerErrors } from '../../utils/serverErrors'
import { login } from '../functions/ams-functions'
import { IAppConfig, getAppConfig } from '../services/AppConfigService'
import { analyticsManager } from '../services/analytics/AnalyticsManager'

const inputStyle = {
    margin: '0 0 16px 0',
    borderColor: 'rgb(16 24 32)',
    '.MuiFormLabel-root': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInputBase-input': {
        borderBottom: '1px solid rgb(16 24 32)',
        color: 'rgb(16 24 32)',
    },
    '.MuiTypography-body1': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInput-underline:after': {
        borderBottom: '1px solid rgb(16 24 32)',
    },
    '.MuiInputLabel-root.Mui-focused': {
        color: 'rgb(16, 24, 32)',
    },
}

const buttonStyle = {
    padding: '10px 57px',
    fontFamily: 'Fira Sans',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0',
    '&.MuiButton-text': {
        paddingTop: '15px',
    },
    '&:hover': {
        backgroundColor: '#101820',
        color: '#FFFFFF',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const errorBox = {
    padding: '10px',
    textAlign: 'center',
    fontFamily: 'Space Grotesk',
    color: 'rgb(220 38 38)',
    backgroundColor: ' rgb(254 226 226)',
    fontSize: '12px',
    marginBottom: '16px',
}
const stackStyle = {
    padding: '16px',
    backgroundColor: '#fff',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #101820',
    overflow: 'hidden',
    overflowY: 'auto',
}

// -------------------------------------------------------

export type LogInFormProps = {
    onClose: () => void
    onLoginSuccessful: () => void
    onSwichToSignup: () => void
    onSwitchToForgotPassword: () => void
    onSwitchToEmailConfirmation: (email: string) => void
    onSignUpSocial: (socialResponse: any) => void
    apiURL: string
    isGuest?: boolean
    logoutHandler: () => Promise<void>
    googleClientId?: string
    facebookAppId?: string
    facebookApiVersion?: string
    appleClientId?: string
    appleRedirectURL?: string
}

function LogInForm(props: LogInFormProps) {
    const {
        onClose,
        onSwichToSignup,
        apiURL,
        onSwitchToForgotPassword,
        onSwitchToEmailConfirmation,
        onLoginSuccessful,
        isGuest,
        logoutHandler,
        onSignUpSocial,
        googleClientId,
        facebookAppId,
        facebookApiVersion,
        appleClientId,
        appleRedirectURL,
    } = props

    const [password, setPassword] = useState('')
    const [userIdentifier, setUserIdentifier] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    const emailValid = /^\S+@\S+\.\S+$/.test(userIdentifier)
    const usernameValid =
        userIdentifier.length >= 2 &&
        userIdentifier.length <= 15 &&
        /^[a-zA-Z0-9](?:[a-zA-Z0-9]*[-._]?[a-zA-Z0-9])+$/.test(userIdentifier)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    const onLogin = useCallback(
        async (event: { preventDefault: () => void }) => {
            event.preventDefault()
            if (!!password && (emailValid || usernameValid)) {
                const loginData = await login(userIdentifier, password)
                if (loginData) {
                    if (
                        loginData.error &&
                        loginData.error.code === ServerErrors.USER_EMAIL_NOT_CONFIRMED &&
                        loginData.error.details &&
                        !loginData.error.details.confirmed
                    ) {
                        onSwitchToEmailConfirmation(loginData.error.details.email)
                        return
                    }
                    if (loginData.error) {
                        // removed by DEV-5098
                        // if (loginData.error.code === ServerErrors.NO_PASSWORD_SET) {
                        //     onSwitchToForgotPassword()
                        // }
                        setErrorMessage(loginData.error.message)
                    } else {
                        onLoginSuccessful()
                        analyticsManager.dispatchEvent('completeLogin', {
                            authType: 'email/user_name',
                            userId: loginData.user.id,
                            userName: loginData.user.user_name,
                        })
                    }
                }
            }
        },
        [emailValid, userIdentifier, password, onLoginSuccessful, isGuest],
    )

    useEffect(() => {
        if ((emailValid || usernameValid) && !!password) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Enter') {
                    onLogin(event)
                }
            }
            document.addEventListener('keydown', handleKeyDown)
            return () => {
                document.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [emailValid, usernameValid, password, onLogin])

    return (
        <Dialog
            onClose={onClose}
            open={true}
            sx={{ zIndex: '1600', display: 'block !important' }}
            PaperProps={{ sx: { m: 0 } }}
        >
            <Stack sx={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton sx={{ color: 'black' }} onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Stack style={{ padding: 16 }}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: 'rgb(16 24 32)',
                            textAlign: 'center',
                            py: '40px',
                        }}
                    >
                        Hello again
                    </Typography>
                    {errorMessage && <Box sx={errorBox}>{errorMessage}</Box>}

                    <FormControl sx={inputStyle} variant="standard">
                        <InputLabel htmlFor="userIdentifier">Enter your email or user name</InputLabel>
                        <Input
                            id="userIdentifier"
                            sx={inputStyle}
                            type="text"
                            style={{ marginBottom: 0 }}
                            value={userIdentifier}
                            onChange={(e) => setUserIdentifier(e.currentTarget.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="" edge="end" tabIndex={-1}>
                                        <img src="/assets/images/icons/user.svg" alt="userIdentifier" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <FormControl sx={inputStyle} variant="standard">
                        <InputLabel htmlFor="password">Enter your password</InputLabel>
                        <Input
                            id="password"
                            sx={inputStyle}
                            type={showPassword ? 'text' : 'password'}
                            style={{ marginBottom: 0 }}
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        tabIndex={-1}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/icons/pass-hidden.svg" alt="hide password" />
                                        ) : (
                                            <img src="/assets/images/icons/pass-show.svg" alt="show password" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Link
                        sx={{
                            color: 'rgb(16 24 32)',
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: '13px',
                            fontFamily: 'Space Grotesk',
                            marginBottom: '20px',
                            marginTop: '40px',
                            cursor: 'pointer',
                        }}
                        onClick={() => onSwitchToForgotPassword()}
                    >
                        I forgot my password
                    </Link>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            sx={buttonStyle}
                            disabled={!(emailValid || usernameValid) || !password}
                            onClick={onLogin}
                        >
                            Log in
                        </Button>
                    </Box>
                    {/* SOCIAL LOGIN MAKES PAGE TO JUMP */}
                    {!(appConfig && appConfig.hideSSO) && (
                        <SocialLogin
                            title="Or Log in with:"
                            onSignUpSocial={onSignUpSocial}
                            onLoginSuccessful={onLoginSuccessful}
                            isGuest={isGuest}
                            logoutHandler={logoutHandler}
                            apiURL={apiURL}
                            googleClientId={googleClientId}
                            facebookAppId={facebookAppId}
                            facebookApiVersion={facebookApiVersion}
                            appleClientId={appleClientId}
                            appleRedirectURL={appleRedirectURL}
                        />
                    )}
                    <Box
                        sx={{
                            color: 'rgb(16 24 32)',
                            fontSize: '14px',
                            fontFamily: 'Space Grotesk',
                            textAlign: 'center',
                            marginTop: '20px',
                        }}
                    >
                        I don't have an account.
                        <Link
                            sx={{
                                paddingLeft: '10px',
                                color: 'rgb(16 24 32)',
                                fontSize: '14px',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                onSwichToSignup()
                            }}
                        >
                            Sign up
                        </Link>
                    </Box>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default LogInForm
