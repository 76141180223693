import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, IconButton, Typography, Zoom } from '@mui/material'
import { useEffect, useState } from 'react'
import useSound from 'use-sound'
import { FramedAvatar } from '../../../sharedComponents/src/globalHeader/components/FramedAvatar'
import { getPuzzleQuestAsset } from '../../../sharedComponents/src/helpers/getPuzzleQuestAsset'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { getReward } from '../common'

export const RewardPopup = () => {
    const userData = useStoreState((state) => state.userData)
    const setUserData = useStoreActions((state) => state.setUserData)
    const setResultsPopup = useStoreActions((state) => state.puzzleQuestView.setResultsPopup)
    const user = useStoreState((state) => state.userData.userData)
    const weekProgress = useStoreState((state) => state.puzzleQuestView.weekProgress)
    const [crowns, setCrowns] = useState(weekProgress?.rewardsTotal || 0)
    const [reward, setReward] = useState<number>()
    const [initialised, setInitialised] = useState(false)

    const [playFrame] = useSound(`/assets/sounds/default/Frame.mp3`, { volume: 0.5 })

    useEffect(() => {
        playFrame()
    }, [initialised])

    const updateUserReward = (reward: number, season?: string) => {
        userData.userData &&
            setUserData({
                ...userData,
                userData: {
                    ...userData.userData,
                    reward: {
                        tier: reward,
                        season: season || '',
                    },
                },
            })
    }

    // Probably better to move it to the `PuzzleQuestModel.ts` file in the end of asyncSavePuzzle handler
    useEffect(() => {
        if (weekProgress?.rewardsTotal) {
            setCrowns((prevCrowns) => {
                if (prevCrowns !== weekProgress.rewardsTotal) {
                    const reward = getReward(weekProgress)

                    reward > 0 &&
                        setTimeout(() => {
                            setReward(reward)
                        }, 2000)
                }
                return weekProgress.rewardsTotal
            })
        } else {
            // REWARD CLEANUP
            setReward(0)
            !weekProgress?.rewardsTotal && userData.userData?.reward.tier && updateUserReward(0)
        }
    }, [weekProgress?.rewardsTotal])

    const handleClose = () => {
        setReward((prevReward) => {
            updateUserReward(prevReward || 0, weekProgress?.season)

            return 0
        })

        setTimeout(() => {
            weekProgress?.stepsToGo === 1 && setResultsPopup(true)
        }, 1000)
    }

    const isTopTier = reward === 3
    if (!reward || !weekProgress || reward === userData.userData?.reward?.tier) return null

    return (
        <Dialog
            onTransitionEnd={() => setInitialised(true)}
            TransitionComponent={Zoom}
            transitionDuration={1500}
            open={Boolean(reward)}
            onClose={handleClose}
        >
            <Box
                sx={{
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    py: { xs: 6, md: 8 },
                    px: { xs: 2, md: 8 },
                    gap: 1,
                }}
            >
                <IconButton
                    sx={{ position: 'absolute', top: { xs: 0, md: 8 }, right: { xs: 0, md: 8 } }}
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
                <Typography textAlign="center" color="secondary" variant="h3">
                    Congratulations!
                </Typography>
                <Typography textAlign="center" variant="h3">
                    You Earned a new reward
                </Typography>
                {isTopTier ? (
                    <Typography textAlign="center" variant="body2" paragraph>
                        Return after the weekly reset for more rewards!
                    </Typography>
                ) : (
                    <Typography textAlign="center" variant="body2" paragraph>
                        Collect more crowns to reach the next milestone.
                    </Typography>
                )}
                <Box position="relative" overflow="visible">
                    <FramedAvatar
                        season={weekProgress.season}
                        reward={reward}
                        avatar={user?.avatar_url || ''}
                        stripe
                        sx={{
                            '& div:first-child': {
                                border: '1px solid white',
                            },
                            width: '160px',
                            height: '160px',
                            'img:nth-child(2), img:nth-child(3), div:last-child': {
                                '@keyframes fadeInOut': {
                                    '0%': {
                                        scale: 0,
                                        opacity: 0,
                                    },
                                    '50%': {
                                        opacity: 0.7,
                                        scale: '1.2',
                                    },
                                    '100%': {
                                        opacity: 1,
                                        scale: '1.0',
                                    },
                                },
                                opacity: 0,
                                animation: initialised ? 'fadeInOut 1s ease forwards' : 'unset',
                            },
                        }}
                    />

                    {initialised && (
                        <Box
                            sx={{
                                position: 'absolute',
                                right: '-50%',
                                bottom: '0%',
                                zIndex: 2,
                                width: '200%',
                                height: '200%',
                            }}
                        >
                            <DotLottieReact
                                speed={0.5}
                                src="/assets/animations/puzzles/confetti.lottie"
                                autoplay
                                layout={{ fit: 'contain', align: [0, 0] }}
                                renderConfig={{
                                    autoResize: true,
                                }}
                            />
                        </Box>
                    )}
                </Box>
                {isTopTier ? (
                    <br />
                ) : (
                    <Zoom style={{ transitionDelay: '2500ms' }} timeout={1000} in>
                        <Typography mt={10} textAlign="center" variant="h5">
                            Next
                            <Box
                                component="span"
                                sx={{
                                    display: 'inline-block',
                                    width: '36px',
                                    height: '36px',
                                    backgroundImage: `url(${getPuzzleQuestAsset(weekProgress.season, reward + 1, 'active')})`,
                                    backgroundSize: 'contain',
                                    mx: 1.5,
                                    mb: '-9px',
                                }}
                            />
                            Goal
                        </Typography>
                    </Zoom>
                )}
            </Box>
        </Dialog>
    )
}
