import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { countries } from '../../../sharedComponents/src/globalHeader/common/countries'

export type CountryItem = {
    name: string
    code: string
}

interface IProps {
    label: string
    value: string
    onCountryChange: (country: CountryItem) => void
}

const CountryDropdown: React.FC<IProps> = (props) => {
    const { label, onCountryChange, value } = props
    const [selectedValue, setSelectedValue] = useState<string>('')

    useEffect(() => {
        const userCountry = countries.find((country) => country.code === value)
        setSelectedValue(userCountry?.name || '')
    }, [])

    const handleOnChange = (e: SelectChangeEvent<string>) => {
        setSelectedValue(e.target.value)
        const country = countries.find((country) => country.name === e.target.value)
        if (country) {
            onCountryChange(country)
        }
    }

    return (
        <FormControl fullWidth variant="standard">
            <InputLabel>{label}</InputLabel>
            <Select
                defaultValue={selectedValue}
                value={selectedValue}
                onChange={handleOnChange}
                sx={{ padding: '0.25rem 1rem' }}
            >
                {countries.map((country) => (
                    <MenuItem key={country.code} value={country.name}>
                        {country.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

CountryDropdown.displayName = 'CountryDropdown'

export default CountryDropdown
