import BackIcon from '@mui/icons-material/ArrowBackIos'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import AppLayoutWithoutBoard from '../../components/AppLayout/AppLayoutWithoutBoard'
import { Layout, useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import GameHistory from './components/GameHistory'
import MobileDashboard from './components/MobileDashboard'
import ProfileAccount from './components/ProfileAccount'
import ProfileBlock from './components/ProfileBlock'
import ProfileMenu from './components/ProfileMenu'
import StatsTable from './components/StatsTable'
import { ProfilePages } from './constants'

const ProfileView: React.FC = () => {
    const settings = useStoreState((state) => state.gameView.settings)
    const profileMenu = useStoreState((state) => state.profileView.profileMenu)
    const initStats = useStoreActions((state) => state.profileView.initStats)
    const setProfileMenu = useStoreActions((state) => state.profileView.setProfileMenu)
    const { layout } = useResponsiveSizings()

    useEffect(() => {
        initStats()
    }, [settings.friendlyMode])

    return (
        <AppLayoutWithoutBoard>
            <Stack
                direction="row"
                gap={{
                    xs: 2,
                    lg: 4,
                }}
            >
                {layout === Layout.DESKTOP || profileMenu === ProfilePages.DASHBOARD ? (
                    <Stack
                        gap={4}
                        sx={{
                            backgroundColor: 'background.paper',
                            flexGrow: layout === Layout.DESKTOP ? 0 : 1,
                            alignSelf: 'flex-start',
                        }}
                    >
                        <ProfileBlock />
                        <MobileDashboard />
                        <ProfileMenu />
                    </Stack>
                ) : (
                    <Stack gap={4} sx={{ backgroundColor: 'background.paper', flexGrow: 1 }}>
                        <Stack sx={{ alignItems: 'flex-start', p: 2, pb: 0 }}>
                            <IconButton
                                onClick={() => setProfileMenu(ProfilePages.DASHBOARD)}
                                size="small"
                                sx={{
                                    width: 'auto',
                                    fontSize: '18px !important',
                                    ':hover': {
                                        background: 'unset',
                                    },
                                }}
                            >
                                <BackIcon fontSize="small" />
                                <Typography>Profile</Typography>
                            </IconButton>
                        </Stack>
                        <ProfileAccount />
                    </Stack>
                )}

                {layout === Layout.DESKTOP && (
                    <Stack
                        gap={{
                            xs: 2,
                            lg: 4,
                        }}
                        sx={{ flexGrow: 1 }}
                    >
                        {profileMenu === ProfilePages.ACCOUNT ? (
                            <ProfileAccount />
                        ) : (
                            <>
                                <StatsTable />
                                <GameHistory />
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
        </AppLayoutWithoutBoard>
    )
}

ProfileView.displayName = 'ProfileView'

export default ProfileView
