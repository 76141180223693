import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import LeaderboardIcon from '../../../sharedComponents/src/globalHeader/icons/leaderboard'
import ProfileIcon from '../../../sharedComponents/src/globalHeader/icons/profile'
import SettingsIcon from '../../../sharedComponents/src/globalHeader/icons/settings'
import { Layout, useResponsiveSizings } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { ProfilePages } from '../constants'

const ProfileMenu: React.FC = () => {
    const profileMenu = useStoreState((state) => state.profileView.profileMenu)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setProfileMenu = useStoreActions((state) => state.profileView.setProfileMenu)

    const { layout } = useResponsiveSizings()
    const [menuItems, setMenuItems] = useState([
        {
            name: ProfilePages.SETTINGS,
            value: 'Settings',
            icon: <SettingsIcon />,
        },
        {
            name: ProfilePages.ACCOUNT,
            value: 'Account',
            icon: <ProfileIcon />,
        },
    ])

    useEffect(() => {
        const dashboardItem = {
            name: ProfilePages.DASHBOARD,
            value: 'Dashboard',
            icon: <LeaderboardIcon />,
        }
        const isDashboardIncluded = menuItems.some((item) => item.name === 'dashboard')

        switch (layout) {
            case Layout.DESKTOP:
                if (!isDashboardIncluded) {
                    const menu = [dashboardItem, ...menuItems]
                    setMenuItems(menu)
                }
                break
            case Layout.MOBILE:
                if (isDashboardIncluded) {
                    const menu = menuItems.filter((item) => item.name !== 'dashboard')
                    setMenuItems(menu)
                }
                break
            default:
                break
        }
    }, [layout])

    return (
        <Stack sx={{ py: 1, display: 'flex', flexGrow: '1', flexDirection: 'column', alignSelf: 'stretch' }}>
            {menuItems.map((item, index) => (
                <MenuItem
                    key={index}
                    selected={profileMenu === item.name}
                    divider={menuItems.length !== index + 1}
                    sx={(theme) => ({
                        fontFamily: 'Space Grotesk',
                        fontWeight: 400,
                        fontSize: '1.25rem',
                        borderBottomWidth: 2,
                        borderBottomColor: 'text.primary',
                        color: 'text.primary',
                        lineHeight: '2rem',
                        gap: 2,
                        py: 2,
                        mx: 2,
                        px: 2,
                        '&:hover': {
                            color: 'background.default',
                            backgroundColor: 'text.primary',
                            mx: 0,
                            px: 4,
                            '> svg path': {
                                fill: theme.palette.background.default,
                                stroke: theme.palette.background.default,
                            },
                        },
                        '&.Mui-selected': {
                            color: 'background.default',
                            backgroundColor: 'text.primary',
                            mx: 0,
                            px: 4,
                            '> svg path': {
                                fill: theme.palette.background.default,
                                stroke: theme.palette.background.default,
                            },
                            '&:hover': {
                                backgroundColor: 'text.primary',
                                color: 'background.default',
                            },
                        },
                    })}
                    onClick={() => {
                        if (item.name === ProfilePages.SETTINGS) {
                            setOverlayTrigger('settings')
                        } else {
                            setProfileMenu(item.name)
                            if (layout === Layout.MOBILE) {
                                window.scrollTo({ top: 0, behavior: 'smooth' })
                            }
                        }
                    }}
                >
                    {item.icon}
                    {item.value}
                </MenuItem>
            ))}
        </Stack>
    )
}

ProfileMenu.displayName = 'ProfileMenu'

export default ProfileMenu
