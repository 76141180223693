import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import useSound from 'use-sound'
import BlitzIcon from '../../../../assets/icons/blitz.svg?react'
import BulletIcon from '../../../../assets/icons/bullet.svg?react'
import ClassicIcon from '../../../../assets/icons/classic.svg?react'
import RapidIcon from '../../../../assets/icons/rapid.svg?react'
import { Color } from '../../../../chess/types'
import { Layout } from '../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { GameData, GameResult, GameResultType } from '../../../../store/types'
import { GameOverNavigation } from './GameOverNavigation/GameOverNavigation'

import { DialogHeader } from './components/DialogHeader/DialogHeader'
import { PlayerColumn } from './components/PlayerColumn/PlayerColumn'
import { ResultColumn } from './components/ResultColumn/ResultColumn'
import { isADraw, isAWin } from './utils'

type Props = {
    open: boolean
    gameData: GameData
    onClose: () => void
    result: GameResult
    myColor?: Color
    observer?: boolean
    onRematch?: () => void
    onNewGame?: (vsHuman: boolean) => void
    onAnalysis: () => void
    layout: Layout
    sessionResultHistory: {
        whitePlayerId: string
        blackPlayerId: string
        winnerId: string | undefined
        type: GameResultType
    }[]
}

export const GameOverDialog = ({
    open,
    onClose,
    gameData,
    result,
    myColor,
    onAnalysis,
    onNewGame,
    onRematch,
    layout,
    sessionResultHistory,
    observer = false,
}: Props) => {
    const gameInQueue = useStoreState((state) => state.matchMaker.gameInQueue)
    const scoreboards = useStoreState((state) => state.matchMaker.scoreboards)
    const settings = useStoreState((state) => state.gameView.settings)

    const getGameFromQueue = useStoreActions((state) => state.matchMaker.getGameFromQueue)
    const setUpdatedRating = useStoreActions((state) => state.setUpdatedRating)

    const [whiteWins, setWhiteWins] = useState<number>(0)
    const [blackWins, setBlackWins] = useState<number>(0)
    const [draws, setDraws] = useState<number>(0)

    // Game Over Sounds
    const iWonURL = `/assets/sounds/${settings.soundStyle}/GameEndWin.mp3`
    const [playIwon] = useSound(iWonURL, { volume: 0.05 })
    const stalemateURL = `/assets/sounds/${settings.soundStyle}/Stalemate.mp3`
    const [playStalemate] = useSound(stalemateURL, { volume: 0.05 })
    const drawOrLoseSoundURL = `/assets/sounds/${settings.soundStyle}/GameEndDraworLoss.mp3`
    const [playDrawOrLose] = useSound(drawOrLoseSoundURL, { volume: 0.05 })

    useEffect(() => {
        if (!open || !settings.soundOn) return

        if ((myColor && result.winner === myColor) || (observer && result.winner !== undefined)) playIwon()
        else if (result.type === GameResultType.Stalemate) playStalemate()
        else playDrawOrLose()
    }, [open, settings.soundOn, result, myColor, playIwon, playStalemate, playDrawOrLose])

    const { timeMode, player1, player2, rated } = gameData

    const [whitePlayer, blackPlayer] = player1.playerColor === 'white' ? [player1, player2] : [player2, player1]

    const whitePlayerOldRating = whitePlayer.ratings ? whitePlayer.ratings[timeMode.name].rating : 0

    const whitePlayerNewRating = result.newRating
        ? whitePlayer.playerId === result.newRating?.player1_id
            ? result.newRating.user1_rating
            : result.newRating.user2_rating
        : whitePlayerOldRating

    const blackPlayerOldRating = blackPlayer.ratings ? blackPlayer.ratings[timeMode.name].rating : 0

    const blackPlayerNewRating = result.newRating
        ? blackPlayer.playerId === result.newRating?.player1_id
            ? result.newRating.user1_rating
            : result.newRating.user2_rating
        : blackPlayerOldRating

    const resultString =
        result.winner === Color.White
            ? '1-0'
            : result.winner === Color.Black
              ? '0-1'
              : isADraw({ winnerId: undefined, type: result.type })
                ? '½-½'
                : '-/-'

    // ½-½
    const TimeControlIcon =
        timeMode.name === 'bullet'
            ? BulletIcon
            : timeMode.name === 'blitz'
              ? BlitzIcon
              : timeMode.name === 'rapid'
                ? RapidIcon
                : ClassicIcon

    useEffect(() => {
        const scoreboard = scoreboards.find((item) => item.pairId === gameData.matchId)
        if (!!scoreboard) {
            // PVP game
            const isWhiteChallenger = scoreboard.challenger.playerId === whitePlayer.playerId
            const whiteWinsCount =
                scoreboard.challenger.score.filter((score) => score === (isWhiteChallenger ? 1 : 0)).length +
                (result.winner === Color.White ? 1 : 0)
            const blackWinsCount =
                scoreboard.challenger.score.filter((score) => score === (isWhiteChallenger ? 0 : 1)).length +
                (result.winner === Color.Black ? 1 : 0)
            const drawsCount =
                scoreboard.challenger.score.filter((score) => score === 0.5).length +
                (isADraw({ winnerId: undefined, type: result.type }) ? 1 : 0)

            setWhiteWins(whiteWinsCount)
            setBlackWins(blackWinsCount)
            setDraws(drawsCount)
        } else {
            // PVE game
            const whiteWinsCount = sessionResultHistory.filter((result) => isAWin(result.winnerId, whitePlayer)).length
            const blackWinsCount = sessionResultHistory.filter((result) => isAWin(result.winnerId, blackPlayer)).length
            const drawsCount = sessionResultHistory.filter((result) => isADraw(result)).length
            setWhiteWins(whiteWinsCount)
            setBlackWins(blackWinsCount)
            setDraws(drawsCount)
        }
    }, [scoreboards.length, gameData.matchId, result])

    useEffect(() => {
        const myNewRating = myColor === Color.White ? whitePlayerNewRating : blackPlayerNewRating
        if (!observer && rated && myNewRating !== undefined) {
            setUpdatedRating({
                type: timeMode.name,
                newRating: myNewRating,
            })
        }
    }, [])

    const vsHuman = whitePlayer.playerKind === 'human' && blackPlayer.playerKind === 'human'

    const onRematchHandler = () => {
        if (gameInQueue) {
            getGameFromQueue()
        } else {
            onRematch && onRematch()
        }
    }

    return (
        <Dialog
            sx={{ maxWidth: '608px', margin: '0px auto' }}
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: '200px',
                    maxHeight: { xs: '99dvh' },
                    margin: { xs: '8px' },
                },
            }}
        >
            <DialogContent
                sx={{
                    border: 'solid',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'background.default',
                    position: 'relative',
                }}
            >
                <IconButton size="large" onClick={onClose} style={{ position: 'absolute', right: 0, top: 0 }}>
                    <CloseIcon />
                </IconButton>
                <DialogHeader
                    myColor={myColor}
                    winner={result.winner}
                    resultType={result.type}
                    layout={layout}
                    TimeControlIcon={TimeControlIcon}
                    timeMode={timeMode}
                    rewardURL={result.botdRewardURL}
                />
                <Grid container spacing="16px">
                    <Grid item xs={6} md={4} order={1}>
                        <PlayerColumn
                            playerInfo={whitePlayer}
                            winner={result.winner === Color.White}
                            rated={rated}
                            oldRating={whitePlayerOldRating}
                            newRating={whitePlayerNewRating}
                            timeMode={timeMode}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} order={{ xs: 3, md: 2 }}>
                        <ResultColumn
                            layout={layout}
                            TimeControlIcon={TimeControlIcon}
                            timeMode={timeMode}
                            whiteWins={whiteWins}
                            blackWins={blackWins}
                            draws={draws}
                            resultString={resultString}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} order={{ xs: 2, md: 3 }}>
                        <PlayerColumn
                            playerInfo={blackPlayer}
                            winner={result.winner === Color.Black}
                            rated={rated}
                            oldRating={blackPlayerOldRating}
                            newRating={blackPlayerNewRating}
                            timeMode={timeMode}
                        />
                    </Grid>
                </Grid>

                <GameOverNavigation
                    onAnalysis={onAnalysis}
                    onNewGame={onNewGame ? () => onNewGame(vsHuman) : undefined}
                    onRematch={onRematch ? onRematchHandler : undefined}
                />
            </DialogContent>
        </Dialog>
    )
}
