import { useEffect, useMemo, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import CameraIcon from '../assets/icons/camera.svg?react'
import { GameViewState } from '../features/gameView/GameViewModel'
import GlobalHeader, { AreaType, LoginState } from '../sharedComponents/src/globalHeader/GlobalHeader'
import { useResponsiveSizings } from '../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../store/hooks'
import PuzzleRating from './Rating/PuzzleRating'

const HeaderBar: React.FC = () => {
    const {
        resolution: { isMobile },
    } = useResponsiveSizings()
    const overlayTrigger = useStoreState((state) => state.overlayTrigger)
    const userData = useStoreState((state) => state.userData)
    const connectedToGame = useStoreState((state) => state.observeView.connectedToGame)
    const userPuzzleRating = useStoreState((state) => state.puzzlesView.userRating)
    const settings = useStoreState((state) => state.gameView.settings)

    const setUserData = useStoreActions((state) => state.setUserData)
    const shutDownGSConnection = useStoreActions((state) => state.gameView.shutDownGSConnection)
    const resetGame = useStoreActions((state) => state.gameView.resetGame)
    const reset = useStoreActions((state) => state.matchMaker.reset)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setSettings = useStoreActions((state) => state.gameView.setSettings)
    const setToken = useStoreActions((state) => state.setToken)
    const setRules = useStoreActions((state) => state.setRules)
    const setUserGroups = useStoreActions((state) => state.setUserGroups)

    const authHeaderRef = useRef<any>(null)
    const location = useLocation()
    // const showPuzzleRating = location.pathname.includes('/puzzles') && !settings.friendlyMode && !!isMobile
    const showPuzzleRating = location.pathname.includes('/puzzles') && !settings.friendlyMode

    useEffect(() => {
        if (overlayTrigger === 'signin') authHeaderRef.current?.openSignInForm()
        else if (overlayTrigger === 'signup') authHeaderRef.current?.openSignUpForm()
        else if (overlayTrigger === 'joinUs') authHeaderRef.current?.openJoinUsForm()
        else if (overlayTrigger === 'guestSignUp') authHeaderRef.current?.onSignUpAsGuest()
        else if (overlayTrigger === 'successLogin') authHeaderRef.current?.onSuccessLogin()
        else if (overlayTrigger === 'settings') authHeaderRef.current?.openProfileSettings()
        else if (overlayTrigger === 'logout') authHeaderRef.current?.onLogout()
        else if (typeof overlayTrigger === 'object') {
            if (overlayTrigger.name === 'socialSignUp') authHeaderRef.current?.onSignUpSocial(overlayTrigger.data)
            else if (overlayTrigger.name === 'updateSettings')
                authHeaderRef.current?.onSettingsUpdate(overlayTrigger.data)
            else if (overlayTrigger.name === 'updateUser') authHeaderRef.current?.onUpdateUserData(overlayTrigger.data)
        }
        setOverlayTrigger(undefined)
    }, [overlayTrigger])

    const extraButtons = useMemo(
        () => (
            <>
                {connectedToGame && <CameraIcon style={{ height: '3.125rem', width: '3.125rem' }} />}

                {showPuzzleRating && isMobile && <PuzzleRating userRating={userPuzzleRating} />}
            </>
        ),
        [connectedToGame, showPuzzleRating, userPuzzleRating, isMobile],
    )

    return (
        <GlobalHeader
            ref={authHeaderRef}
            initialUserData={userData.userData}
            onSettingsChange={(updatedSettings) => {
                if (userData.userData) {
                    setSettings(updatedSettings)
                    setUserData({ ...userData, userData: { ...userData.userData, game_settings: updatedSettings } })
                }
            }}
            onLoginStateChange={(userData) => {
                setUserData({
                    state: userData.state as LoginState,
                    userData: userData.userData,
                    token: userData.token,
                })
                if (userData.userData?.groups) {
                    const ruleNames = userData.userData.groups.flatMap((group) => group.rules.map((rule) => rule.name))
                    setRules(ruleNames)
                    const userGroups = userData.userData.groups.map((group) => group.name)
                    setUserGroups(userGroups)
                }
                if (userData.token) {
                    setToken(userData.token)
                }
                if (userData.state === LoginState.NOT_LOGGED_IN) {
                    console.log('resetting game server, matchmaker and observe server')
                    shutDownGSConnection()
                    resetGame(GameViewState.PRE_GAME)
                    reset()
                    setUserData({ state: LoginState.NOT_LOGGED_IN, userData: null, token: '' })
                    setToken('')
                }
                if (userData.userData?.game_settings) setSettings(userData.userData?.game_settings)
            }}
            apiURL={import.meta.env.VITE_REACT_APP_AMS_URL}
            extraButtons={userData.state === 'logged_in' ? [extraButtons] : []}
            forumsURL={import.meta.env.VITE_REACT_APP_FORUMS_URL}
            learningURL={import.meta.env.VITE_REACT_APP_LEARNING_URL}
            playAreaURL={''}
            websiteURL={import.meta.env.VITE_REACT_APP_WEBSITE_URL}
            shopURL={import.meta.env.VITE_REACT_APP_SHOP_URL}
            env={import.meta.env.VITE_REACT_APP_ENV}
            gaGameKey={import.meta.env.VITE_REACT_APP_GAME_KEY}
            gaSecretKey={import.meta.env.VITE_REACT_APP_SECRET_KEY}
            googleClientId={import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID}
            facebookAppId={import.meta.env.VITE_REACT_APP_FACEBOOK_APP_ID}
            facebookApiVersion={import.meta.env.VITE_REACT_APP_FACEBOOK_API_VERSION}
            appleClientId={import.meta.env.VITE_REACT_APP_APPLE_CLIENT_ID}
            appleRedirectURL={import.meta.env.VITE_REACT_APP_APPLE_REDIRECT_URL}
            area={AreaType.PLAY_AREA}
            Link={Link}
        />
    )
}

HeaderBar.displayName = 'HeaderBar'

export default HeaderBar
